<template>
    <h1 class="text-center mb-6">
        {{ $t("auth.changePwdIntro") }}
    </h1>
    <form
        @submit.prevent="change"
        class="w-full">
        <div class="c__input">
            <label
                for="token"
                class="">
                {{ $t("form.token") }}
            </label>

            <input
                id="token"
                type="text"
                required
                disabled
                readonly
                :value="token"
                name="token" />
            <p
                class="error"
                v-if="errors.token">
                {{ errors.token }}
            </p>
        </div>
        <div class="c__input">
            <label
                for="password"
                class="">
                {{ $t("form.newPwd") }}
            </label>

            <input
                id="password"
                type="password"
                required
                v-model="password"
                name="password" />

            <p
                class="error"
                v-if="errors.password">
                {{ errors.password }}
            </p>
        </div>
        <div class="c__input">
            <label
                for="confirmPassword"
                class="">
                {{ $t("form.confirmNewPwd") }}
            </label>

            <input
                id="confirmPassword"
                type="password"
                required
                v-model="confirmPassword"
                name="confirmPassword" />

            <p
                class="error"
                v-if="errors.confirmPassword">
                {{ errors.confirmPassword }}
            </p>
        </div>
        <div class="flex flex-row justify-center items-center mt-6">
            <CustomButton type="submit">
                {{ $t("auth.btnChangePwd") }}
            </CustomButton>
        </div>
    </form>

    <div class="md:flex flex-row justify-center items-center md:space-x-3 mt-6">
        <CustomButton
            to="/auth/forgot-pwd"
            version="text">
            {{ $t("auth.btnForgotPwdRestart") }}
        </CustomButton>

        <CustomButton
            to="/auth/login"
            class="mt-6 md:mt-0"
            version="text">
            {{ $t("auth.btnLogin") }}
        </CustomButton>
    </div>
</template>
<script>
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import get from "lodash-es/get"
import { USER_TYPES } from "@globals/constants"

export default {
    name: "UserChangePassword",
    components: { CustomButton },
    data() {
        return {
            type: USER_TYPES.USER,
            token: "",
            password: "",
            confirmPassword: "",
            errors: {}
        }
    },
    created() {
        this.token = get(this.$route, "query.token", "")
        this.type = get(this.$route, "query.type", USER_TYPES.USER)

        //check values
        if (this.token === "") {
            this.$router.replace("/auth/forgot-pwd")
        }
        if (!Object.values(USER_TYPES).includes(this.type)) {
            this.$router.replace("/auth/forgot-pwd")
        }
    },
    methods: {
        change() {
            this.errors = {}
            let action = types.USER_RESET_PWD
            if (this.type === USER_TYPES.STUDENT) {
                action = types.STUDENT_RESET_PWD
            }
            this.$store
                .dispatch(action, {
                    token: this.token,
                    password: this.password,
                    confirmPassword: this.confirmPassword
                })
                .then((res) => {
                    this.$router.replace("/auth/login")
                })
                .catch((err) => {
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
