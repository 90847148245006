<template>
    <Card>
        <div
            class="p-2 cursor-pointer"
            @click.prevent="$emit('join', game.uuid)">
            <div class="flex flex-row justify-between items-stretch space-x-3">
                <div class="flex flex-row justify-start items-stretch space-x-3">
                    <Image
                        class="h-28 w-28 min-w-28 md:w-40 md:min-w-40"
                        :image-url="game.wordGroup.imageUrl"
                        :alt="game.wordGroup.text" />

                    <div>
                        <div class="md:flex flex-row justify-between items-center md:space-x-3">
                            <div
                                class="rounded-full bg-gradient-purple px-2 py-1 flex flex-row justify-start items-center">
                                <Icons
                                    icon="flag"
                                    class="text-white w-4 h-4 block mr-1" />
                                <span class="text-white block font-bold text-8 uppercase">
                                    {{ $t(`global.gameTypes.${game.gameType.slug}`) }}
                                </span>
                            </div>
                            <h5 class="uppercase text-purple-60 pt-3 md:pt-0">
                                {{ capitalize(moment.utc(game.createdAt).fromNow()) }}
                            </h5>
                        </div>
                        <h2
                            v-if="traduction"
                            class="mt-2">
                            {{ traduction.text }}
                        </h2>
                        <h3 class="pt-4">{{ $t("game.code") }}: {{ cleanCode }}</h3>
                    </div>
                </div>
                <div class="flex flex-col justify-between items-center">
                    <div v-if="game.gameData.studentGames">
                        <h4>{{ $t("game.connectedPlayers") }}: {{ game.gameData.studentGames.length }}</h4>

                        <simplebar
                            v-if="game.gameData.studentGames"
                            class="overflow-y-hidden">
                            <div class="flex flex-row justify-start items-center space-x-1 flex-nowrap h-12">
                                <StudentAvatar
                                    with-popper
                                    v-for="studentGame in game.gameData.studentGames"
                                    :key="studentGame.student.id"
                                    :student="studentGame.student"
                                    size="small" />
                            </div>
                        </simplebar>
                    </div>

                    <template v-if="isCreator">
                        <CustomButton
                            @click.prevent.stop="terminateGame"
                            v-if="game.gameData.isStarted">
                            {{ $t("game.btnTerminateGame") }}
                        </CustomButton>
                        <CustomButton
                            @click.prevent.stop="terminateGame"
                            v-else>
                            {{ $t("game.btnCancelGame") }}
                        </CustomButton>
                    </template>
                    <template v-if="!isCreator && $store.getters.isStudentLogin">
                        <CustomButton @click.prevent.stop="leaveGame">
                            {{ $t("game.btnQuitGame") }}
                        </CustomButton>
                    </template>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import CustomButton from "components/globals/CustomButton"
import Card from "components/cards/Card"
import StudentAvatar from "components/globals/StudentAvatar"
import Simplebar from "components/globals/Simplebar"
import Icons from "components/globals/Icons"
import Image from "components/globals/Image"
import { cleanGameCode } from "helpers/helpers"
import ajax from "config/ajax"

export default {
    name: "LiveGameCard",
    components: { Image, Icons, Simplebar, StudentAvatar, Card, CustomButton },
    emits: ["join", "remove"],
    props: {
        game: Object
    },
    data() {
        return {
            timer: null
        }
    },
    computed: {
        isCreator() {
            if (!this.game) return false
            if (this.$store.getters.isStudentLogin) {
                return this.game.studentCreatorId === this.$store.state.auth.student.id
            }
            if (this.$store.getters.isUserLogin) {
                return this.game.creatorId === this.$store.state.auth.user.id
            }
            return false
        },
        traduction() {
            if (!this.game.wordGroup) return null
            return this.game.wordGroup.traductions.find((t) => t.lang === this.game.lang)
        },
        cleanCode() {
            return cleanGameCode(this.game.code)
        }
    },
    methods: {
        leaveGame() {
            ajax.post(`/student/games/${this.game.uuid}/leave`)
            this.$emit("remove", this.game)
        },
        terminateGame() {
            if (!this.isCreator) return
            if (this.$store.getters.isStudentLogin) {
                ajax.post(`/student/live-games/${this.game.uuid}/terminate`)
            }
            if (this.$store.getters.isUserLogin) {
                ajax.post(`/user/live-games/${this.game.uuid}/terminate`)
            }
            this.$emit("remove", this.game)
        }
    }
}
</script>
