<template>
    <div class="register w-full">
        <div
            v-if="student"
            class="border my-4 p-4 border-gray-200 rounded w-full flex flex-col justify-center items-center">
            <p class="text-center text-purple">
                {{ $t("auth.studentTakeNote") }}
            </p>
            <h3 class="mt-4">{{ $t("form.username") }}: {{ student.username }}</h3>
            <!--  <h3 class="mt-4">
          {{ $t('form.shortcode') }}: {{ student.shortcode }}
        </h3> -->

            <CustomButton
                to="/auth/login"
                class="mt-6">
                {{ $t("auth.btnLogin") }}
            </CustomButton>
        </div>
        <div
            class="w-full"
            v-else>
            <Loader v-if="isLoading" />

            <form
                @submit.prevent="submit"
                v-else>
                <p class="-small my-4 md:px-12 text-purple text-center">
                    {{ $t("auth.onlyWorkForTeacher") }}
                </p>
                <div class="c__input">
                    <label
                        for="classroomCode"
                        class="">
                        {{ $t("form.classroomCode") }}
                    </label>

                    <input
                        v-model="classroomCode"
                        id="classroomCode"
                        type="text"
                        required
                        name="classroomCode" />

                    <p
                        class="error"
                        v-if="errors.classroomCode">
                        {{ errors.classroomCode }}
                    </p>
                </div>

                <div class="md:grid gap-3 grid-cols-2">
                    <div class="c__input">
                        <label
                            for="firstName"
                            class="">
                            {{ $t("form.firstName") }}
                        </label>

                        <input
                            v-model="firstName"
                            id="firstName"
                            type="text"
                            required
                            name="firstName" />

                        <p
                            class="error"
                            v-if="errors.firstName">
                            {{ errors.firstName }}
                        </p>
                    </div>
                    <div class="c__input">
                        <label
                            for="lastName"
                            class="">
                            {{ $t("form.lastName") }}
                        </label>

                        <input
                            v-model="lastName"
                            id="lastName"
                            type="text"
                            required
                            name="lastName" />

                        <p
                            class="error"
                            v-if="errors.lastName">
                            {{ errors.lastName }}
                        </p>
                    </div>
                </div>

                <div class="c__input">
                    <label
                        for="username"
                        class="">
                        {{ $t("form.username") }} <span class="text-11">({{ $t("form.autoGeneratedIfEmpty") }})</span>
                    </label>

                    <input
                        v-model="username"
                        id="username"
                        type="text"
                        name="username" />

                    <p
                        class="error"
                        v-if="errors.username">
                        {{ errors.username }}
                    </p>
                </div>

                <div class="c__input">
                    <label
                        for="password"
                        class="">
                        {{ $t("form.createPwd") }}
                    </label>

                    <input
                        v-model="password"
                        id="password"
                        type="password"
                        required
                        name="password" />

                    <p
                        class="error"
                        v-if="errors.password">
                        {{ errors.password }}
                    </p>
                </div>

                <div class="flex flex-row justify-center items-center mt-6">
                    <CustomButton type="submit">
                        {{ $t("auth.btnCreateAccount") }}
                    </CustomButton>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import Loader from "components/Loader"

export default {
    components: { Loader, CustomButton },
    name: "RegisterStudentForm",
    data() {
        return {
            student: null,

            username: "",
            classroomCode: "",
            firstName: "",
            lastName: "",
            password: "",

            isLoading: false,
            errors: {}
        }
    },
    mounted() {
        if (this.$route.query.email) {
            this.username = this.$route.query.email.split("@")[0]
        }
        if (this.$route.query.name) {
            let split = this.$route.query.name.split(" ")
            this.firstName = split[0]
            this.lastName = this.$route.query.name.replace(this.firstName + " ", "")
        }
    },
    methods: {
        submit() {
            this.isLoading = true
            this.$store
                .dispatch(types.STUDENT_REGISTER_WITH_CLASSROOM, {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    password: this.password,
                    username: this.username,
                    classroomCode: this.classroomCode
                })
                .then((res) => {
                    this.isLoading = false
                    this.student = res.student
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
