<template>
    <div
        v-if="isLoading"
        class="py-6">
        <Loader />
    </div>

    <simplebar
        v-else
        class="scroll-container -no-scroll">
        <div class="flex flex-col justify-start items-start w-full h-full max-h-full">
            <div class="mb-6 w-full">
                <ListGameHeader
                    :timer="null"
                    @go-back="onGoBack"
                    :game-type="gameType"
                    :word-group="wordGroup" />
            </div>
            <div class="w-full flex-1">
                <Game3DLobby
                    v-if="gameTypeSlug === GAME_TYPES.LICK_3D_GAME"
                    :word-group="wordGroup"
                    :game-type="gameType"
                    @start="startGame" />
            </div>
        </div>
    </simplebar>
</template>

<script>
import * as types from "store/types"
import Loader from "components/Loader"
import ListGameHeader from "components/game/student/list/ListGameHeader"
import Simplebar from "components/globals/Simplebar"
import Game3DLobby from "components/game/student/types/lobby/Game3DLobby.vue"

import { GAME_TYPES } from "@globals/constants"

export default {
    name: "ListGameLobby",
    components: {
        Game3DLobby,
        Simplebar,
        ListGameHeader,
        Loader
    },
    data() {
        return {
            GAME_TYPES,

            isLoading: true,

            gameTypeSlug: "",
            wordGroup: null
        }
    },
    async created() {
        this.gameTypeSlug = this.$route.params.gameType
        if (!Object.values(this.GAME_TYPES).includes(this.gameTypeSlug)) {
            this.$router.replace("/")
            return
        }

        this.wordGroup =
            this.$store.state.studentData.wordGroups.find((wG) => wG.uuid === this.$route.params.uuid) || null
        if (!this.wordGroup) {
            this.wordGroup = await this.$store.dispatch(
                types.STUDENT_DATA.GET_WORD_GROUP_BY_UUID,
                this.$route.params.uuid
            )
        }

        this.isLoading = false
    },
    computed: {
        gameType() {
            let arr = []
            if (this.$store.getters.isStudentLogin) {
                arr = this.$store.state.studentData.global.gameTypes
            }
            if (this.$store.getters.isUserLogin) {
                arr = this.$store.state.userData.global.gameTypes
            }
            if (this.$store.getters.isAnonymousLogin) {
                arr = this.$store.state.anonymousData.global.gameTypes
            }

            return arr.find((g) => g.slug === this.gameTypeSlug)
        }
    },
    methods: {
        onGoBack() {
            this.$router.replace(`/student/list/${this.wordGroup.uuid}`)
        },
        startGame({ options }) {
            this.$router.push({ path: `/student/list/${this.wordGroup.uuid}/${this.gameTypeSlug}`, query: options })
        }
    }
}
</script>
