<template>
    <CircleButton
        icon="arrow-left"
        @click.prevent="onClick" />
</template>
<script>
import CircleButton from "components/globals/CircleButton"

export default {
    components: { CircleButton },
    name: "GoBackButton",
    props: {
        route: {
            default: null,
            type: String
        }
    },
    methods: {
        onClick() {
            if (this.$attrs && this.$attrs.onClick) return //parent has set a listener, cancel this click
            if (this.route) {
                this.$router.push(this.route)
            } else {
                this.goBack()
            }
        }
    }
}
</script>
