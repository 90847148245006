import * as types from "store/types"
import ajax from "config/ajax"
import cloneDeep from "lodash-es/cloneDeep"
import { addOrReplaceArr } from "helpers/helpers"

const state = {
    gameLeft: 11, //10 game left, will always be 1 less
    wordGroups: [],
    words: [],
    allProducts: [],

    global: {
        gameTypes: []
    },

    isFetching: {
        productsById: {}
    },

    hasFetch: {
        global: false,
        wordGroups: false
    }
}

const originalState = cloneDeep(state)

// getters, make function easy to access by vue
const getters = {
    getAnonymousTraduction: (state, getters, rootState) => (item) => {
        return item.traductions.find((t) => t.lang === rootState.auth.anonymous.lang) || null
    },
    getAnonymousLearningTraduction: (state, getters, rootState) => (item) => {
        return item.traductions.find((t) => t.lang === rootState.auth.anonymous.learningLang) || null
    }
}

// actions
const actions = {
    [types.ANONYMOUS_DATA.GET_WORDS_BY_WORD_GROUP_UUID](store, wordGroupUuid) {
        //todo cache
        return ajax.get(`/anonymous/word-groups/uuid/${wordGroupUuid}/words`).then((res) => {
            res.words.forEach((word) => {
                store.commit(types.ANONYMOUS_DATA.GET_WORD_BY_ID, word)
            })

            return Promise.resolve(res.words)
        })
    },
    [types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_UUID](store, uuid) {
        if (store.state.wordGroups.length > 0) {
            let exist = store.state.wordGroups.find((w) => w.uuid === uuid)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        return ajax.get(`/anonymous/word-groups/uuid/${uuid}`).then((res) => {
            store.commit(types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_UUID, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_ID](store, id) {
        if (store.state.wordGroups.length > 0) {
            let exist = store.state.wordGroups.find((w) => w.id === id)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        return ajax.get(`/anonymous/word-groups/id/${id}`).then((res) => {
            store.commit(types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_ID, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.ANONYMOUS_DATA.GET_WORD_GROUPS](store, payload) {
        if (store.state.hasFetch.wordGroups) {
            return Promise.resolve(store.state.wordGroups)
        }
        return ajax.get("/anonymous/word-groups").then((res) => {
            store.commit(types.ANONYMOUS_DATA.GET_WORD_GROUPS, res.wordGroups)
            return Promise.resolve(res.wordGroups)
        })
    },
    [types.ANONYMOUS_DATA.GET_GLOBALS](store, payload) {
        if (store.state.hasFetch.global) {
            return Promise.resolve(store.state.global)
        }
        return ajax.get(`/anonymous/globals`).then((res) => {
            store.commit(types.ANONYMOUS_DATA.GET_GLOBALS, res.global)
            return Promise.resolve(res.global)
        })
    },
    [types.ANONYMOUS_DATA.GET_PRODUCT_BY_ID](store, id) {
        if (store.state.allProducts.length > 0) {
            let exist = store.state.allProducts.find((p) => p.id === id)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        //skip, we are already loading it
        if (typeof store.state.isFetching.productsById[id] !== "undefined") {
            return Promise.resolve(null)
        }
        store.commit(types.ANONYMOUS_DATA.IS_FETCHING_PRODUCT_BY_ID, { id, isFetching: true })

        return ajax.get(`/anonymous/products/id/${id}`).then((res) => {
            store.commit(types.ANONYMOUS_DATA.IS_FETCHING_PRODUCT_BY_ID, { id, isFetching: false })
            store.commit(types.ANONYMOUS_DATA.GET_PRODUCT_BY_ID, res.product)
            return Promise.resolve(res.product)
        })
    }
}

// mutations
const mutations = {
    [types.ANONYMOUS_DATA.ANONYMOUS_PLAYED_GAME](state) {
        state.gameLeft--
    },
    [types.ANONYMOUS_DATA.GET_PRODUCT_BY_ID](state, product) {
        addOrReplaceArr(state.allProducts, product)
    },
    [types.ANONYMOUS_DATA.IS_FETCHING_PRODUCT_BY_ID](state, { id, isFetching }) {
        if (isFetching) {
            state.isFetching.productsById[id] = isFetching
        } else if (typeof state.isFetching.productsById[id] !== "undefined") {
            delete state.isFetching.productsById[id]
        }
    },
    [types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_ID](state, wordGroup) {
        addOrReplaceArr(state.wordGroups, wordGroup)
    },
    [types.ANONYMOUS_DATA.GET_WORD_GROUPS](state, wordGroups) {
        state.wordGroups = wordGroups
        state.hasFetch.wordGroups = true
    },
    [types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_UUID](state, wordGroup) {
        addOrReplaceArr(state.wordGroups, wordGroup)
    },
    [types.ANONYMOUS_DATA.GET_WORD_BY_ID](state, word) {
        addOrReplaceArr(state.words, word)
    },
    [types.ANONYMOUS_DATA.GET_GLOBALS](state, globals) {
        state.global.gameTypes = globals.gameTypes
        state.hasFetch.global = true
    },
    [types.LOGOUT](state) {
        //reset all data
        let clone = cloneDeep(originalState)
        Object.keys(state).forEach((key) => {
            state[key] = clone[key]
        })
        Object.assign(state, clone)
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
