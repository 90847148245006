<template>
    <div
        v-if="studentGameData"
        class="w-1/3 flex flex-col justify-end items-start">
        <StudentAvatar
            :student="studentGameData.student"
            size="big"
            class="mx-auto" />
        <h2
            class="text-center pt-2 w-full"
            v-if="studentGameData.student">
            {{ studentGameData.student.name }}
        </h2>
        <div
            class="bg-purple w-full mt-4 flex flex-col justify-center items-center"
            :class="{
                'h-24': step === 1,
                'h-16': step === 2,
                'h-12': step === 3
            }">
            <h1 class="text-white font-bold">
                <template v-if="step === 1">
                    {{ $t("game.1st") }}
                </template>
                <template v-if="step === 2">
                    {{ $t("game.2nd") }}
                </template>
                <template v-if="step === 3">
                    {{ $t("game.3rd") }}
                </template>
            </h1>
        </div>
    </div>
</template>

<script>
import StudentAvatar from "components/globals/StudentAvatar"

export default {
    name: "GamePodiumStep",
    components: { StudentAvatar },
    props: {
        step: {
            type: Number,
            anyOf: [1, 2, 3],
            default: 1
        },
        studentGameData: Object
    }
}
</script>
