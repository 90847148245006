<template>
    <modal
        :show="true"
        @close="$emit('close')"
        :size="modalSize">
        <h2
            class="mb-4"
            v-if="$store.state.auth.user.isTeacher">
            {{ $t("form.createStudent") }}
        </h2>
        <h2
            class="mb-4"
            v-if="$store.state.auth.user.isParent">
            {{ $t("form.createChildren") }}
        </h2>
        <TabbedButtons
            version="simple"
            @click="currentOptionValue = $event"
            :value="currentOptionValue"
            :options="options" />
        <form
            @submit.prevent="submit"
            class="mt-6">
            <Loader v-if="isLoading" />
            <div v-else>
                <div v-if="currentOptionValue === 'multiple'">
                    <div class="space-y-2">
                        <div
                            v-for="(student, idx) in bulkStudents"
                            :key="idx"
                            class="w-full flex flex-row justify-start items-center pr-2 space-x-3">
                            <BulkStudentForm
                                class="w-full"
                                ref="bulkStudentForm"
                                version="create"
                                :student="student"
                                :errors="bulkErrors[idx]" />

                            <SmallCircleButton
                                @click.prevent="removeBulkStudent(idx)"
                                icon="trash" />
                        </div>
                    </div>
                    <CustomButton @click.prevent="addBulkStudent">
                        {{ $t("students.addStudent") }}
                    </CustomButton>
                </div>

                <StudentForm
                    ref="studentForm"
                    version="create"
                    :student="student"
                    :errors="errors"
                    v-else />
            </div>
            <div class="flex flex-row justify-center items-center my-4 w-full">
                <CustomButton type="submit">
                    {{ $t("form.btnCreate") }}
                </CustomButton>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import StudentForm from "components/forms/StudentForm"
import cloneDeep from "lodash-es/cloneDeep"
import Loader from "components/Loader"
import TabbedButtons from "components/globals/TabbedButtons"
import BulkStudentForm from "components/forms/BulkStudentForm"
import SmallCircleButton from "components/globals/SmallCircleButton"

const STUDENT_INIT_DATA = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    gender: "",
    lang: "",
    country: "",
    region: "",
    learningLang: "",
    grade: 1,
    difficulty: 1,
    classroomId: null
}

export default {
    name: "CreateStudentModal",
    emits: ["close"],
    components: { SmallCircleButton, BulkStudentForm, TabbedButtons, Loader, StudentForm, CustomButton, Modal },
    props: {
        classroomId: Number
    },
    data() {
        return {
            currentOptionValue: "single",
            options: [
                {
                    label: this.$t("students.single"),
                    value: "single"
                },
                {
                    label: this.$t("students.multiple"),
                    value: "multiple"
                }
            ],

            bulkStudents: [],

            student: cloneDeep(STUDENT_INIT_DATA),
            // usernameAvailable: false,
            errors: {},
            bulkErrors: [], //init to first object
            isLoading: false
        }
    },
    async created() {
        this.student.lang = this.$store.state.auth.user.lang //from parent
        this.student.country = this.$store.state.auth.user.country
        this.student.region = this.$store.state.auth.user.region

        if (this.classroom) {
            this.student.classroomId = this.classroom.id
            this.student.grade = this.classroom.grade
        }

        if (this.$store.state.auth.user.teachingLangs.length > 0) {
            this.student.learningLang = this.$store.state.auth.user.teachingLangs[0]
        }

        this.addBulkStudent() //init
    },
    computed: {
        modalSize() {
            return this.currentOptionValue === "multiple" ? "big" : "small"
        },
        classroom() {
            if (!this.classroomId) return null
            return this.$store.state.userData.classrooms.find((c) => c.id === this.classroomId)
        }
    },
    methods: {
        addBulkStudent() {
            let newStudent = cloneDeep(STUDENT_INIT_DATA)

            newStudent.lang = this.$store.state.auth.user.lang //from parent
            newStudent.country = this.$store.state.auth.user.country
            newStudent.region = this.$store.state.auth.user.region

            if (this.classroom) {
                newStudent.classroomId = this.classroom.id
                newStudent.grade = this.classroom.grade
            }

            if (this.$store.state.auth.user.teachingLangs.length > 0) {
                newStudent.learningLang = this.$store.state.auth.user.teachingLangs[0]
            }

            this.bulkStudents.push(newStudent)
            this.bulkErrors.push({})
        },

        removeBulkStudent(idx) {
            this.bulkErrors.splice(idx, 1)
            this.bulkStudents.splice(idx, 1)
        },
        /*  async checkUsernameAvailable(){
        let res = await ajax.post('/user/students/available', {
          username: this.student.username
        })

        this.usernameAvailable = res.available

      },*/

        async createStudent() {
            this.errors = this.$refs.studentForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.isLoading = true

            this.$store
                .dispatch(types.USER_DATA.CREATE_STUDENT, this.student)
                .then((res) => {
                    this.isLoading = false
                    this.$emit("close")
                    if (this.classroom) {
                        this.$store.dispatch(types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID, this.classroom.uuid)
                    }
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        },
        async createBulkStudents() {
            //make sure we got students
            if (this.bulkStudents.length === 0) return

            let hasError = false
            this.bulkErrors = this.$refs.bulkStudentForm.map((form) => {
                let errors = form.validate()
                if (Object.keys(errors).length > 0) {
                    hasError = true
                }
                return errors
            })

            if (hasError) return

            this.isLoading = true

            let idx = 0
            for (let student of this.bulkStudents) {
                try {
                    await this.$store.dispatch(types.USER_DATA.CREATE_STUDENT, student)
                } catch (err) {
                    this.isLoading = false
                    this.bulkErrors[idx] = err.errors
                    return
                }
                idx++
            }

            this.isLoading = false
            this.$emit("close")
            if (this.classroom) {
                this.$store.dispatch(types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID, this.classroom.uuid)
            }
        },
        async submit() {
            if (this.currentOptionValue === "single") {
                this.createStudent()
            } else if (this.currentOptionValue === "multiple") {
                this.createBulkStudents()
            }
        }
    }
}
</script>
