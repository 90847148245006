import { io } from "socket.io-client"

export const createSocket = (authToken, type) => {
    const socket = io(process.env.VUE_APP_API_URL, {
        transports: ["websocket"],
        autoConnect: true,
        auth: {
            jwt: authToken
        },
        query: {
            type
        }
        //withCredentials: true
    })

    if (process.env.NODE_ENV !== "production") {
        socket.onAny((event, ...args) => {
            console.log(event, args)
        })
    }

    return socket
}
