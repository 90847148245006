<template>
    <CircleButton icon-class="text-purple w-7 min-w-7 h-7" icon="plus" />
</template>
<script>
import CircleButton from "components/globals/CircleButton"

export default {
    components: { CircleButton },
    name: "AddButton"
}
</script>
