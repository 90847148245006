<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="mb-2">
            {{ $t("form.createClassroom") }}
        </h2>
        <form @submit.prevent="createClassroom">
            <ClassroomForm
                ref="classroomForm"
                :classroom="classroom"
                :errors="errors" />
            <div class="flex flex-row justify-center items-center my-4 w-full">
                <CustomButton type="submit">
                    {{ $t("form.btnCreate") }}
                </CustomButton>
            </div>
        </form>
    </modal>
</template>

<script>
import * as types from "store/types"
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import ClassroomForm from "components/forms/ClassroomForm"
import { LANGS } from "@globals/constants"

export default {
    name: "CreateClassroomModal",
    emits: ["close", "created"],
    components: { ClassroomForm, CustomButton, Modal },
    data() {
        return {
            classroom: {
                title: "",
                grade: 1,
                lang: LANGS.EN,
                listGroupId: ""
            },

            errors: {}
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS)

        if (this.$store.state.auth.user.teachingLangs.length > 0) {
            this.classroom.lang = this.$store.state.auth.user.teachingLangs[0]
        }
    },
    methods: {
        async createClassroom() {
            this.errors = this.$refs.classroomForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            await this.$store.dispatch(types.USER_DATA.CREATE_CLASSROOM, this.classroom)
            this.$emit("created")
            this.$emit("close")
        }
    }
}
</script>
