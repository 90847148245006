<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="mt-6 text-center px-6">
            {{ $t("modal.leaveGame.title") }}
        </h2>
        <h3 class="text-center mt-2">
            {{ $t("modal.leaveGame.description") }}
        </h3>

        <div class="flex flex-row justify-center items-center space-x-3 mt-6">
            <CustomButton @click.prevent="$emit('exit')">
                {{ $t("form.btnQuit") }}
            </CustomButton>
            <CustomButton @click.prevent="$emit('stay')">
                {{ $t("form.btnCancel") }}
            </CustomButton>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "ConfirmQuittingGameModal",
    components: { CustomButton, Modal },
    emits: ["stay", "exit", "close"]
}
</script>
