<template>
    <div class="w-full h-full dashboard flex flex-row justify-center items-stretch">
        <simplebar class="scroll-container pt-0">
            <div class="w-full pb-6">
                <StudentHeader experience-class="block md:hidden">
                    <div class="flex flex-row justify-start items-center space-x-2 lg:-mt-6">
                        <img
                            class="w-32 h-auto hidden lg:block"
                            :src="smileys" />
                        <h1 class="text-white">{{ $t("home.hi") }} {{ $store.state.auth.student.name }}!</h1>
                    </div>
                </StudentHeader>

                <div
                    class="mt-6"
                    v-if="currentWordGroups.length > 0">
                    <div class="grid grid-cols-1 2xl:grid-cols-2 gap-12">
                        <WordGroupOfTheWeek
                            :class="{
                                '2xl:col-span-2': currentWordGroups.length === 1
                            }"
                            v-for="wordGroup in currentWordGroups"
                            :key="wordGroup.id"
                            :word-group="wordGroup" />
                    </div>
                </div>
                <div class="mt-12 grid grid-cols-1 xl:grid-cols-2 gap-12">
                    <router-link to="/student/list">
                        <Card color="yellow-20">
                            <div class="w-full h-full relative p-1">
                                <div class="flex flex-row justify-end items-center">
                                    <img
                                        class="w-5/6 h-64 object-right-top object-contain block"
                                        :src="soundImage" />
                                </div>
                                <div class="absolute left-6 bottom-6">
                                    <h3 v-html="$t('play.writeWordCorrectlyBySound')" />
                                </div>
                            </div>
                        </Card>
                    </router-link>
                    <router-link to="/student/list">
                        <Card color="yellow-20">
                            <div class="w-full h-full relative p-1">
                                <div class="flex flex-row justify-end items-center">
                                    <img
                                        class="w-5/6 h-64 object-right object-contain block"
                                        :src="wordOrderImage" />
                                </div>
                                <div class="absolute left-6 bottom-6">
                                    <h3 v-html="$t('play.placeWordsInOrder')" />
                                </div>
                            </div>
                        </Card>
                    </router-link>
                </div>
                <!-- <div class="mt-12">
                    <router-link to="/student/list">
                        <Card color="yellow-20">
                            <div class="w-full h-full flex flex-row justify-center items-center p-4">
                                <div class="w-full h-56 md:w-2/5 md:pr-6 flex flex-col justify-between items-stretch">
                                    <h2 v-html="$t('play.writeWordCorrectlyBySound')" />
                                    <div class="mt-6 inline-block">
                                        <CustomButton
                                            size="big"
                                            to="/student/list">
                                            {{ $t("list.seeAll") }}
                                        </CustomButton>
                                    </div>
                                </div>
                                <div class="w-full h-56 md:w-3/5 md:pl-6 flex flex-row justify-end items-center">
                                    <img
                                        class="w-full h-full object-right object-contain block"
                                        :src="soundImage" />
                                </div>
                            </div>
                        </Card>
                    </router-link>
                </div> -->
                <!--  <PurchaseCta class="mt-12"/> -->
            </div>
        </simplebar>

        <div class="hidden md:block pl-6 py-6">
            <Card class="min-w-72 h-full">
                <simplebar class="p-4 overflow-y-auto h-full max-h-full">
                    <div class="h-full">
                        <div>
                            <StudentAvatar :student="$store.state.auth.student" />
                        </div>
                        <h2 class="mt-4">
                            {{ $store.state.auth.student.name }}
                        </h2>
                        <div
                            v-if="studentExperience"
                            class="mt-4">
                            <div class="flex flex-row justify-between items-center space-x-2">
                                <ProgressBar
                                    :min-value="10"
                                    class="w-full"
                                    :value="$store.state.auth.student.pointsXP"
                                    :max-value="studentExperience.toNextLevel" />
                            </div>
                            <div class="flex flex-row justify-center items-center space-x-2 mt-4">
                                <div class="w-1/2">
                                    <h3 class="whitespace-nowrap leading-0 pt-3">
                                        {{ $t("global.level") }} {{ studentExperience.level }}
                                    </h3>
                                    <span class="text-11 text-gray-400 pt-2 block">
                                        {{ studentExperience.levelPercentage }}%
                                    </span>
                                </div>
                                <PointTag
                                    class="w-1/2"
                                    type="A"
                                    :value="$store.state.auth.student.pointsA" />
                            </div>
                        </div>
                        <div class="mt-4 pt-4 border-t border-purple-10">
                            <h3 class="font-medium">
                                {{ $t("home.myProgress") }}
                            </h3>

                            <ul
                                class="mt-2"
                                v-if="$store.state.studentData.statsWords">
                                <li class="text-gray-400 text-14 py-0.5">
                                    {{ $t("stats.wordsLearnt") }}:
                                    {{ $store.state.studentData.statsWords.learntWordsCount }}
                                </li>
                                <li class="text-gray-400 text-14 py-0.5">
                                    {{ $t("stats.successRate") }}:
                                    {{ $store.state.studentData.statsWords.successRate }}%
                                </li>
                                <li class="text-gray-400 text-14 py-0.5">
                                    {{ $t("stats.nextLevelIn") }}: {{ studentExperience.toNextLevel }}
                                    {{ $t("global.points") }}
                                </li>
                            </ul>
                            <div class="mt-4">
                                <CustomButton
                                    to="/student/progress"
                                    version="text"
                                    align="left">
                                    {{ $t("home.seeMyProgress") }}
                                </CustomButton>
                            </div>
                        </div>
                        <div class="mt-4 pt-4 border-t border-purple-10">
                            <h3 class="font-medium">
                                {{ $t("home.lastProductPurchased") }}
                            </h3>
                            <SmallProductCard
                                v-if="$store.state.studentData.global.lastUnlockedProduct"
                                :product="$store.state.studentData.global.lastUnlockedProduct"
                                class="mt-2" />
                            <div class="mt-4">
                                <CustomButton
                                    to="/student/inventory"
                                    version="text"
                                    align="left">
                                    {{ $t("home.seeMyPurchases") }}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </simplebar>
            </Card>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import PointTag from "components/globals/PointTag"
import ProgressBar from "components/globals/ProgressBar"
import CustomButton from "components/globals/CustomButton"
import finishSentenceImage from "assets/images/finish_sentence.png"
import wordOrderImage from "assets/images/words-order.png"
import soundImage from "assets/images/sound.png"
import StudentAvatar from "components/globals/StudentAvatar"
import WordGroupOfTheWeek from "components/cards/WordGroupOfTheWeek"
import Simplebar from "components/globals/Simplebar"
import SmallProductCard from "components/cards/SmallProductCard"
import StudentHeader from "components/globals/StudentHeader"
import smileys from "assets/images/smileys@2x.png"

export default {
    name: "Home",
    components: {
        StudentHeader,
        SmallProductCard,
        Simplebar,
        WordGroupOfTheWeek,
        StudentAvatar,
        CustomButton,
        ProgressBar,
        PointTag,
        Card
    },
    data() {
        return {
            smileys,
            finishSentenceImage,
            soundImage,
            wordOrderImage,
            gameCode: ""
        }
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_STATS_WORDS)
    },
    computed: {
        currentWordGroups() {
            return this.$store.state.studentData.global.currentWordGroups
        },
        studentExperience() {
            return this.$store.getters.getStudentExperience(this.$store.state.auth.student)
        }
    }
}
</script>
