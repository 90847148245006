import moment from "moment"

export default (app) => {
    app.mixin({
        created: function () {
            this.moment = moment
        },
        methods: {
            formatThousand(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            goBack() {
                this.$router.go(-1)
            },
            capitalize(value) {
                value = value.toString().toLowerCase()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            centsToDollars(cents) {
                return (cents / 100).toFixed(2)
            }
        }
    })
}
