<template>
    <img ref="img" />
</template>
<script>
import front1 from "assets/images/games/characters/marche_face_1.png"
import front2 from "assets/images/games/characters/marche_face_2.png"
import front3 from "assets/images/games/characters/marche_face_3.png"
import front4 from "assets/images/games/characters/marche_face_4.png"

import back1 from "assets/images/games/characters/marche_dos_1.png"
import back2 from "assets/images/games/characters/marche_dos_2.png"
import back3 from "assets/images/games/characters/marche_dos_3.png"
import back4 from "assets/images/games/characters/marche_dos_4.png"
import back5 from "assets/images/games/characters/marche_dos_5.png"

import right1 from "assets/images/games/characters/marche_droite_1.png"
import left1 from "assets/images/games/characters/marche_gauche_1.png"
import right2 from "assets/images/games/characters/marche_droite_2.png"
import left2 from "assets/images/games/characters/marche_gauche_2.png"
import right3 from "assets/images/games/characters/marche_droite_3.png"
import left3 from "assets/images/games/characters/marche_gauche_3.png"
import right4 from "assets/images/games/characters/marche_droite_4.png"
import left4 from "assets/images/games/characters/marche_gauche_4.png"
import right5 from "assets/images/games/characters/marche_droite_5.png"
import left5 from "assets/images/games/characters/marche_gauche_5.png"

import lick1 from "assets/images/games/characters/liche_1.png"
import lick2 from "assets/images/games/characters/liche_2.png"
import lick3 from "assets/images/games/characters/liche_3.png"
import lick4 from "assets/images/games/characters/liche_4.png"
import lick5 from "assets/images/games/characters/liche_5.png"
import lick6 from "assets/images/games/characters/liche_6.png"
import lick7 from "assets/images/games/characters/liche_7.png"

import lickLeft1 from "assets/images/games/characters/liche_gauche_1.png"
import lickLeft2 from "assets/images/games/characters/liche_gauche_2.png"
import lickLeft3 from "assets/images/games/characters/liche_gauche_3.png"
import lickLeft4 from "assets/images/games/characters/liche_gauche_4.png"
import lickLeft5 from "assets/images/games/characters/liche_gauche_5.png"
import lickLeft6 from "assets/images/games/characters/liche_gauche_6.png"
import lickLeft7 from "assets/images/games/characters/liche_gauche_7.png"

import { DIRECTIONS } from "services/constants"

const rightImages = [right1, right2, right3, right4, right5]
const leftImages = [left1, left2, left3, left4, left5]
const frontImages = [front1, front2, front3, front4]
const backImages = [back1, back2, back3, back4, back5]
const lickRightImages = [lick1, lick2, lick3, lick4, lick5, lick6, lick7]
const lickLeftImages = [lickLeft1, lickLeft2, lickLeft3, lickLeft4, lickLeft5, lickLeft6, lickLeft7]

export default {
    name: "Character",
    props: {
        type: String,
        direction: String,
        isLicking: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            DIRECTIONS
        }
    },
    created() {
        this.imageIdx = 0
        this.lastImage = right1 //default
        this.lastDirection = this.direction
    },
    mounted() {
        this.$refs.img.src = this.lastImage
    },
    watch: {
        isLicking: function (newVal, oldVal) {
            if (newVal === true) {
                this.startLickingAnimation()
            } else {
                this.stopLickingAnimation()
            }
        },
        direction: function (newVal, oldVal) {
            if (newVal === null) {
                this.stopAnimation()
                return
            }
            if (this.lastDirection !== newVal) {
                this.imageIdx = 0
            }
            if (newVal === DIRECTIONS.RIGHT) {
                this.startAnimation(rightImages)
            } else if (newVal === DIRECTIONS.DOWN) {
                this.startAnimation(frontImages)
            } else if (newVal === DIRECTIONS.UP) {
                this.startAnimation(backImages)
            } else if (newVal === DIRECTIONS.LEFT) {
                this.startAnimation(leftImages)
            }
            this.lastDirection = newVal
        }
    },
    beforeUnmount() {
        this.stopAnimation()
        this.stopLickingAnimation()
    },
    methods: {
        startLickingAnimation() {
            if (this.lastDirection === DIRECTIONS.LEFT) {
                this.startAnimation(lickLeftImages)
            } else if (this.lastDirection === DIRECTIONS.RIGHT) {
                //default
                this.startAnimation(lickRightImages)
            } else if (this.lastDirection === DIRECTIONS.UP) {
                this.startAnimation(backImages)
            } else if (this.lastDirection === DIRECTIONS.DOWN) {
                this.startAnimation(frontImages)
            }
        },
        stopLickingAnimation() {
            this.stopAnimation()
            this.imageIdx = 0

            //make sure we reset animation
            if (this.lastDirection === DIRECTIONS.LEFT) {
                this.setImage(leftImages)
            } else if (this.lastDirection === DIRECTIONS.RIGHT) {
                //default
                this.setImage(rightImages)
            }
        },
        stopAnimation() {
            clearInterval(this.animationId)
        },
        startAnimation(images) {
            clearInterval(this.animationId)
            this.setImage(images)
            this.animationId = setInterval(() => {
                this.setImage(images)
            }, 110) //movement speed
        },
        setImage(images) {
            this.$refs.img.src = images[this.imageIdx]
            this.lastImage = images[this.imageIdx]
            this.imageIdx++
            if (this.imageIdx >= images.length) {
                this.imageIdx = 0 //restart animation
            }
        }
    }
}
</script>
<style lang="scss" scoped>
img {
    display: block;
    width: auto;
    height: 100%;
}
</style>
