<template>
    <div class="bg-gray-50 flex flex-col justify-center py-12 container">
        <div class="mt-12 md:mt-8 mx-auto px-4 w-full md:px-0 md:w-2/3 lg:w-1/2">
            <img
                class="h-16 mx-auto block w-auto"
                :src="logo"
                alt="Adimo logo" />
            <p class="font-semibold mt-1 text-center -small">
                Beta
            </p>
            <div class="bg-white shadow-xl rounded p-6 md:p-12 mt-12 flex flex-col justify-center items-center">
                <router-view />
            </div>
            <div class="flex flex-row justify-center items-center mx-auto">
                <CustomButton
                    v-if="showRegisterBtn"
                    to="/auth/register"
                    class="mt-6">
                    {{ $t("auth.btnCreateAccount") }}
                </CustomButton>
                <CustomButton
                    v-if="showLoginBtn"
                    to="/auth/login"
                    class="mt-6">
                    {{ $t("auth.btnLogin") }}
                </CustomButton>
            </div>
            <div class="flex flex-row justify-center items-center space-x-6 pb-6 mx-auto mt-12">
                <router-link
                    class="text-purple -small"
                    to="/terms"
                >{{ $t("auth.terms") }}
                </router-link
                >
                <router-link
                    class="text-purple -small"
                    to="/privacy"
                >{{ $t("auth.privacy") }}
                </router-link
                >
            </div>
        </div>
    </div>
    <!-- just FR for now <div class="absolute flex flex-row justify-center md:justify-end items-center left-6 top-3 md:top-6 right-6">
        <LangDropdown />
    </div> -->
</template>
<script>
import logo from "@globals/assets/logos/logo.svg"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import { selectLang } from "config/i18n"
import { LANGS } from "@globals/constants"

export default {
    name: "AuthLayout",
    components: { CustomButton },
    data() {
        return {
            LANGS,
            logo
        }
    },
    created() {
        this.$store.commit(types.LOGOUT)
    },
    computed: {
        showRegisterBtn() {
            if (this.$route.path.startsWith("/auth/register")) {
                return false
            }
            return true
        },
        showLoginBtn() {
            if (this.$route.path.startsWith("/auth/login")) {
                return false
            }
            return true
        }
    },
    methods: {
        changeLang(lang) {
            selectLang(lang)
        }
    }
}
</script>
