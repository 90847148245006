<template>
    <div>
        <h2 class="mb-6 text-center">
            {{ $t("auth.studentIntro") }}
        </h2>
        <p class="my-4 text-purple -small text-center">
            {{ $t("auth.studentDescription") }}
        </p>
        <div class="w-3/4 mx-auto lg:w-1/2 2xl:w-1/3">
            <CustomButton
                type="button"
                to="/auth/register/parent"
                class="w-full mb-4">
                {{ $t("auth.continueWithParent") }}
            </CustomButton>
            <CustomButton
                class="w-full"
                type="button"
                to="/auth/register/student/classroom">
                {{ $t("auth.goToStudentRegisterWithClassroom") }}
            </CustomButton>
        </div>

        <CustomButton
            class="mt-6"
            version="text"
            @click.prevent="goBack">
            {{ $t("global.goBack") }}
        </CustomButton>
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton"

export default {
    name: "RegisterInstructionsStudent",
    components: { CustomButton }
}
</script>
