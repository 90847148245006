export const LOGIN_STUDENT_WITH_SHORTCODE = "LOGIN_STUDENT_WITH_SHORTCODE"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const FORGOT_PWD = "FORGOT_PWD"
export const STUDENT_JWT = "STUDENT_JWT"
export const USER_JWT = "USER_JWT"
export const GET_USER = "GET_USER"
export const GET_STUDENT = "GET_STUDENT"
export const REGISTER = "REGISTER"
export const USER_RESET_PWD = "USER_RESET_PWD"
export const STUDENT_RESET_PWD = "STUDENT_RESET_PWD"
export const USER_REGISTER = "USER_REGISTER"
export const UPDATE_ANONYMOUS = "UPDATE_ANONYMOUS"
export const ANONYMOUS_LOGIN = "ANONYMOUS_LOGIN"
export const ANONYMOUS_JWT = "ANONYMOUS_JWT"
export const GET_ANONYMOUS = "GET_ANONYMOUS"
export const STUDENT_REGISTER_WITH_CLASSROOM = "STUDENT_REGISTER_WITH_CLASSROOM"
export const SET_FINISHED_SESSION = "SET_FINISHED_SESSION"

export const STUDENT_DATA = {
    GET_MY_GAMES: "STUDENT_DATA/GET_MY_GAMES",
    GET_GAME_BY_CODE: "STUDENT_DATA/GET_GAME_BY_CODE",
    CREATE_GAME: "STUDENT_DATA/CREATE_GAME",

    GET_CONTEST_PLAYABLE_WORD_GROUPS: "STUDENT_DATA/GET_CONTEST_PLAYABLE_WORD_GROUPS",
    GET_WORD_GROUPS: "STUDENT_DATA/GET_WORD_GROUPS",
    GET_WORDS_BY_WORD_GROUP_UUID: "STUDENT_DATA/GET_WORDS_BY_WORD_GROUP_UUID",
    GET_WORD_GROUP_BY_UUID: "STUDENT_DATA/GET_WORD_GROUP_BY_UUID",
    GET_WORD_GROUP_BY_ID: "STUDENT_DATA/GET_WORD_GROUP_BY_ID",

    GET_WORDS: "STUDENT_DATA/GET_WORDS",
    GET_WORD_BY_ID: "STUDENT_DATA/GET_WORD_BY_ID",
    GET_WORD_BY_UUID: "STUDENT_DATA/GET_WORD_BY_UUID",

    GET_NOTIFICATION_BY_UUID: "STUDENT_DATA/GET_NOTIFICATION_BY_UUID",
    REMOVE_NOTIFICATION_BY_UUID: "STUDENT_DATA/REMOVE_NOTIFICATION_BY_UUID",
    SEEN_NOTIFICATION_BY_UUID: "STUDENT_DATA/SEEN_NOTIFICATION_BY_UUID",
    GET_NOTIFICATIONS: "STUDENT_DATA/GET_NOTIFICATIONS",

    GET_GLOBALS: "STUDENT_DATA/GET_GLOBALS",

    GET_MY_PRODUCTS: "STUDENT_DATA/GET_MY_PRODUCTS",
    GET_PRODUCT_BY_ID: "STUDENT_DATA/GET_PRODUCT_BY_ID",
    BUY_PRODUCT_BY_UUID: "STUDENT_DATA/BUY_PRODUCT_BY_UUID",
    USE_PRODUCT_BY_UUID: "STUDENT_DATA/USE_PRODUCT_BY_UUID",
    GET_BOUGHT_PRODUCTS: "STUDENT_DATA/GET_BOUGHT_PRODUCTS",
    GET_USED_PRODUCTS: "STUDENT_DATA/GET_USED_PRODUCTS",

    IS_FETCHING_PRODUCT_BY_ID: "STUDENT_DATA/IS_FETCHING_PRODUCT_BY_ID",

    GET_LEAGUES: "STUDENT_DATA/GET_LEAGUES",
    GET_FRIENDS: "STUDENT_DATA/GET_FRIENDS",
    CREATE_LEAGUE: "STUDENT_DATA/CREATE_LEAGUE",
    UPDATE_LEAGUE: "STUDENT_DATA/UPDATE_LEAGUE",
    DELETE_LEAGUE: "STUDENT_DATA/DELETE_LEAGUE",

    GET_STANDINGS: "STUDENT_DATA/GET_STANDINGS",
    GET_STATS_WORDS: "STUDENT_DATA/GET_STATS_WORDS",

    JOIN_LEAGUE: "STUDENT_DATA/JOIN_LEAGUE",
    GET_LEAGUE_INVITATIONS: "STUDENT_DATA/LEAGUE_INVITATIONS",
    GET_LEAGUE_INVITATION_BY_UUID: "STUDENT_DATA/GET_LEAGUE_INVITATION_BY_UUID",

    GET_LAST_HOUR_LIVE_BATTLE_SCOREBOARD: "STUDENT_DATA/GET_LAST_HOUR_LIVE_BATTLE_SCOREBOARD",

    GET_CLASSROOMS: "STUDENT_DATA/GET_CLASSROOMS",

    STUDENT_PLAYED_GAME: "STUDENT_DATA/STUDENT_PLAYED_GAME"
}

export const USER_DATA = {
    SET_FETCH: "USER_DATA/SET_FETCH",

    DELETE_CLASSROOM: "USER_DATA/DELETE_CLASSROOM",
    EDIT_CLASSROOM: "USER_DATA/EDIT_CLASSROOM",
    CREATE_CLASSROOM: "USER_DATA/CREATE_CLASSROOM",
    GET_CLASSROOMS: "USER_DATA/GET_CLASSROOMS",
    GET_CLASSROOM_BY_UUID: "USER_DATA/GET_CLASSROOM_BY_UUID",
    GET_CLASSROOM_BY_ID: "USER_DATA/GET_CLASSROOM_BY_ID",

    DELETE_STUDENT: "USER_DATA/DELETE_STUDENT",
    EDIT_STUDENT: "USER_DATA/EDIT_STUDENT",
    CREATE_STUDENT: "USER_DATA/CREATE_STUDENT",
    GET_STUDENTS: "USER_DATA/GET_STUDENTS",
    GET_STUDENT_BY_ID: "USER_DATA/GET_STUDENT_BY_ID",
    GET_STUDENT_BY_UUID: "USER_DATA/GET_STUDENT_BY_UUID",
    GET_STUDENTS_BY_CLASSROOM_UUID: "USER_DATA/GET_STUDENTS_BY_CLASSROOM_UUID",

    GET_GLOBALS: "USER_DATA/GET_GLOBALS",

    GET_PRODUCT_BY_ID: "USER_DATA/GET_PRODUCT_BY_ID",

    IS_FETCHING_PRODUCT_BY_ID: "USER_DATA/IS_FETCHING_PRODUCT_BY_ID",

    GET_WORD_GROUPS_BY_LIST_GROUP_UUID: "USER_DATA/GET_WORD_GROUPS_BY_LIST_GROUP_UUID",

    DELETE_WORD_GROUP: "USER_DATA/DELETE_WORD_GROUP",
    EDIT_WORD_GROUP: "USER_DATA/EDIT_WORD_GROUP",
    CREATE_WORD_GROUP: "USER_DATA/CREATE_WORD_GROUP",
    GET_WORD_GROUPS: "USER_DATA/GET_WORD_GROUPS",
    GET_WORDS_BY_WORD_GROUP_UUID: "USER_DATA/GET_WORDS_BY_WORD_GROUP_UUID",
    GET_WORD_GROUP_BY_UUID: "USER_DATA/GET_WORD_GROUP_BY_UUID",
    GET_WORD_GROUP_BY_ID: "USER_DATA/GET_WORD_GROUP_BY_ID",
    DUPLICATE_WORD_GROUP_BY_UUID: "USER_DATA/DUPLICATE_WORD_GROUP_BY_UUID",
    GET_CONTEST_PLAYABLE_WORD_GROUPS: "USER_DATA/GET_CONTEST_PLAYABLE_WORD_GROUPS",

    DUPLICATE_LIST_IN_LIST_GROUP: "USER_DATA/DUPLICATE_LIST_IN_LIST_GROUP",
    DELETE_LIST_GROUP: "USER_DATA/DELETE_LIST_GROUP",
    EDIT_LIST_GROUP: "USER_DATA/EDIT_LIST_GROUP",
    CREATE_LIST_GROUP: "USER_DATA/CREATE_LIST_GROUP",
    GET_LIST_GROUPS: "USER_DATA/GET_LIST_GROUPS",
    GET_WORDS_BY_LIST_GROUP_UUID: "USER_DATA/GET_WORDS_BY_LIST_GROUP_UUID",
    GET_LIST_GROUP_BY_ID: "USER_DATA/GET_LIST_GROUP_BY_ID",

    GET_WORDS: "USER_DATA/GET_WORDS",
    GET_WORD_BY_ID: "USER_DATA/GET_WORD_BY_ID",
    GET_WORD_BY_UUID: "USER_DATA/GET_WORD_BY_UUID",

    GET_PAYMENTS: "USER_DATA/GET_PAYMENTS",
    // GET_LICENSE_GROUPS: "USER_DATA/GET_LICENSE_GROUPS",

    GET_GAMES_BY_CLASSROOM_UUID: "USER_DATA/GET_GAMES_BY_CLASSROOM_UUID",

    GET_CURRENT_PAYMENT_PLAN: "USER_DATA/GET_CURRENT_PAYMENT_PLAN",

    SET_CURRENT_CLASSROOM_UUID: "USER_DATA/SET_CURRENT_CLASSROOM_UUID"
}

export const ANONYMOUS_DATA = {
    GET_WORD_GROUPS: "ANONYMOUS_DATA/GET_WORD_GROUPS",
    GET_WORDS_BY_WORD_GROUP_UUID: "ANONYMOUS_DATA/GET_WORDS_BY_WORD_GROUP_UUID",
    GET_WORD_GROUP_BY_UUID: "ANONYMOUS_DATA/GET_WORD_GROUP_BY_UUID",
    GET_WORD_GROUP_BY_ID: "ANONYMOUS_DATA/GET_WORD_GROUP_BY_ID",

    GET_WORDS: "ANONYMOUS_DATA/GET_WORDS",
    GET_WORD_BY_ID: "ANONYMOUS_DATA/GET_WORD_BY_ID",
    GET_WORD_BY_UUID: "ANONYMOUS_DATA/GET_WORD_BY_UUID",

    GET_GLOBALS: "ANONYMOUS_DATA/GET_GLOBALS",

    GET_PRODUCT_BY_ID: "ANONYMOUS_DATA/GET_PRODUCT_BY_ID",

    IS_FETCHING_PRODUCT_BY_ID: "ANONYMOUS_DATA/IS_FETCHING_PRODUCT_BY_ID",

    ANONYMOUS_PLAYED_GAME: "ANONYMOUS_DATA/ANONYMOUS_PLAYED_GAME"
}
