<template>
    <div class="c__input">
        <label>
            {{ $t("form.leagueTitle") }}
        </label>
        <input
            type="text"
            name="name"
            min="4"
            max="191"
            v-model="league.name" />
        <p
            class="error"
            v-if="errors.name">
            {{ errors.name }}
        </p>
    </div>
    <div class="c__input">
        <label>
            {{ $t("form.leagueType") }}
        </label>
        <div class="c__select">
            <select
                v-model="league.type"
                class="form-select">
                <option
                    v-for="item in types"
                    :key="item.value"
                    :value="item.value">
                    {{ item.label }}
                </option>
            </select>
        </div>
        <p
            class="error"
            v-if="errors.type">
            {{ errors.type }}
        </p>
    </div>
</template>
<script>
export default {
    name: "LeagueForm",
    props: {
        league: {
            type: Object
        },
        errors: {
            default: () => {},
            type: Object
        }
    },
    data() {
        return {
            types: [
                {
                    value: "public",
                    label: this.$t("form.leaguePublic")
                },
                {
                    value: "private",
                    label: this.$t("form.leaguePrivate")
                }
            ]
        }
    },
    methods: {
        validate() {
            let errors = {}

            if (!this.league.name) {
                errors.name = this.$t("validationErrors.league")
            }
            if (this.league.name.length < 4 || this.league.name.length > 191) {
                errors.name = this.$t("validationErrors.leagueLength")
            }
            return errors
        }
    }
}
</script>
