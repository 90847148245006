<template>
    <Card
        color="white"
        class="word-group-of-the-week">
        <div class="flex flex-row justify-start items-stretch h-full relative">
            <div class="w-full md:w-1/2 pl-6 py-6 md:pr-12">
                <div class="flex flex-row justify-start items-center space-x-2">
                    <p class="text-purple">
                        {{ $t("home.listOfTheWeek") }}
                    </p>
                    <Icons
                        icon="sparkles"
                        class="text-red w-8 h-8" />
                </div>


                <h1
                    class="mt-1"
                    v-if="learningTraduction">
                    {{ capitalize(learningTraduction.text) }}
                </h1>
                <h1
                    class="mt-1"
                    v-else-if="traduction">
                    {{ capitalize(traduction.text) }}
                </h1>
                <h1
                    class="mt-1"
                    v-else>
                    {{ capitalize(wordGroup.text) }}
                </h1>

                <h3 class="mt-3">{{ wordGroup.wordIds.length }} {{ $t("list.words") }}</h3>
                <div class="mt-6 inline-block">
                    <CustomButton
                        size="big"
                        :to="`/student/list/${wordGroup.uuid}`">
                        {{ $t("list.see") }}
                    </CustomButton>
                </div>
            </div>
            <div class="absolute top-0 bottom-0 right-0">
                <img
                    alt="Bateau | Adimo"
                    class="word-group-of-the-week__img"
                    :src="image" />
            </div>
        </div>
    </Card>
</template>

<script>
import CustomButton from "components/globals/CustomButton"
import Card from "components/cards/Card"
import image from "assets/images/bateau.png"
import Icons from "components/globals/Icons.vue"

export default {
    name: "WordGroupOfTheWeek",
    components: { Icons, Card, CustomButton },
    props: {
        wordGroup: Object
    },
    data() {
        return {
            image
        }
    },
    computed: {
        learningTraduction() {
            if (!this.wordGroup) return null
            return this.$store.getters.getStudentLearningTraduction(this.wordGroup)
        },
        traduction() {
            if (!this.wordGroup) return null
            return this.$store.getters.getStudentTraduction(this.wordGroup)
        }
    }
}
</script>

<style lang="scss">
.word-group-of-the-week {
  &__img {
    @apply w-auto object-contain block relative;
    height: 100%;
    top: 0;
    bottom: 0;
  }
}
</style>
