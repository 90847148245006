<template>
    <modal
        :show="true"
        v-if="showModal"
        :closeable="false">
        <h2 class="mt-6 text-center px-6">
            {{ $t("modal.noLicense.title") }}
        </h2>
        <p class="-small text-purple text-center mt-2">
            {{ $t("modal.noLicense.description") }}
        </p>

        <div class="flex flex-col justify-center items-center mt-6">
            <CustomButton
                class="mt-4"
                to="/auth/login">
                {{ $t("modal.noLicense.loginWithTeacher") }}
            </CustomButton>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "NoLicenseModal",
    components: { CustomButton, Modal },
    emits: [],
    computed: {
        showModal() {
            if (!this.$store.state.auth.student) return false
            return !this.$store.state.auth.student.licenseId
        }
    }
}
</script>
