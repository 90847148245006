<template>
    <info-layout>
        <h1>Conditions d'utilisation</h1>

        <p>
            Puisque nous sommes basés au Québec, les taxes de vente (TPS et TVQ) sont applicables sur tout abonnement.
        </p>

        <h2>Parties contractantes</h2>

        <p>Les ventes régies par les présentes sont conclues entre le Client et la société :</p>

        <p>
            Dialecte Numérique inc<br />
            210-5300 boulevard des Galeries,<br />
            Québec QC,<br />
            G2K 2A2 CANADA<br/>
            info@adimo.app<br />
        </p>

        <p>Toute commande oblige le client à son paiement</p>

        <h2>Champ d'application</h2>

        <p>
            L’accès illimité au contenu du site est payant et implique une souscription de la part du client. Toute
            souscription est avec obligation de paiement.
        </p>
        <p>
            Notre site est configuré et compatible avec les systèmes d’exploitation standards habituels les plus
            utilisés (accessible sur ordinateurs pc et mac, tablettes ipad et Android, Smartphones et iphones). Adimo
            ne peut être engagée ne peut être engagé comme responsable si le système d’exploitation du matériel du
            client ne lui permet pas un libre et complet accès à notre site.
        </p>
        <p>
            Les présentes conditions de vente définissent les modalités de fourniture par Adimo, sur son site
            www.adimo.app, www.app.adimo.app, de contenus numériques non fournis sur un support matériel, à un consommateur pour son usage
            personnel et non commercial.
        </p>
        <p>
            Pour effectuer une commande, le client doit avoir la pleine capacité de contracter et reconnaît qu’il est
            l’utilisateur final des contenus (directement ou en qualité de représentant légal du ou des mineurs
            utilisateurs).
        </p>
        <p>
            Le fait que Adimo ne se prévale pas à un moment donné de l’une des stipulations des présentes Conditions
            d’utilisations ne peut être interprété comme une renonciation à s’en prévaloir ultérieurement.
        </p>

        <h2>Commande et accès au contenu</h2>

        <p>
            Une commande nécessite que tous les champs requis soient remplis par le client avec les informations
            complètes, actuelles et exactes. Toute erreur du client (en particulier sur les mentions relatives à
            l’adresse postale, l’adresse Internet et le numéro de téléphone du client) exonère Adimo de toute
            responsabilité du fait de ces erreurs.
        </p>

        <p>
            Le client choisit des identifiants, nom d’utilisateur et mot de passe, dont il assume la mémorisation et
            l’usage sous sa seule responsabilité. Si vous souscrivez un abonnement pour votre enfant, il est préférable
            de noter à un endroit sécuritaire les identifiants et mot de passe de votre enfant.
        </p>

        <p>
            La confirmation de commande en ligne est adressée au client dès qu’il a payé l’abonnement. Le client doit
            vérifier qu’il a bien reçu sa confirmation et, à défaut, nous informer par écrit (si possible sur notre site
            via notre onglet « contact ») de la non réception de cette confirmation.
        </p>

        <p>
            A défaut, la preuve de l’envoi de la confirmation de commande à l’adresse telle qu’exactement indiquée par
            le client sur sa commande vaudra présomption de réception.
        </p>

        <p>
            La commande validée et payée est passée au nom du client avec lequel la convention est conclue. Le contrat
            ainsi passé est personnel au client et aucun des obligations et droits ne peut être transféré, de quelque
            manière que ce soit, en tout ou partie, à un tiers.
        </p>

        <h2>Facturation et Paiement</h2>

        <p>
            La saisie en ligne du numéro de carte bancaire du client et sa validation finale de la commande feront
            preuve de sa commande en son nom et pour son compte et exigibilité des sommes figurant sur le bon de
            commande.
        </p>

        <h2>Conditions d’exercice du droit de rétractation</h2>

        <p>
            Le client dispose d’un délai de quatorze jours francs pour exercer son droit de rétractation sans avoir à
            justifier de motifs. Ce délai court à compter de la conclusion du contrat de souscription. Veuillez nous contactez par le formulaire de contact
            <a href="https://www.adimo.app/#contact" class="inline-block underline">ici</a>
        </p>

        <h2>Durée de l’abonnement initial et renouvellement</h2>

        <p>
            Sur Adimo, tout abonnement est valide de la date où la souscription a été complétée jusqu’a la même date de l'année suivante.
        </p>

        <p>
            Lors de chaque renouvellement, le client devra, le cas échéant, nous informer de toute modification de ses
            données telles qu’inscrites lors de la souscription précédente.
        </p>

        <p>
            Le client s’engage donc à maintenir ses données à jour : celles nécessaires à l’identification, la
            correspondance et le règlement des abonnements en particulier.
        </p>

        <h2>Garantie et Responsabilité</h2>

        <p>Le client a 14 jours pour se faire une idée des contenus variés proposés.</p>

        <p>
            S'il n'est pas satisfait, le client peut résilier son abonnement et un remboursement complet sera effectué sur sa
            carte de crédit ou son compte Paypal. En conséquence, le client est présumé parfaitement informé de la
            nature du site, de son objet et des contenus proposés et de la seule obligation de moyens de Adimo.
        </p>

        <p>
            Adimo assume à l’égard du client toutes obligations que la loi met à sa charge quant à la fourniture du
            contenu commandé au titre des garanties légales.
        </p>

        <p>
            Le client fait son affaire personnelle, pendant toute la durée de son (ses) abonnement(s) de son équipement
            en matériel, logiciels, systèmes d’exploitation et utilitaires standards nécessaires au parfait accès au
            contenu et de leur bon fonctionnement.
        </p>

        <p>
            Adimo présente son contenu dans un format standard utilisé au Canada. Adimo fait régulièrement évoluer ses
            contenus. Des modifications y sont apportées régulièrement de façon à toujours améliorer le service et
            l’expérience fournie au client. Cette nécessité d’évolution est inhérente à la nature de l’objet de notre
            site, ce que reconnait et accepte expressément le client. Aucune modification d’abonnement ne peut
            intervenir de ce fait.
        </p>

        <p>
            Conformément à la réglementation, Adimo s’engage à respecter ses obligations relatives aux garanties
            attachées le cas échéant à la fourniture de contenu numérique non fourni sur un support matériel.
        </p>

        <h2>Droit applicable et juridiction</h2>

        <p>
            La version applicable aux ventes est la version en langue française. Les ventes passées sur le site sont
            soumises au droit québecois et canadien. Tout litige survenant entre et un consommateur acquéreur des produits pour son
            usage personnel sera soumis aux tribunaux du siège social du vendeur.
        </p>
    </info-layout>
</template>

<script>
import InfoLayout from "views/layouts/InfoLayout"

export default {
    name: "Terms",
    components: { InfoLayout }
}
</script>
