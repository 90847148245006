<template>
    <div class="w-full h-full max-h-full">
        <Loader v-if="isLoading" />
        <div
            class="flex flex-col justify-start items-start w-full h-full max-h-full"
            v-else>
            <h2>
                {{ $t("billing.invoices") }}
            </h2>
            <simplebar class="w-full h-0 flex-1">
                <div class="">
                    <div
                        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10 mt-6"
                        v-if="$store.state.userData.payments.length > 0">
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.amount") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.type") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.date") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.invoice") }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex flex-row justify-start items-center w-full space-x-1 py-2"
                        v-for="(payment, idx) in $store.state.userData.payments"
                        :class="{
                            'border-t border-purple-10': idx !== 0
                        }"
                        :key="payment.id">
                        <div class="w-1/4">
                            <p
                                class="-small"
                                :class="`${payment.refunded ? 'text-red' : 'text-purple'}`">
                                ${{ centsToDollars(payment.refunded ? 0 : payment.transaction.amount) }}
                                {{ $t("billing.simplePriceInfo") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p
                                class="-small"
                                :class="`${payment.refunded ? 'text-red' : 'text-purple'}`"
                                v-if="payment.type === 'subscription'">
                                {{ $t("billing.typeSubscription") }}
                                <span
                                    class="text-11 block"
                                    v-if="payment.transaction.student">
                                    {{ payment.transaction.student.name }}
                                </span>
                            </p>
                            <p
                                class="-small"
                                :class="`${payment.refunded ? 'text-red' : 'text-purple'}`"
                                v-else-if="payment.type === 'license'">
                                {{ $t("billing.typeLicense") }}
                            </p>
                            <p
                                class="-small"
                                :class="`${payment.refunded ? 'text-red' : 'text-purple'}`"
                                v-else-if="payment.type === 'renew-license'">
                                {{ $t("billing.typeRenewLicense") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p
                                class="-small"
                                :class="`${payment.refunded ? 'text-red' : 'text-purple'}`">
                                {{ moment.utc(payment.createdAt).local().format("LL") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p
                                class="-small font-medium cursor-pointer underline"
                                :class="`${payment.refunded ? 'text-red' : 'text-purple'}`"
                                @click.prevent="downloadInvoice(payment)">
                                {{ $t("billing.download") }}
                            </p>
                        </div>
                    </div>
                    <p
                        class="text-purple -small mt-2"
                        v-if="$store.state.userData.payments.length === 0">
                        {{ $t("billing.noInvoices") }}
                    </p>
                </div>
            </simplebar>
        </div>
    </div>
</template>

<script>
import Loader from "components/Loader"
import Simplebar from "components/globals/Simplebar"
import { downloadFile } from "helpers/helpers"

export default {
    name: "Transactions",
    components: { Simplebar, Loader },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        downloadInvoice(payment) {
            downloadFile(payment.invoiceUrl)
        }
    }
}
</script>
