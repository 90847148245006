import { createStore } from "vuex"
import auth from "./modules/auth"
import studentData from "./modules/studentData"
import userData from "./modules/userData"
import anonymousData from "./modules/anonymousData"

export default createStore({
    modules: {
        auth,
        studentData,
        userData,
        anonymousData
    }
})
