import frFlag from "svg-country-flags/svg/fr.svg"
import enFlag from "svg-country-flags/svg/gb.svg"
import esFlag from "svg-country-flags/svg/es.svg"
import deFlag from "svg-country-flags/svg/de.svg"
import itFlag from "svg-country-flags/svg/it.svg"
import ptFlag from "svg-country-flags/svg/pt.svg"
import { LANGS } from "@globals/constants"

export const FLAGS = {
    [LANGS.FR]: frFlag,
    [LANGS.EN]: enFlag,
    [LANGS.ES]: esFlag,
    [LANGS.DE]: deFlag,
    [LANGS.IT]: itFlag,
    [LANGS.PT]: ptFlag
}
