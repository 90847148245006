<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <div class="flex flex-col justify-start items-start max-h-full">
            <h2 class="mb-6">
                {{ $t("form.createListGroup") }}
            </h2>
            <form
                @submit.prevent="createListGroup"
                class="w-full max-h-full flex flex-col justify-start items-start overflow-y-hidden">
                <ListGroupForm
                    ref="listGroupForm"
                    :list-group="listGroup"
                    :errors="errors" />

                <div class="flex flex-row justify-center items-center my-4 w-full">
                    <CustomButton type="submit">
                        {{ $t("form.btnCreate") }}
                    </CustomButton>
                </div>
            </form>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import ListGroupForm from "components/forms/ListGroupForm"

export default {
    name: "CreateListGroupModal",
    emits: ["close", "created"],
    components: { ListGroupForm, CustomButton, Modal },

    data() {
        return {
            listGroup: {
                title: ""
            },
            errors: {}
        }
    },
    created() {},
    methods: {
        async createListGroup() {
            this.errors = this.$refs.listGroupForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            await this.$store.dispatch(types.USER_DATA.CREATE_LIST_GROUP, this.listGroup)
            this.$emit("close")
            this.$emit("created")
        }
    }
}
</script>
