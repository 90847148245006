<template>
    <UserHeader class="block lg:hidden mb-6">
        <h1>
            {{ $t("settings.title") }}
        </h1>
    </UserHeader>
    <div class="py-6 w-full h-full">
        <Card class="w-full h-full">
            <simplebar class="scroll-container">
                <div class="px-6">
                    <div class="">
                        <form @submit.prevent="updateUser">
                            <h2>
                                {{ $t("settings.title") }}
                            </h2>
                            <div class="mt-6">
                                <UserForm
                                    ref="userForm"
                                    :errors="errors"
                                    :user="user" />
                            </div>
                            <div class="inline-block mt-6">
                                <CustomButton type="submit">
                                    {{ $t("form.btnSave") }}
                                </CustomButton>
                            </div>
                        </form>
                    </div>
                </div>
            </simplebar>
        </Card>
    </div>
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import EventBus, { EVENTS } from "@/eventbus"
import Simplebar from "components/globals/Simplebar"
import UserHeader from "components/globals/UserHeader"
import UserForm from "components/forms/UserForm"
import cloneDeep from "lodash-es/cloneDeep"
export default {
    name: "UserSettings",
    components: { UserForm, UserHeader, Simplebar, CustomButton, Card },
    data() {
        return {
            user: {},
            errors: {}
        }
    },
    created() {
        this.user = cloneDeep(this.$store.state.auth.user)

        //show licenses count for teacher

        //todo allow user to change from parent to teacher, etc
    },
    computed: {},
    methods: {
        updateUser() {
            this.errors = this.$refs.userForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store
                .dispatch(types.UPDATE_USER, this.user)
                .then((user) => {
                    this.user = cloneDeep(user)
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        title: this.$t("flash.success"),
                        message: this.$t("flash.accountSaved"),
                        type: "success"
                    })
                })
                .catch((res) => {
                    this.errors = res.errors
                })
        }
    }
}
</script>
