<template>
    <div class="flex flex-col flex-wrap md:flex-nowrap justify-center items-center h-full space-y-6">
        <div
            class="flex flex-row flex-wrap md:flex-nowrap justify-center items-stretch w-full h-full"
            v-if="currentSection">
            <div class="w-full md:w-1/2 md:pr-3">
                <div class="grid grid-cols-1 grid-rows-4 gap-3 md:gap-6 h-full p-3 md:p-6 rounded bg-white">
                    <button
                        v-for="(word, index) in currentWordsOrdered"
                        :key="word.id"
                        type="button"
                        class="w-full h-full appearance-none cursor-pointer rounded flex flex-col justify-center items-center text-center h1"
                        :class="{
                            'bg-red': currentSection.isWrongAnswer,
                            'bg-green': currentSection.isCorrectAnswer,
                            'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'text-purple bg-gray-200': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }"
                        @click.prevent="removeWord(word.id)">
                        {{ index + 1 }} -
                        <template v-if="getGameTraduction(word)">
                            {{ getGameTraduction(word).text }}
                        </template>
                        <template v-else>
                            {{ word.text }}
                        </template>
                    </button>
                    <template v-if="4 - currentWordIdsOrdered.length > 0">
                        <div
                            v-for="(_, index) in 4 - currentWordIdsOrdered.length"
                            :key="index"
                            class="w-full h-full bg-gray-150 rounded flex flex-col justify-center items-center text-center h1 text-white">
                            {{ index + 1 + currentWordIdsOrdered.length }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="w-full md:w-1/2 md:pl-3 pt-6 md:pt-0">
                <div class="grid grid-cols-1 grid-rows-4 gap-3 md:gap-6 h-full">
                    <button
                        v-for="answer in currentSection.answers"
                        :key="answer.id"
                        type="button"
                        class="w-full h-full appearance-none cursor-pointer rounded flex flex-col justify-center items-center text-center bg-white text-purple"
                        :class="{
                            'opacity-50': currentWordIdsOrdered.includes(answer.id)
                        }"
                        @click.prevent="toggleWord(answer.id)">
                        <span
                            class="h1"
                            v-if="getGameTraduction(answer)">
                            {{ getGameTraduction(answer).text }}
                        </span>
                        <span
                            class="h1"
                            v-else>
                            {{ answer.text }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full flex flex-row justify-center items-center space-x-6">
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center bg-white text-purple h3"
                @click.prevent="skip">
                {{ $t("game.skipQuestion") }}
            </button>
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center h3"
                :class="{
                    'bg-gray-400 text-gray-200': wordsLeft.length > 0,
                    'bg-green text-white': wordsLeft.length === 0
                }"
                @click.prevent="sendAnswer">
                {{ $t("game.validate") }}
            </button>
        </div>
    </div>
</template>
<script>
import { FLAGS } from "services/countryFlags"
import sortBy from "lodash-es/sortBy"
import { removeAccents } from "@globals/functions"

export default {
    name: "GameWordsOrder",
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            FLAGS,

            currentWordIdsOrdered: [],
            correctAnswersOrdered: []
        }
    },
    watch: {
        currentSection(nextValue, prevValue) {
            //reset to empty arr when we changed section
            if (nextValue && prevValue) {
                if (prevValue.sectionIdx !== nextValue.sectionIdx) {
                    this.currentWordIdsOrdered = []
                }
            }

            if (nextValue.words.length === prevValue.words.length && !nextValue.isReal) return

            this.currentWordIdsOrdered = []
            this.setCorrectAnswers()
        }
    },
    created() {
        this.setCorrectAnswers()
    },
    computed: {
        currentWordsOrdered() {
            if (!this.currentSection) {
                return []
            }
            return this.currentWordIdsOrdered.reduce((acc, id) => {
                let word = this.currentSection.words.find((w) => w.id === id)
                if (!word) return acc
                acc.push(word)
                return acc
            }, [])
        },
        wordsLeft() {
            if (!this.currentSection) {
                return []
            }
            return this.currentSection.words.reduce((acc, word) => {
                let isInAnswer = this.currentWordIdsOrdered.some((id) => id === word.id)
                if (isInAnswer) return acc
                acc.push(word)
                return acc
            }, [])
        }
    },
    methods: {
        setCorrectAnswers() {
            if (!this.currentSection) {
                this.correctAnswersOrdered = []
                return
            }
            this.correctAnswersOrdered = sortBy(this.currentSection.answers, (answer) => {
                let traduction = this.getGameTraduction(answer)
                return traduction ? removeAccents(traduction.text) : removeAccents(answer.text)
            })
        },
        removeWord(id) {
            //remove by index
            let idx = this.currentWordIdsOrdered.indexOf(id)
            this.currentWordIdsOrdered.splice(idx, 1)
        },
        toggleWord(id) {
            if (this.currentWordIdsOrdered.includes(id)) {
                this.removeWord(id)
            } else {
                this.addWord(id)
            }
        },
        addWord(id) {
            this.currentWordIdsOrdered.push(id)
        },
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        },
        sendAnswer() {
            if (!this.currentSection) return
            if (!this.currentSection.isReal) return
            if (this.wordsLeft.length > 0) return

            let wordsUuid = this.correctAnswersOrdered.map((a) => a.uuid)
            let answeredWordsUuid = this.currentWordsOrdered.map((w) => w.uuid)

            let wrongAnsweredWordUuid = wordsUuid.find((uuid, idx) => {
                return answeredWordsUuid[idx] !== uuid
            })

            //wrong answer
            if (wrongAnsweredWordUuid) {
                //wrong answer, grab uuid from wrong answer
                this.$emit("answer", {
                    wordUuid: wrongAnsweredWordUuid, //send correct uuid
                    answeredWordUuid: null //send null to receive wrong answer
                })
            } else {
                //correct answer, send all data
                this.$emit("answer", {
                    wordsUuid: wordsUuid, //send correct ordered
                    answeredWordsUuid: answeredWordsUuid //send user ordered
                })
            }
        },
        skip() {
            if (!this.currentSection) return
            if (!this.currentSection.isReal) return
            if (this.currentSection.words.length === 0) return

            //todo:Alex dont use first word since that skew the bad answer for that word...
            this.$emit("answer", {
                wordUuid: this.currentSection.words[0].uuid,
                answeredWordUuid: null,
                skipQuestion: true
            })
        }
    }
}
</script>
