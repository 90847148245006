<template>
    <StudentHeader
        class="block lg:hidden"
        experience-class="hidden">
        <h1>
            {{ $t("settings.title") }}
        </h1>
    </StudentHeader>
    <div class="py-6 w-full h-0 flex-1">
        <Card class="w-full h-full">
            <simplebar class="scroll-container">
                <div class="p-6">
                    <StudentHeader2 :student="student" />
                    <div class="border-t border-purple-10 mt-12 pt-12">
                        <form @submit.prevent="updateStudent">
                            <h2>
                                {{ $t("settings.title") }}
                            </h2>
                            <!--  <h3 class="pt-4">
                {{ $t('settings.shortcode') }}: {{ student.shortcode }}
              </h3> -->
                            <h3
                                class="pt-4"
                                v-if="student.teacherId">
                                {{ $t("settings.teacher") }}:
                                <span v-if="student.teacher.name">{{ student.teacher.name }}</span>
                                <span v-else-if="student.teacher.email">{{ student.teacher.email }}</span>
                            </h3>
                            <h3
                                class="pt-4"
                                v-if="student.parentId">
                                {{ $t("settings.parent") }}:
                                <span v-if="student.parent.name">{{ student.parent.name }}</span>
                                <span v-else-if="student.parent.email">{{ student.parent.email }}</span>
                            </h3>
                            <div class="mt-6">
                                <StudentForm
                                    version="edit"
                                    :read-only="!canUpdateHimself"
                                    :student="student"
                                    :errors="errors" />
                            </div>
                            <div
                                class="inline-block mt-6"
                                v-if="canUpdateHimself">
                                <CustomButton type="submit">
                                    {{ $t("form.btnSave") }}
                                </CustomButton>
                            </div>
                        </form>
                    </div>
                </div>
            </simplebar>
        </Card>
    </div>
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import StudentHeader2 from "components/globals/StudentHeader2"
import CustomButton from "components/globals/CustomButton"
import EventBus, { EVENTS } from "@/eventbus"
import StudentForm from "components/forms/StudentForm"
import Simplebar from "components/globals/Simplebar"
import StudentHeader from "components/globals/StudentHeader"
import cloneDeep from "lodash-es/cloneDeep"

export default {
    name: "Settings",
    components: { StudentHeader, Simplebar, StudentForm, CustomButton, StudentHeader2, Card },
    data() {
        return {
            student: {},
            errors: {}
        }
    },
    created() {
        this.student = cloneDeep(this.$store.state.auth.student)
    },
    computed: {
        canUpdateHimself() {
            //if no manager, can update himself
            return this.$store.state.auth.student.teacherId === null && this.$store.state.auth.student.parentId === null
        }
    },
    methods: {
        updateStudent() {
            if (!this.canUpdateHimself) return
            let errors = {}

            if (this.student.firstName === "") {
                errors.firstName = this.$t("validationErrors.firstName")
            }
            if (this.student.lastName === "") {
                errors.lastName = this.$t("validationErrors.lastName")
            }

            if (this.student.username === "") {
                errors.username = this.$t("validationErrors.username")
            }

            this.errors = errors

            if (Object.keys(errors).length > 0) {
                return
            }

            this.$store
                .dispatch(types.UPDATE_STUDENT, this.student)
                .then((student) => {
                    this.student = cloneDeep(student)
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        title: this.$t("flash.success"),
                        message: this.$t("flash.accountSaved"),
                        type: "success"
                    })
                })
                .catch((res) => {
                    this.errors = res.errors
                })
        }
    }
}
</script>
