const DEFAULT_LANG = "fr"

const LANGS = {
    FR: "fr",
    EN: "en",
    ES: "es",
    DE: "de",
    IT: "it",
    PT: "pt"
}

const AVAILABLE_LANGS = {
    FR: "fr"
    // EN: "en", //just fr for now
    //ES: "es" //just fr for now
}

const AVAILABLE_LEARNING_LANGS = {
    FR: "fr"
    // EN: "en",//just fr for now
    // ES: "es"//just fr for now
}

const LEARNING_LANGS = {
    FR: LANGS.FR, //same lang per default
    EN: LANGS.EN, //same lang per default
    ES: LANGS.ES, //same lang per default
    DE: LANGS.DE, //same lang per default
    IT: LANGS.IT, //same lang per default
    PT: LANGS.PT //same lang per default
}

const WORD_TYPES = {
    VERB: "verb",
    NOUN: "noun",
    PLURIAL_NOUN: "noun-plu",
    PLURIAL_MASCULINE_NOUN: "noun-m-plu",
    PLURIAL_FEMININE_NOUN: "noun-f-plu",
    MASCULINE_NOUN: "noun-m",
    FEMININE_NOUN: "noun-f",
    ADVERB: "adv",
    ADJECTIVE: "adj",
    PREPOSITION: "prep",
    DETERMINANT: "det",
    PRONOUN: "pron",
    CONJONCTION: "conj",
    LETTER: "letter",
    INVARIABLE: "invariable"
}


//make sure those plan are in braintree
const PLAN_TYPES = {
    PARENT_STANDARD: "PARENT_STANDARD",
    PARENT_PREMIUM: "PARENT_PREMIUM",
    TEACHER_STANDARD: "TEACHER_STANDARD",
    TEACHER_ON_DEMAND: "TEACHER_ON_DEMAND"
}


//use for aws, etc
const LANG_CODES = {
    FR: "fr-CA",
    EN: "en-US",
    ES: "es-ES",
    DE: "de-DE",
    IT: "it-IT",
    PT: "pt-PT"
}


//https://fakerjs.dev/api/localization.html
const FAKER_LANG_CODES = {
    FR: "fr_CA",
    EN: "en_CA",
    ES: "es",
    DE: "de",
    IT: "it",
    PT: "pt_PT"
}


//Débutant, Intermédiaire, Avancé
const DIFFICULTY = {
    EASY: 1,
    MEDIUM: 2,
    HARD: 3,
    VERY_HARD: 4
}


const GENDERS = {
    M: "m",
    F: "f",
    X: "x"
}

const GRADES = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6
}

const PRODUCT_CATEGORIES = {
    AVATAR: "avatar",
    BADGE: "badge",
    MAIN_BACKGROUND: "main-background",
    BACKGROUND: "background"
}


const GAME_TYPES = {
    // CARDS: "cards",
    // HEAR: "hear",
    // MATCH: "match",
    //TRANSLATE: "translate",

    GUESS: "guess",
    ORDER: "order",
    QUESTION: "question",
    WORDS_ORDER: "words-order",
    WRITE: "write",

    MISSING_LETTERS: "missing-letters",

    HANGMAN: "hangman",

    //live stuff
    BATTLE: "battle",
    CONTEST: "contest",

    LICK_3D_GAME: "lick-3d-game"
}


const USER_ROLES = {
    TEACHER: "teacher",
    PARENT: "parent",
    SCHOOL_MANAGER: "school-manager"
}


const USER_TYPES = {
    USER: "user",
    STUDENT: "student",
    ANONYMOUS: "anonymous"
}


const LEAGUE_TYPES = {
    PUBLIC: "public",
    PRIVATE: "private"
}


const WORD_GROUP_TYPES = {
    LIST: "list",
    CUSTOM: "custom" //created by a school for student, etc
}


const COUNTRIES = {
    CA: "Canada",
    US: "United States of America",
    FR: "France",
    MX: "Mexico",
    ES: "España"
}


const STAT_TYPES = {
    PRODUCT: "product",
    GAME_TYPE: "game-type"
}

const SOCKET_EVENTS = {
    NEW_NOTIFICATION: "NEW_NOTIFICATION",

    JOIN_LIVE_GAME: "JOIN_LIVE_GAME",
    START_LIVE_GAME: "START_LIVE_GAME",
    UPDATE_LIVE_GAME: "UPDATE_LIVE_GAME",

    USER_UPDATED_GAME: "USER_UPDATED_GAME",

    CLOSING_SOCKET: "CLOSING_SOCKET"
}


const NOTIFICATION_TYPES = {
    LEAGUE_REQUEST: "league-request",
    LEAGUE_INVITATION: "league-invitation",
    GAME_INVITATION: "game-invitation"
}


const PAYMENT_TYPES = {
    RENEW_LICENSE: "renew-license",
    LICENSE: "license",
    SUBSCRIPTION: "subscription"
}


const BULK_DOCUMENT_TYPES = {
    CLASSROOM: "classroom"
}


const FILTERED_CHOICES = {
    ALL: "all",
    MONTH: "month",
    YEAR: "year"
}


const LICENSE_TYPES = {
    PARENT: "parent",
    STUDENT: "student",
    TEACHER: "teacher"
}


const WORD_GROUP_CATEGORIES = {
    DUO: "duo",
    ZIG_ZAG: "zig-zag"
}


module.exports = {
    DEFAULT_LANG,
    LANGS,
    GENDERS,
    GRADES,
    AVAILABLE_LANGS,
    AVAILABLE_LEARNING_LANGS,
    LEARNING_LANGS,
    WORD_TYPES,
    LANG_CODES,
    FAKER_LANG_CODES,
    DIFFICULTY,
    PLAN_TYPES,
    PAYMENT_TYPES,
    PRODUCT_CATEGORIES,
    GAME_TYPES,
    USER_ROLES,
    USER_TYPES,
    LEAGUE_TYPES,
    WORD_GROUP_TYPES,
    COUNTRIES,
    STAT_TYPES,
    SOCKET_EVENTS,
    NOTIFICATION_TYPES,
    BULK_DOCUMENT_TYPES,
    FILTERED_CHOICES,
    LICENSE_TYPES,
    WORD_GROUP_CATEGORIES,
}
