<template>
    <div
        ref="joystick"
        class="joystick"></div>
</template>
<script>
import nipplejs from "nipplejs"
import { DIRECTIONS } from "services/constants"

export default {
    name: "Joystick",
    emits: ["move"],
    data() {
        return {
            direction: null
        }
    },
    mounted() {
        this.init()
    },
    beforeUnmount() {
        this.joystickInstance.destroy()
    },
    methods: {
        init() {
            this.joystickInstance = nipplejs.create({
                zone: this.$refs.joystick,
                color: "black",
                mode: "static",
                position: { bottom: "0px", right: "0px" }
            })
            this.joystickInstance.on("end", (e) => {
                if (this.direction === null) return
                this.direction = null
                this.$emit("move", {
                    direction: null
                })
            })
            this.joystickInstance.on("move", (e, nipple) => {
                let direction = this.getDirectionFromAngle(nipple.angle.degree)
                if (this.direction === direction) return
                this.direction = direction
                this.$emit("move", {
                    direction: direction
                })
            })
        },
        getDirectionFromAngle(angle) {
            //divisions of 360 degrees for 16 directions, but then map them to 8 directions so we got 8 directions possible
            const DIV = 22.5
            let count = Math.floor(angle / DIV)
            const DIRECTIONS_MAP = {
                0: DIRECTIONS.RIGHT,
                1: DIRECTIONS.RIGHT_UP,
                2: DIRECTIONS.RIGHT_UP,
                3: DIRECTIONS.UP,
                4: DIRECTIONS.UP,
                5: DIRECTIONS.LEFT_UP,
                6: DIRECTIONS.LEFT_UP,
                7: DIRECTIONS.LEFT,
                8: DIRECTIONS.LEFT,
                9: DIRECTIONS.LEFT_DOWN,
                10: DIRECTIONS.LEFT_DOWN,
                11: DIRECTIONS.DOWN,
                12: DIRECTIONS.DOWN,
                13: DIRECTIONS.RIGHT_DOWN,
                14: DIRECTIONS.RIGHT_DOWN,
                15: DIRECTIONS.RIGHT
            }
            return DIRECTIONS_MAP[count]
        }
    }
}
</script>
<style lang="scss">
.joystick {
    position: relative;
    .nipple {
    }
}
</style>
