const BAGS = [
    {
        id: 1,
        title: "Sac jaune",
        price: 0,
        limit: 4
    },
    {
        id: 2,
        title: "Sac rouge",
        price: 20,
        limit: 8
    },
    {
        id: 3,
        title: "Sac brun",
        price: 35,
        limit: 20
    }
]

const INITIAL_STUDENT_GAME_EXTRA_DATA = {
    currentBagId: 1,//bag from above
    allCaptureBugIds: [],
    captureBugIds: [],
    purchasedLevels: ["level0"],//start with level0
    lickLeft: 0,
    money: 0,
    level: "level0"
}


const BUGS = [
    {
        id: 1,
        levels: ["level0", "level1"],
        title: "Igor Vrombissant",
        price: 1,
        img: "/static/images/games/bugs/bug1.png?ver=1.0.0"
    },
    {
        id: 2,
        levels: ["level0", "level1"],
        title: "Mouche Spectroïde",
        price: 2,
        img: "/static/images/games/bugs/bug2.png?ver=1.0.0"
    },
    {
        id: 3,
        levels: ["level0", "level1"],
        title: "Fluzar Infernale",
        price: 3,
        img: "/static/images/games/bugs/bug3.png?ver=1.0.0"
    },
    {
        id: 4,
        levels: ["level0", "level1"],
        title: "Zzoth Hypnotique",
        price: 4,
        img: "/static/images/games/bugs/bug4.png?ver=1.0.0"
    },
    {
        id: 5,
        levels: ["level0", "level1"],
        title: "Mouche Luminescente",
        price: 8,
        img: "/static/images/games/bugs/bug5.png?ver=1.0.0"
    },
    {
        id: 6,
        levels: ["level1"],
        title: "Skylar Furtive",
        price: 10,
        img: "/static/images/games/bugs/bug6.png?ver=1.0.0"
    },
    {
        id: 7,
        levels: ["level1"],
        title: "Mouche Cyclonique",
        price: 10,
        img: "/static/images/games/bugs/bug7.png?ver=1.0.0"
    },
    {
        id: 8,
        levels: ["level1"],
        title: "Xybra Chimique",
        //chance: 0.05,//5%
        price: 20,
        img: "/static/images/games/bugs/bug8.png?ver=1.0.0"
    }
]

const ZONES = {
    SELL: "sell",
    QUESTION: "question",
    GO_BACK: "go_back",
    TRAIL: "trail",
    LICK: "lick",
    BAG: "bag",
    LEVEL_CHANGE: "level_change"
}

//same thing but simplier so zones is not long
const Z = {
    SE: ZONES.SELL,
    QU: ZONES.QUESTION,
    LI: ZONES.LICK,
    GB: ZONES.GO_BACK,
    TR: ZONES.TRAIL,
    BG: ZONES.BAG,
    LC: ZONES.LEVEL_CHANGE
}

const LEVELS = {
    level0: {
        code: "level0",
        title: "La forêt royale",
        price: 0,
        player: {
            height: parseInt(3778 / 2 / 9),//ratio of map height
            initialZone: {
                x: 8,
                y: 6
            }
        },
        specialZones: {
            "12-5": {
                type: ZONES.LEVEL_CHANGE,
                levelTo: "level1"
            },
            "12-6": {
                type: ZONES.LEVEL_CHANGE,
                levelTo: "level1"
            },
            "13-6": {
                type: ZONES.LEVEL_CHANGE,
                levelTo: "level1"
            },
            "26-4": {
                type: ZONES.BAG,
                bagId: 3
            },
            "26-5": {
                type: ZONES.BAG,
                bagId: 3
            },
            "26-7": {
                type: ZONES.BAG,
                bagId: 2
            },
            "26-8": {
                type: ZONES.BAG,
                bagId: 2
            },
            "26-10": {
                type: ZONES.BAG,
                bagId: 1
            },
            "26-11": {
                type: ZONES.BAG,
                bagId: 1
            }
        },
        zones: [
            //1     2     3     4    5     6     7     8     9     10     11    12    13    14    15    16    17    18    19    20    21    22    23    24   25    26    27    28    29    30     31    32
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 1
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 2
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 3
            [null, null, null, null, Z.SE, Z.SE, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null], // 4
            [null, null, null, null, Z.SE, Z.SE, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.BG, Z.TR, Z.TR, null, null, null], // 5
            [null, null, null, null, Z.TR, Z.TR, null, null, null, null, null, null, Z.LC, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.BG, Z.TR, Z.TR, null, null, null], // 6
            [null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.LC, Z.LC, null, null, null, Z.LI, Z.LI, Z.LI, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null], // 7
            [null, null, null, null, Z.LI, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, Z.TR, Z.TR, Z.LI, Z.LI, Z.LI, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.BG, Z.TR, Z.TR, null, null, null], // 8
            [null, null, null, Z.LI, Z.LI, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.BG, Z.TR, Z.TR, null, null, null], // 9
            [null, null, null, Z.LI, Z.LI, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null], // 10
            [null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.BG, Z.TR, Z.TR, null, null, null], // 11
            [null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.BG, Z.TR, Z.TR, null, null, null], // 12
            [null, null, Z.QU, Z.QU, Z.TR, Z.TR, Z.TR, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null], // 13
            [null, null, Z.QU, Z.QU, Z.QU, Z.TR, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null, null, null, null], // 14
            [null, null, Z.QU, Z.QU, Z.QU, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 15
            [null, null, Z.QU, Z.QU, Z.QU, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 16
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 17
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 18
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 19
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null] // 20
        ],
        bg: {
            width: parseInt(7747 / 2),
            height: parseInt(3778 / 2),
            layers: {
                overPlayer: null,
                underPlayer: "/static/images/games/level0@2x.png?ver=1.0.1"
            }
        }
    },
    level1: {
        code: "level1",
        title: "La forêt d'orée",
        price: 20,
        player: {
            height: parseInt(3778 / 2 / 9),//ratio of map height
            initialZone: {
                x: 10,
                y: 5
            }
        },
        specialZones: {
            "6-3": {
                type: ZONES.LEVEL_CHANGE,
                levelTo: "level0"
            },
            "6-4": {
                type: ZONES.LEVEL_CHANGE,
                levelTo: "level0"
            },
            "17-17": {
                type: ZONES.LEVEL_CHANGE,
                levelTo: "level0"
            },
            "17-18": {
                type: ZONES.LEVEL_CHANGE,
                levelTo: "level0"
            }
        },
        zones: [
            //1     2     3     4    5     6     7     8     9     10     11    12    13    14    15    16    17    18    19    20
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 1
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 2
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], // 3
            [null, null, null, null, null, null, Z.LC, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null, null, null, null, null, null, null], // 4
            [null, null, null, null, null, null, Z.LC, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null, null, null, null, null, null], // 5
            [null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null, null, Z.LI, Z.LI, null, null], // 6
            [null, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null, null, Z.TR, Z.TR, null, null], // 7
            [null, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, null, null, null, null, null, Z.TR, Z.TR, null, null], // 8
            [null, null, null, null, null, null, null, null, Z.TR, Z.TR, null, null, null, null, null, null, Z.TR, Z.TR, null, null], // 9
            [null, null, null, null, null, null, null, null, Z.TR, Z.TR, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null], // 10
            [null, null, null, null, Z.LI, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null], // 11
            [null, null, null, null, Z.LI, Z.TR, Z.TR, Z.TR, null, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, Z.TR, null, null, null], // 12
            [null, null, null, null, Z.LI, Z.TR, Z.TR, Z.TR, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, null, null], // 13
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.TR, Z.TR, null, null], // 14
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.TR, null, null], // 15
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.TR, null, null], // 16
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.TR, null, null], // 17
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.LC, null, null], // 18
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, Z.TR, Z.LC, null, null], // 19
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null] // 20
        ],
        bg: {
            width: parseInt(3877 / 2),
            height: parseInt(3778 / 2),

            layers: {
                overPlayer: "/static/images/games/level1_over@2x.png?ver=1.0.1",
                underPlayer: "/static/images/games/level1_under@2x.png?ver=1.0.1"
            }
        }
    }
}


module.exports = {
    INITIAL_STUDENT_GAME_EXTRA_DATA,
    BUGS,
    ZONES,
    LEVELS,
    BAGS
}
