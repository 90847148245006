<template>
    <Card :id="`product-${product.id}`">
        <div class="p-2 h-full flex flex-col justify-start items-start">
            <div
                class="rounded bg-gray-100 h-32 overflow-hidden flex flex-row justify-center items-center w-full"
                v-if="productCategory">
                <img
                    :alt="product.title"
                    class="w-full h-full object-contain object-center p-2"
                    loading="lazy"
                    v-if="productCategory.slug === PRODUCT_CATEGORIES.AVATAR"
                    :src="product.imageUrl" />

                <img
                    :alt="product.title"
                    class="w-full h-full object-contain object-center"
                    loading="lazy"
                    v-if="productCategory.slug === PRODUCT_CATEGORIES.MAIN_BACKGROUND"
                    :src="mainBackgroundImg" />

                <div
                    v-else-if="productCategory.slug === PRODUCT_CATEGORIES.BADGE"
                    class="w-full h-full p-2">
                    <ProductSvgShape
                        version="product"
                        class="w-full h-full"
                        :code="product.code" />
                </div>

                <div
                    class="w-full h-full"
                    v-else-if="productCategory.slug === PRODUCT_CATEGORIES.BACKGROUND"
                    :style="`background:${product.color}`" />
            </div>
            <div class="px-2 pb-2 flex flex-col justify-between items-start w-full flex-1">
                <h3 class="pt-6">{{ capitalize(title) }}</h3>
                <div class="w-full">
                    <div class="flex flex-col justify-start items-start space-y-3 my-6">
                        <PointTag
                            type="A"
                            version="simple"
                            :value="product.pricePointsA" />
                    </div>
                    <div
                        v-if="alreadyBrought"
                        class="">
                        <template v-if="canEquip">
                            <CustomButton
                                disabled
                                v-if="currentlyInUse"
                                class="w-full">
                                {{ $t("shop.productCurrentlyInUse") }}
                            </CustomButton>
                            <CustomButton
                                v-else
                                @click.prevent="$emit('use', product.uuid)"
                                class="w-full">
                                {{ $t("shop.useProduct") }}
                            </CustomButton>
                        </template>
                        <template v-else>
                            <CustomButton
                                disabled
                                class="w-full">
                                {{ $t("shop.productAlreadyBrought") }}
                            </CustomButton>
                        </template>
                    </div>
                    <div v-else>
                        <CustomButton
                            v-if="isLocked"
                            disabled
                            class="w-full">
                            {{ $t("shop.unlockProductAt", { level: product.unlockAtLevel }) }}
                        </CustomButton>
                        <CustomButton
                            v-else-if="!canBuy"
                            disabled
                            class="w-full">
                            {{ $t("shop.notEnoughToBuyProduct") }}
                        </CustomButton>
                        <CustomButton
                            v-else
                            @click.prevent="$emit('buy', product.uuid)"
                            class="w-full">
                            {{ $t("shop.buyProduct") }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "components/cards/Card"
import PointTag from "components/globals/PointTag"
import CustomButton from "components/globals/CustomButton"
import { PRODUCT_CATEGORIES } from "@globals/constants"

import mainBg01 from "assets/products/backgrounds/bg-desktop-01.jpg"
/*import mainBg02 from "assets/products/backgrounds/bg-desktop-02.jpg"
import mainBg03 from "assets/products/backgrounds/bg-desktop-03.jpg"
import mainBg04 from "assets/products/backgrounds/bg-desktop-04.jpg"
import mainBg05 from "assets/products/backgrounds/bg-desktop-05.jpg" */
import ProductSvgShape from "components/globals/ProductSvgShape"

export default {
    name: "ProductCard",
    components: { ProductSvgShape, CustomButton, PointTag, Card },
    emits: ["buy", "use"],
    props: {
        product: {
            required: true,
            type: Object
        },
        canEquip: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            PRODUCT_CATEGORIES
        }
    },
    computed: {
        productCategory() {
            return this.$store.state.studentData.global.productCategories.find(
                (pc) => pc.id === this.product.productCategoryId
            )
        },
        mainBackgroundImg() {
            if (this.productCategory.slug !== PRODUCT_CATEGORIES.MAIN_BACKGROUND) return

            switch (this.product.code) {
                case "01":
                    return mainBg01
                /*    case "02":
                    return mainBg02
                case "03":
                    return mainBg03
                case "04":
                    return mainBg04
                case "05":
                    return mainBg05 */
                default:
                    return mainBg01
            }
            return null
        },
        traduction() {
            if (!this.product) return null
            return this.$store.getters.getStudentTraduction(this.product)
        },
        title() {
            if (this.traduction) {
                if (this.traduction.text) {
                    return this.traduction.text
                }
            }
            return this.product.text
        },
        canBuy() {
            return this.$store.state.auth.student.pointsA >= this.product.pricePointsA
        },
        studentExperience() {
            return this.$store.getters.getStudentExperience(this.$store.state.auth.student)
        },
        isLocked() {
            if (!this.studentExperience) return true
            let studentLevel = this.studentExperience.level
            return this.product.unlockAtLevel > studentLevel
        },
        currentlyInUse() {
            if (!this.productCategory) return false
            switch (this.productCategory.slug) {
                case PRODUCT_CATEGORIES.BADGE:
                    return this.$store.state.auth.student.badgeId === this.product.id
                case PRODUCT_CATEGORIES.AVATAR:
                    return this.$store.state.auth.student.avatarId === this.product.id
                case PRODUCT_CATEGORIES.MAIN_BACKGROUND:
                    return this.$store.state.auth.student.mainBackgroundId === this.product.id
                case PRODUCT_CATEGORIES.BACKGROUND:
                    return this.$store.state.auth.student.backgroundId === this.product.id
            }
            return false
        },
        alreadyBrought() {
            return this.$store.state.studentData.boughtProducts.some((p) => p.id === this.product.id)
        }
    }
}
</script>
