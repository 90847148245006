const { PLAN_TYPES } = require("../constants")



const parentPlans = [
    {
        title: "Forfait Découverte",
        subtitle: "Pour parents et enfants",
        color: "yellow-400",
        icon: "discover",
        handle: PLAN_TYPES.PARENT_STANDARD,
        list: [
            "2 Enfants",
            "Tous les jeux disponibles en accès illimité",
            "Création de 2 communautés"
        ],
        notYetList:[
            "Accès illimité aux produits du magasin",
        ],
        price: "9.99$",
    },
    {
        title: "Forfait Progression",
        subtitle: "Pour parents et enfants",
        color: "blue-400",
        icon: "progress",
        handle: PLAN_TYPES.PARENT_PREMIUM,
        list: [
            "5 Enfants",
            "Tous les jeux disponibles en accès illimité",
            "Création illimité de communautés",
            "Accès illimité aux produits du magasin"
        ],
        price: "15.99$",
    }
]

const teacherPlans = [
    {
        title: "Forfait Professeur",
        subtitle:"Pour les professeurs et les classes",
        color:"red-500",
        icon:"prof",
        handle: PLAN_TYPES.TEACHER_STANDARD,
        list: [
            "28 Étudiants",
            "Tous les jeux disponibles en accès illimité",
            "Création de 5 communautés",
            "Accès illimité aux produits du magasin",
        ],
        price: "49.99$"
    },
    {
        title: "Forfait sur demande",
        subtitle:"Pour les professeurs et les classes",
        color:"yellow-400",
        icon:"demand",
        handle: PLAN_TYPES.TEACHER_ON_DEMAND,
        list: [
            "Nombre d'étudiants illimité",
            "Tous les jeux disponibles en accès illimité",
            "Création illimité de communautés",
            "Accès illimité aux produits du magasin",
            "Dictée généré par l'IA"
        ],
        notYetList:[
            "Exercises généré par l'IA (à venir)"
        ],
        price: "89.99$"
    }
]


module.exports = {
    teacherPlans,
    parentPlans
}
