<template>
    <div class="h-full flex flex-col justify-between items-center space-y-6">
        <div
            class="w-full h-full md:h-1/2 flex flex-row flex-wrap md:flex-nowrap justify-between items-center md:space-x-6 p-2 md:p-0 rounded bg-white md:bg-transparent">
            <div
                class="w-full md:w-1/2 h-1/2 md:h-full p-3 rounded bg-white flex flex-col justify-center items-center relative">
                <h3 class="pt-3">{{ $t("game.tryLeft", { tryLeft: 6 - wrongGuessCount }) }}</h3>
                <div class="relative w-full flex-1">
                    <div class="w-full h-full absolute inset-0 flex flex-col justify-center items-center">
                        <img
                            v-show="wrongGuessCount === 0"
                            :src="pendu1Img"
                            class="object-contain object-center w-full h-full block" />
                        <img
                            v-show="wrongGuessCount === 1"
                            :src="pendu2Img"
                            class="object-contain object-center w-full h-full block" />
                        <img
                            v-show="wrongGuessCount === 2"
                            :src="pendu3Img"
                            class="object-contain object-center w-full h-full block" />
                        <img
                            v-show="wrongGuessCount === 3"
                            :src="pendu4Img"
                            class="object-contain object-center w-full h-full block" />
                        <img
                            v-show="wrongGuessCount === 4"
                            :src="pendu5Img"
                            class="object-contain object-center w-full h-full block" />
                        <img
                            v-show="wrongGuessCount >= 5"
                            :src="pendu6Img"
                            class="object-contain object-center w-full h-full block" />
                    </div>
                </div>
            </div>

            <div
                v-if="currentSection"
                class="w-full md:w-1/2 h-1/2 md:h-full rounded"
                :class="{
                    'bg-red': currentSection.isWrongAnswer,
                    'bg-green': currentSection.isCorrectAnswer,
                    'bg-white': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                }">
                <div class="w-full h-full flex flex-col justify-center items-center">
                    <h3
                        class="mb-6"
                        :class="{
                            'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }">
                        {{ $t("game.wordOfXLetters", { lettersCount: gameTraduction.text.length }) }}&nbsp;/&nbsp;
                        <span
                            v-for="(type, idx) in word.type"
                            :key="type">
                            {{ idx > 0 ? ", " : "" }}{{ $t(`global.wordTypes.${type}`) }}
                        </span>
                    </h3>
                    <h1 class="h-12 tracking-widest flex flex-row justify-center items-center">
                        <span
                            v-for="(letter, index) in wordLetters"
                            :key="index"
                            class=""
                            :class="{
                                'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                                'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                            }">
                            <template v-if="lettersGuessed.includes(letter)">
                                {{ letter }}
                            </template>
                            <span
                                v-else
                                class="black block bg-gray-150 h-6 md:h-10 w-6 md:w-10 mr-2 mb-2 rounded" />
                        </span>
                    </h1>
                    <div
                        class="w-3/4 mx-auto border-dashed border-t-2 h-2"
                        :class="{
                            'border-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'border-black': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }"></div>
                </div>
            </div>
        </div>
        <div class="w-full h-1/2">
            <VirtualKeyboard
                accept-real-keys
                class="w-full h-full"
                @on-key-press="onKeyboardPress"
                hide-remove
                :disabled-letters="lettersGuessed"
                ref="keyboard" />
        </div>
    </div>
</template>
<script>
import { FLAGS } from "services/countryFlags"
import VirtualKeyboard from "components/globals/VirtualKeyboard.vue"

import pendu1Img from "assets/images/pendu/pendu1.png"
import pendu2Img from "assets/images/pendu/pendu2.png"
import pendu3Img from "assets/images/pendu/pendu3.png"
import pendu4Img from "assets/images/pendu/pendu4.png"
import pendu5Img from "assets/images/pendu/pendu5.png"
import pendu6Img from "assets/images/pendu/pendu6.png"

//todo
export default {
    name: "GameHangman",
    components: {  VirtualKeyboard },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            FLAGS,
            pendu1Img,
            pendu2Img,
            pendu3Img,
            pendu4Img,
            pendu5Img,
            pendu6Img,

            lettersGuessed: [],
            wrongGuessCount: 0,
            goodGuessCount: 0
        }
    },
    watch: {
        currentSection(nextValue, prevValue) {
            //reset to empty arr when we changed section
            if (nextValue && prevValue) {
                if (prevValue.sectionIdx !== nextValue.sectionIdx) {
                    this.wrongGuessCount = 0
                    this.goodGuessCount = 0
                    this.lettersGuessed = []
                }
            }

            if (nextValue.words.length === prevValue.words.length && !nextValue.isReal) return

            this.wrongGuessCount = 0
            this.goodGuessCount = 0
            this.lettersGuessed = []
        }
    },
    computed: {
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        gameTraduction() {
            if (!this.word || !this.game) return null
            return this.word.traductions.find((t) => t.lang === this.game.lang) || null
        },
        wordLetters() {
            if (!this.gameTraduction) return []
            return this.gameTraduction.text.split("")
        },
        lettersNotFound() {
            return this.wordLetters.reduce((acc, letter) => {
                if (this.lettersGuessed.includes(letter)) {
                    return acc
                }
                acc.push(letter)
                return acc
            }, [])
        }
    },
    methods: {
        async onKeyboardPress(key) {
            //block click when not real
            if (!this.currentSection.isReal) return

            let letterIsInWord = this.gameTraduction.text.toLowerCase().includes(key)
            if (!letterIsInWord) {
                this.wrongGuessCount++
                this.wrongAnswer()
            } else {
                this.goodGuessCount++
                this.correctAnswer()
            }
            this.lettersGuessed.push(key)

            await this.$nextTick()

            //is correct answer
            if (this.lettersNotFound.length === 0) {
                this.sendAnswer(true)
                return
            }

            //has pass max count
            if (this.wrongGuessCount === 6) {
                this.sendAnswer(false)
                this.lettersGuessed = this.wordLetters //so we can see all letters
                return
            }
        },
        wrongAnswer() {},
        correctAnswer() {},
        sendAnswer(isCorrectAnswer) {
            if (!this.currentSection.isReal) return
            if (!this.gameTraduction) return

            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: isCorrectAnswer ? this.word.uuid : null //pass null for wrong answer
            })
        }
    }
}
</script>
