export default (app) => {
    app.directive("uppercase", {
        updated(el) {
            el.value = el.value.toUpperCase()
        }
    })

    app.directive("lowercase", {
        updated(el) {
            el.value = el.value.toLowerCase()
        }
    })

    app.directive("no-space", {
        updated(el) {
            el.value = el.value.replace(/ /g, "")
        }
    })
}
