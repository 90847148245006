<template>
    <div class="w-full relative h-6">
        <div
            class="w-full h-px top-1/2 -translate-y-1/2 absolute left-0 right-0"
            :class="{
                'bg-white': version === 'white',
                'bg-purple': version === 'color'
            }" />
        <div class="flex flex-row justify-between items-center w-full h-full absolute inset-0">
            <div
                v-for="(section, idx) in gameData.sections.length + 1"
                :key="idx"
                class="h-2 xs:h-4 min-w-2 xs:min-w-4 w-2 xs:w-4 rounded"
                :class="{
                    'bg-white': version === 'white',
                    'bg-purple': version === 'color'
                }" />
        </div>
        <div
            ref="studentAvatars"
            v-for="studentGame in allStudentGames"
            :key="studentGame.student.id"
            :data-student-uuid="studentGame.student.uuid"
            class="absolute top-1/2"
            :class="{
                'z-1': currentStudentId === studentGame.student.id
            }">
            <StudentAvatar
                with-popper
                :student="studentGame.student"
                :size="currentStudentId === studentGame.student.id ? 'medium' : 'small'" />
        </div>
    </div>
</template>

<script>
import StudentAvatar from "components/globals/StudentAvatar"
import gsap from "gsap"
import throttle from "lodash-es/throttle"

export default {
    name: "LiveBattleRace",
    components: { StudentAvatar },
    props: {
        gameData: Object,
        version: {
            type: String,
            default: "white",
            oneOf: ["white", "color"]
        }
    },
    watch: {
        gameData: {
            immediate: true,
            handler: function() {
                this.setAvatar()
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize, { passive: true })

        //init like that so gsap keeps the position
        this.$refs.studentAvatars.forEach((_node) => {
            gsap.set(_node, { y: "-50%" })
        })

        this.setAvatar()
    },
    beforeUnmount() {
        window.addEventListener("resize", this.onResize)
    },
    computed: {
        allStudentGames() {
            return [].concat(this.gameData.studentGames).concat(this.gameData.hasLeftStudentGames)
        },
        currentStudentId() {
            if (!this.$store.getters.isStudentLogin) {
                return null
            }

            return this.$store.state.auth.student.id
        }
    },
    methods: {
        onResize: throttle(function() {
            this.setAvatar()
        }, 250, {
            leading: true,
            trailing: true
        }),
        setAvatar() {
            if (!this.$el) return
            if (!this.$refs.studentAvatars) return
            let totalWidth = this.$el.offsetWidth
            this.$refs.studentAvatars.forEach((_node) => {
                //use uuid so its string based. since fake student use string as id
                const studentUuidWanted = _node.dataset.studentUuid
                let data = this.allStudentGames.find(sG => sG.student.uuid === studentUuidWanted)

                let halfWidth = _node.offsetWidth / 2

                if (!data) {
                    //go back to start if no data
                    gsap.to(_node, 0.3, { x: 0 })
                    return
                }

                let progress = data.sectionsDoneCount / data.sectionsCount

                //start
                if (progress === 0) {
                    gsap.to(_node, 0.3, { x: "100%" })
                    return
                }

                //finish
                if (progress === 1) {
                    gsap.to(_node, 0.3, { opacity: 0, x: totalWidth })
                    return
                }
                
                //so we are in the middle of the dots
                let left = (totalWidth * progress) - halfWidth
                gsap.to(_node, 0.3, { x: left })
            })
        }
    }
}
</script>
