<template>
    <div
        v-if="isLoading"
        class="py-6">
        <Loader />
    </div>

    <simplebar
        v-else
        class="scroll-container -no-scroll">
        <div class="flex flex-col justify-start items-start w-full h-full max-h-full">
            <div class="mb-6 w-full">
                <ListGameHeader
                    @end="leaveGame"
                    @restart="restart"
                    @goBack="onGoBack"
                    :timer="timer"
                    :game-type="gameType"
                    :word-group="wordGroup"
                    :game-data="gameData" />
            </div>
            <div class="w-full flex-1">
                <StudentGameResult
                    v-if="gameData.isFinish"
                    :student-game-data="studentGameData"
                    :game-data="gameData"
                    :game="game"
                    @end="leaveGame"
                    @restart="restart" />

                <GameOrder
                    v-else-if="gameTypeSlug === GAME_TYPES.ORDER"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />

                <GameWordsOrder
                    v-else-if="gameTypeSlug === GAME_TYPES.WORDS_ORDER"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />

                <GameWrite
                    v-else-if="gameTypeSlug === GAME_TYPES.WRITE"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />

                <GameMissingLetters
                    v-else-if="gameTypeSlug === GAME_TYPES.MISSING_LETTERS"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />

                <GameHangman
                    v-else-if="gameTypeSlug === GAME_TYPES.HANGMAN"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />

                <Game3D
                    v-else-if="gameTypeSlug === GAME_TYPES.LICK_3D_GAME"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    :student-game-data="studentGameData"
                    @onUpdatedGame="onUpdatedGame"
                    @answer="sendAnswer" />

                <!--  todo:Adimo   <GameCards
                    v-else-if="gameTypeSlug === GAME_TYPES.CARDS"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />
                <GameHear
                    v-else-if="gameTypeSlug === GAME_TYPES.HEAR"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />
                <GameMatchDnD
                    v-else-if="gameTypeSlug === GAME_TYPES.MATCH"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" />
                <GameTranslate
                    v-else-if="gameTypeSlug === GAME_TYPES.TRANSLATE"
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="sendAnswer" /> -->
            </div>
            <div
                class="mt-6 w-full"
                v-if="showGameFooter">
                <GameFooter
                    :game-type="gameType"
                    :student-game-data="studentGameData"
                    :timer="timer" />
            </div>
        </div>
    </simplebar>
    <ConfirmQuittingGameModal
        v-if="showQuittingModal"
        @exit="leaveGame"
        @stay="showQuittingModal = false"
        @close="showQuittingModal = false" />
</template>

<script>
import * as types from "store/types"
import Loader from "components/Loader"
import ajax from "config/ajax"
import EventBus, { EVENTS } from "@/eventbus"
import ListGameHeader from "components/game/student/list/ListGameHeader"
import GameFooter from "components/game/GameFooter"
import { studentGameMixin } from "mixins/game"
import ConfirmQuittingGameModal from "components/modals/student/ConfirmQuittingGameModal"
import Simplebar from "components/globals/Simplebar"
import StudentGameResult from "components/game/student/global/StudentGameResult"
import GameOrder from "components/game/student/types/GameOrder.vue"
import GameWordsOrder from "components/game/student/types/GameWordsOrder.vue"
import GameWrite from "components/game/student/types/GameWrite.vue"
import GameMissingLetters from "components/game/student/types/GameMissingLetters.vue"
import GameHangman from "components/game/student/types/GameHangman.vue"
import Game3D from "components/game/student/types/Game3D.vue"
import { GAME_TYPES } from "@globals/constants"
import get from "lodash-es/get"
import { tryAsyncFunc } from "@globals/helpers/async"

export default {
    name: "ListGame",
    components: {
        Game3D,
        GameHangman,
        GameMissingLetters,
        GameWrite,
        GameWordsOrder,
        GameOrder,
        StudentGameResult,
        Simplebar,
        ConfirmQuittingGameModal,
        GameFooter,
        ListGameHeader,
        Loader
    },
    mixins: [studentGameMixin],
    async created() {
        this.$store.commit(types.STUDENT_DATA.STUDENT_PLAYED_GAME)

        await this.init() //from mixin
        await this.initGame() //init game
    },
    computed: {
        showGameFooter() {
            if (this.gameTypeSlug === GAME_TYPES.LICK_3D_GAME) return false
            return !this.gameData.isFinish
        }
    },
    methods: {
        async initGame() {
            //check if game already exist
            //last app didn't do that so dont do it right now
            this.wordGroup =
                this.$store.state.studentData.wordGroups.find((wG) => wG.uuid === this.$route.params.uuid) || null
            if (!this.wordGroup) {
                this.wordGroup = await this.$store.dispatch(
                    types.STUDENT_DATA.GET_WORD_GROUP_BY_UUID,
                    this.$route.params.uuid
                )
            }

            await this.startGame() //auto start

            this.isLoading = false
        },
        async startGame() {
            let options = {}
            if (this.gameTypeSlug === GAME_TYPES.LICK_3D_GAME) {
                options.min = get(this.$route.query, "min", undefined)
                options.type = get(this.$route.query, "type", undefined)
                options.money = get(this.$route.query, "money", undefined)

                //remove query
                this.$router.replace({ path: this.$route.path, query: undefined })
            }
            let [res, err] = await tryAsyncFunc(ajax.post(`/student/list-games`, {
                wordGroupUuid: this.wordGroup.uuid,
                gameTypeSlug: this.gameTypeSlug,
                options: options
            }))

            if (err) {
                this.$router.push(`/student/list/${this.wordGroup.uuid}`)
                EventBus.emit(EVENTS.SHOW_FLASH, {
                    title: this.$t("flash.error"),
                    message: this.$t("flash.errorOccurred"),
                    type: "error"
                })
                return
            }

            this.game = res.game
            this.gameData = res.gameData

            //wait for computed
            await this.$nextTick()

            let studentGameData =
                this.gameData.studentGames.find((sG) => sG.student && sG.student.id === this.$store.state.auth.student.id) ||
                null

            if (!studentGameData) {
                this.$router.push(`/student/list/${this.wordGroup.uuid}`)
                EventBus.emit(EVENTS.SHOW_FLASH, {
                    title: this.$t("flash.error"),
                    message: this.$t("flash.errorOccurred"),
                    type: "error"
                })
                return
            }

            let currentSection = studentGameData.sections[0]

            //init
            this.currentSection = {
                ...currentSection,
                isReal: true,
                wasGoodAnswer: false,
                wasWrongAnswer: false
            }

            this.startTimer()
            this.startTotalTimer()

            //start local time for list game, since server wont send socket event
            if (this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME && this.gameData.extraData.type === "time") {
                const delayMs = this.gameData.extraData.min * 60000 + (60 * 3)//time + 3 seconds so server get to process the finish game request
                setTimeout(() => {
                    this.updateGame()
                }, delayMs)
            }
        },
        async updateGame() {
            const [res, err] = await tryAsyncFunc(ajax.get(`/student/list-games/${this.game.uuid}`))
            if (err) {
                return
            }

            this.onUpdatedGame({
                gameData: res.gameData,
                game: res.game
            })
        },
        onUpdatedGame({ gameData, game }) {
            this.game = game
            this.gameData = gameData
        }
    }
}
</script>
