<template>
    <Card
        class="w-full"
        v-if="version === VERSIONS.DEFAULT">
        <div :class="containerClass">
            <div :class="`xs:flex flex-row flex-wrap justify-start items-center md:space-x-3 ${size === SIZES.SMALL ? 'p-1.5' : 'p-3'}`">
                <button
                    v-for="item in options"
                    type="button"
                    :key="item.value"
                    @click.prevent="$emit('click', item[valueKey])"
                    class="rounded font-medium transition duration-75"
                    :class="`${item[valueKey] === value ? 'bg-gray-100 text-purple' : 'text-gray-200'} ${size === SIZES.SMALL ? 'p-2 text-14' : 'py-2 px-3 text-16'}`">
                    {{ item[labelKey] }}
                </button>
            </div>
            <slot></slot>
        </div>
    </Card>
    <div
        class="w-full"
        v-if="version === VERSIONS.SIMPLE">
        <div :class="containerClass">
            <div class="xs:flex flex-row flex-wrap justify-start items-center md:space-x-3">
                <button
                    v-for="item in options"
                    type="button"
                    :key="item.value"
                    @click.prevent="$emit('click', item[valueKey])"
                    class="rounded font-medium transition duration-75"
                    :class="`${item[valueKey] === value ? 'bg-gray-100 text-purple' : 'text-gray-200'} ${size === SIZES.SMALL ? 'p-2 text-14' : 'py-2 px-3 text-16'}\``">
                    {{ item[labelKey] }}
                </button>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Card from "components/cards/Card"

const VERSIONS = {
    DEFAULT: "default",
    SIMPLE: "simple"
}

const SIZES = {
    DEFAULT: "default",
    SMALL: "small"
}

export default {
    name: "TabbedButtons",
    components: { Card },
    emits: ["click"],
    props: {
        value: [String, Number],
        size: {
            type: String,
            default: SIZES.DEFAULT
        },
        version: {
            type: String,
            default: VERSIONS.DEFAULT
        },
        containerClass: {
            type: String,
            default: ""
        },
        options: {
            default: () => [],
            type: Array
        },
        valueKey: {
            type: String,
            default: "value"
        },
        labelKey: {
            type: [String, Function],
            default: "label"
        }
    },
    data() {
        return {
            VERSIONS,
            SIZES
        }
    }
}
</script>
