<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="mb-6">
            {{ $t("form.editLeague") }}
        </h2>
        <form @submit.prevent="submit">
            <LeagueForm
                ref="leagueForm"
                :errors="errors"
                :league="league" />
            <div class="flex flex-row justify-between items-center gap-2 mt-12">
                <div>
                    <CustomButton version="text" type="button" @click="onDelete" v-if="isManager">
                        {{ $t("form.btnDelete") }}
                    </CustomButton>
                </div>
                <CustomButton type="submit">
                    {{ $t("form.btnEdit") }}
                </CustomButton>
            </div>
        </form>

        <DeleteItemModal
            :item-title="league.name"
            :is-loading="false"
            v-if="showDeleteModal"
            @delete="onDeleteConfirm"
            @close="showDeleteModal = false"
        />
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import LeagueForm from "components/forms/LeagueForm"
import DeleteItemModal from "components/modals/user/DeleteItemModal.vue"

export default {
    components: { DeleteItemModal, LeagueForm, CustomButton, Modal },
    emits: ["close"],
    name: "UpdateLeagueModal",
    props: {
        league: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showDeleteModal: false,

            errors: {}
        }
    },
    computed: {
        isManager() {
            return this.league.studentManagerId === this.$store.state.auth.student.id
        }
    },
    methods: {
        onDelete() {
            this.showDeleteModal = true
        },
        onDeleteConfirm() {
            this.showDeleteModal = false
            this.$store.dispatch(types.STUDENT_DATA.DELETE_LEAGUE, this.league)
            this.$emit("close")
        },
        submit() {
            this.errors = this.$refs.leagueForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store.dispatch(types.STUDENT_DATA.UPDATE_LEAGUE, this.league)
            this.$emit("close")
        }
    }
}
</script>
