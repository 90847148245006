<template>
    <form
        @submit.prevent="login"
        class="w-full">
        <div class="c__input">
            <label>
                {{ $t("form.usernameOrEmail") }}
            </label>
            <input
                required
                type="text"
                name="username"
                v-model="username" />
            <p
                class="error"
                v-if="errors.username">
                {{ errors.username }}
            </p>
        </div>

        <div class="c__input">
            <label>
                {{ $t("form.password") }}
            </label>
            <input
                type="password"
                name="password"
                v-model="password" />
            <p
                class="error"
                v-if="errors.password">
                {{ errors.password }}
            </p>
        </div>

        <div class="flex flex-row justify-center items-center mt-6">
            <CustomButton type="submit">
                {{ $t("auth.btnLogin") }}
            </CustomButton>
        </div>
    </form>
</template>

<script>
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "LoginAnonymousForm",
    components: { CustomButton },
    data() {
        return {
            username: "",
            password: "",
            errors: {}
        }
    },
    created() {},
    methods: {
        login() {
            this.errors = {}
            this.$store
                .dispatch(types.LOGIN, {
                    username: this.username,
                    password: this.password
                })
                .then((res) => {
                    this.$router.push("/")
                })
                .catch((err) => {
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
