<template>
    <modal
        :show="true"
        @close="$emit('close')"
        size="big"
        :scrollable="false">
        <div class="flex flex-col justify-start items-start max-h-full h-full">
            <!-- Dont check for externalCode since we can't edit word group with only external code since it's created by admin -->
            <h2 class="mb-6">{{ $t("form.edit") }} {{ wordGroup.text }} <span v-if="wordGroup.shortcode">(#{{wordGroup.shortcode}})</span></h2>
            <div class="form w-full max-h-full h-full flex flex-col justify-start items-start overflow-y-hidden">
                <WordGroupForm
                    ref="wordGroupForm"
                    :word-group="cloneWordGroup"
                    :errors="errors" />

                <div class="flex flex-row justify-center items-center my-4 w-full">
                    <CustomButton
                        type="button"
                        @click.prevent="editWordGroup">
                        {{ $t("form.btnEdit") }}
                    </CustomButton>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import WordGroupForm from "components/forms/WordGroupForm"
import * as types from "store/types"
import cloneDeep from "lodash-es/cloneDeep"

export default {
    name: "EditWordGroupModal",
    emits: ["close"],
    components: { WordGroupForm, CustomButton, Modal },

    props: {
        wordGroup: Object
    },
    data() {
        return {
            cloneWordGroup: cloneDeep(this.wordGroup),
            errors: {}
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_WORDS)
        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)
    },
    methods: {
        editWordGroup() {
            this.errors = this.$refs.wordGroupForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store.dispatch(types.USER_DATA.EDIT_WORD_GROUP, this.cloneWordGroup)
            this.$emit("close")
        }
    }
}
</script>
