<template>
    <div
        class="simplebar-component"
        :class="`${isSafari ? '-is-safari' : ''}`">
        <div
            ref="content"
            class="h-full w-full">
            <slot />
        </div>
    </div>
</template>
<script>
import SimpleBar from "simplebar"
import "simplebar/dist/simplebar.css"

export default {
    name: "simplebar",
    computed: {
        isSafari() {
            return typeof window.safari !== "undefined"
        }
    },
    mounted() {
        this.setSimpleBar()
    },
    beforeUnmount() {
        this.removeSimpleBar()
    },
    methods: {
        setSimpleBar() {
            //dont init on mobile + safari (ios, ipad) so we use native scrolling
            if (this.isSafari && window.innerWidth < 1024) {
                return
            }

            const options = SimpleBar.getOptions(this.$el.attributes)
            this.simpleBar = new SimpleBar(this.$el, options)

            //make sure we recalculate when init
            setTimeout(() => {
                this.simpleBar.recalculate()
            }, 200)

            if (typeof window.ResizeObserver !== "undefined") {
                this.resizeObserver = new ResizeObserver(() => {
                    if (!this.simpleBar) return
                    this.$nextTick(() => {
                        this.simpleBar.recalculate()
                    })
                })

                //if contrent change, recalculate
                this.resizeObserver.observe(this.$refs.content)
            }
        },
        removeSimpleBar() {
            if (this.resizeObserver) {
                this.resizeObserver.unobserve(this.$refs.content)
            }
        }
    }
}
</script>
