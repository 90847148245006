<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <div class="flex flex-col justify-start items-start max-h-full">
            <h2 class="mb-6">{{ $t("form.edit") }} {{ listGroup.title }}</h2>
            <form
                @submit.prevent="editListGroup"
                class="w-full max-h-full flex flex-col justify-start items-start overflow-y-hidden">
                <ListGroupForm
                    ref="listGroupForm"
                    :list-group="cloneListGroup"
                    :errors="errors" />

                <div class="flex flex-row justify-center items-center my-4 w-full">
                    <CustomButton type="submit">
                        {{ $t("form.btnEdit") }}
                    </CustomButton>
                </div>
            </form>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import ListGroupForm from "components/forms/ListGroupForm"
import cloneDeep from "lodash-es/cloneDeep"

export default {
    name: "EditListGroupModal",
    emits: ["close"],
    components: { ListGroupForm, CustomButton, Modal },

    props: {
        listGroup: Object
    },
    data() {
        return {
            cloneListGroup: cloneDeep(this.listGroup),
            errors: {}
        }
    },
    created() {},
    methods: {
        editListGroup() {
            this.errors = this.$refs.listGroupForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store.dispatch(types.USER_DATA.EDIT_LIST_GROUP, this.cloneListGroup)
            this.$emit("close")
        }
    }
}
</script>
