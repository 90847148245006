<template>
    <div
        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10"
        v-if="data.length > 0">
        <div class="min-w-12"></div>
        <div class="w-1/7">
            <p class="-smallest font-medium text-purple">
                {{ $t("form.name") }}
            </p>
        </div>
        <div class="w-1/7">
            <p class="-smallest font-medium text-purple">
                {{ $t("form.username") }}
            </p>
        </div>
        <div class="w-1/7">
            <p class="-smallest font-medium text-purple">
                {{ $t("game.totalScore") }}
            </p>
        </div>
        <div class="w-1/7">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.successRate") }}
            </p>
        </div>
        <div class="w-1/7">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.totalGamesPlayed") }}
            </p>
        </div>
        <div class="w-1/7">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.totalTimePlayed") }}
            </p>
        </div>
        <div class="w-1/7">
            <p></p>
        </div>
    </div>
    <div
        class="flex flex-row justify-start items-center space-x-1 w-full py-2"
        v-for="(item, idx) in data"
        :key="item.id"
        :class="{
            'border-t border-purple-10': idx !== 0
        }">
        <div class="min-w-12">
            <p class="-small text-purple">{{ idx + 1 }}.</p>
        </div>
        <div class="w-1/7">
            <p class="-small text-purple">
                {{ item.student.name }}
            </p>
        </div>
        <div class="w-1/7">
            <p class="-small text-purple">
                {{ item.student.username }}
            </p>
        </div>
        <div class="w-1/7">
            <p class="-small text-purple">{{ item.totalScore }} {{ $t("global.points") }}</p>
        </div>
        <div class="w-1/7">
            <p class="-small text-purple">{{ item.totalSuccessRate > 0 ? item.totalSuccessRate : 0 }}%</p>
        </div>
        <div class="w-1/7">
            <p class="-small text-purple">{{ item.games.length }}</p>
        </div>
        <div class="w-1/7">
            <p
                class="-small text-purple"
                v-if="studentsTimer.length > 0">
                <span v-if="studentsTimer[idx].hours > 0">
                    {{ padStart(studentsTimer[idx].hours, 2, "0") }}h{{ padStart(studentsTimer[idx].minutes, 2, "0") }}
                </span>
                <span v-else-if="studentsTimer[idx].minutes > 9">
                    {{ padStart(studentsTimer[idx].minutes, 2, "0") }} {{ $t("global.minutes") }}
                </span>
                <span v-else> {{ studentsTimer[idx].minutes }} {{ $t("global.minutes") }} </span>
            </p>
        </div>

        <div class="w-1/7">
            <div class="inline-block">
                <CustomButton
                    version="text"
                    :to="`/user/students/${item.student.uuid}`">
                    {{ $t("game.seeMore") }}
                </CustomButton>
            </div>
        </div>
    </div>
    <p
        class="text-purple -small mt-2"
        v-if="data.length === 0">
        {{ $t("students.noStudentsFromTeacher") }}
    </p>
</template>

<script>
import CustomButton from "components/globals/CustomButton"
import { msToTime } from "@globals/functions"
import padStart from "lodash-es/padStart"

export default {
    name: "ClassroomStudentsTable",
    components: { CustomButton },
    props: {
        data: Array
    },
    data() {
        return {
            padStart
        }
    },
    computed: {
        studentsTimer() {
            return this.data.map((item) => msToTime(item.totalTimeMsPlayed))
        }
    }
}
</script>
