<template>
    <svg
        v-if="code === '01'"
        viewBox="0 0 188 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_113_51)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M94 157C55.3401 157 24 125.66 24 87C24 48.3401 55.3401 17 94 17C132.66 17 164 48.3401 164 87C164 125.66 132.66 157 94 157Z"
                fill="white" />
        </g>
        <defs v-if="version === VERSIONS.AVATAR">
            <clipPath
                width="176"
                height="176"
                :id="shapeName">
                <ProductSvgInnerShape
                    :code="code"
                    fill="none" />
            </clipPath>
        </defs>
        <ProductSvgInnerShape
            :code="code"
            v-else />
        <defs>
            <filter
                id="filter0_d_113_51"
                x="0"
                y="0"
                width="188"
                height="188"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_51" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_51"
                    result="shape" />
            </filter>
            <filter
                id="filter1_d_113_51"
                x="6"
                y="6"
                width="176"
                height="176"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_51" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_51"
                    result="shape" />
            </filter>
        </defs>
        <slot
            :width="125"
            :height="125"
            :x="32"
            :y="24" />
    </svg>

    <svg
        v-else-if="code === '02'"
        viewBox="0 0 174 174"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_113_45)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38 17H136C143.732 17 150 23.268 150 31V129C150 136.732 143.732 143 136 143H38C30.268 143 24 136.732 24 129V31C24 23.268 30.268 17 38 17Z"
                fill="white" />
        </g>
        <defs v-if="version === VERSIONS.AVATAR">
            <clipPath
                width="176"
                height="176"
                :id="shapeName">
                <ProductSvgInnerShape
                    :code="code"
                    fill="none" />
            </clipPath>
        </defs>
        <ProductSvgInnerShape
            :code="code"
            v-else />
        <defs>
            <filter
                id="filter0_d_113_45"
                x="0"
                y="0"
                width="174"
                height="174"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_45" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_45"
                    result="shape" />
            </filter>
            <filter
                id="filter1_d_113_45"
                x="6"
                y="6"
                width="162"
                height="162"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_45" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_45"
                    result="shape" />
            </filter>
        </defs>
        <slot
            :width="112"
            :height="112"
            :x="32"
            :y="24" />
    </svg>

    <svg
        v-else-if="code === '03'"
        viewBox="0 0 188 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_113_39)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M115.213 26.728L154.272 65.7868C165.988 77.5026 165.988 96.4975 154.272 108.213L115.213 147.272C103.498 158.988 84.5026 158.988 72.7868 147.272L33.728 108.213C22.0122 96.4975 22.0122 77.5026 33.728 65.7868L72.7868 26.728C84.5026 15.0122 103.498 15.0122 115.213 26.728Z"
                fill="white" />
        </g>
        <defs v-if="version === VERSIONS.AVATAR">
            <clipPath
                width="176"
                height="176"
                :id="shapeName">
                <ProductSvgInnerShape
                    :code="code"
                    fill="none" />
            </clipPath>
        </defs>
        <ProductSvgInnerShape
            :code="code"
            v-else />
        <defs>
            <filter
                id="filter0_d_113_39"
                x="0.941162"
                y="0.941162"
                width="186.118"
                height="186.118"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_39" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_39"
                    result="shape" />
            </filter>
            <filter
                id="filter1_d_113_39"
                x="6.94116"
                y="6.94116"
                width="174.118"
                height="174.118"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_39" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_39"
                    result="shape" />
            </filter>
        </defs>
        <slot
            :width="112"
            :height="112"
            :x="38"
            :y="40" />
    </svg>

    <svg
        v-else-if="code === '04'"
        viewBox="0 0 176 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_113_42)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M103 21.732L136.158 40.8756C145.44 46.2346 151.158 56.1384 151.158 66.8564V105.144C151.158 115.862 145.44 125.765 136.158 131.124L103 150.268C93.7179 155.627 82.282 155.627 73 150.268L39.8423 131.124C30.5603 125.765 24.8423 115.862 24.8423 105.144V66.8564C24.8423 56.1384 30.5603 46.2346 39.8423 40.8756L73 21.732C82.282 16.373 93.7179 16.373 103 21.732Z"
                fill="white" />
        </g>
        <defs v-if="version === VERSIONS.AVATAR">
            <clipPath
                width="176"
                height="176"
                :id="shapeName">
                <ProductSvgInnerShape
                    :code="code"
                    fill="none" />
            </clipPath>
        </defs>
        <ProductSvgInnerShape
            :code="code"
            v-else />
        <defs>
            <filter
                id="filter0_d_113_42"
                x="0.842285"
                y="0.712769"
                width="174.315"
                height="184.574"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_42" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_42"
                    result="shape" />
            </filter>
            <filter
                id="filter1_d_113_42"
                x="6.84229"
                y="6.71277"
                width="162.315"
                height="172.574"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_42" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_42"
                    result="shape" />
            </filter>
        </defs>
        <slot
            :width="114"
            :height="114"
            :x="32"
            :y="30" />
    </svg>

    <svg
        v-else-if="code === '05'"
        viewBox="0 0 188 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_113_54)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M102.419 18.993L136.135 32.9587C141.526 35.1915 145.809 39.4744 148.041 44.865L162.007 78.5811C164.24 83.9717 164.24 90.0285 162.007 95.4191L148.041 129.135C145.809 134.526 141.526 138.809 136.135 141.042L102.419 155.007C97.0284 157.24 90.9715 157.24 85.5809 155.007L51.8649 141.042C46.4742 138.809 42.1914 134.526 39.9585 129.135L25.9929 95.4191C23.76 90.0285 23.76 83.9717 25.9929 78.581L39.9585 44.865C42.1914 39.4744 46.4742 35.1915 51.8649 32.9587L85.5809 18.993C90.9715 16.7601 97.0284 16.7601 102.419 18.993Z"
                fill="white" />
        </g>
        <defs v-if="version === VERSIONS.AVATAR">
            <clipPath
                width="176"
                height="176"
                :id="shapeName">
                <ProductSvgInnerShape
                    :code="code"
                    fill="none" />
            </clipPath>
        </defs>
        <ProductSvgInnerShape
            :code="code"
            v-else />
        <defs>
            <filter
                id="filter0_d_113_54"
                x="0.318237"
                y="0.318359"
                width="187.363"
                height="187.364"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_54" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_54"
                    result="shape" />
            </filter>
            <filter
                id="filter1_d_113_54"
                x="6.31824"
                y="6.31836"
                width="175.363"
                height="175.364"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="7" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_113_54" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_113_54"
                    result="shape" />
            </filter>
        </defs>
        <slot
            :width="120"
            :height="120"
            :x="35"
            :y="28" />
    </svg>
</template>

<script>
import ProductSvgInnerShape from "components/globals/ProductSvgInnerShape"

const VERSIONS = {
    AVATAR: "avatar",
    PRODUCT: "product"
}

//todo change width height x y of each shape to give more space to the image
export default {
    name: "ProductSvgShape",
    components: { ProductSvgInnerShape },
    props: {
        version: {
            default: "avatar",
            type: String
        },
        code: String,
        shapeName: String
    },
    data() {
        return {
            VERSIONS
        }
    }
}
</script>
