<template>
    <Card rounded>
        <div
            class="flex flex-row justify-center items-center w-10 min-w-10 h-10 md:min-w-12 md:w-12 md:h-12 cursor-pointer">
            <Icons
                v-if="icon"
                :icon="icon"
                class="block"
                :class="iconClass" />
            <slot v-else />
        </div>
    </Card>
</template>

<script>
import Icons from "components/globals/Icons"
import Card from "components/cards/Card"

export default {
    name: "CircleButton",
    components: { Card, Icons },
    props: {
        icon: String,
        iconClass: {
            type: String,
            default: "min-w-4 w-4 h-4"
        }
    }
}
</script>
