<template>
    <div
        class="flex flex-col md:flex-row flex-wrap lg:flex-nowrap justify-center lg:justify-start items-center md:space-x-6">
        <StudentAvatar
            size="bigger"
            :student="student" />
        <div class="pt-6 md:pt-0">
            <h1 class="text-center md:text-left">
                {{ student.name }}
            </h1>
            <div
                v-if="studentExperience"
                class="flex flex-col sm:flex-row justify-start items-center space-y-3 sm:space-x-3 mt-4">
                <div>
                    <h3 class="whitespace-nowrap leading-0 pt-3">
                        {{ $t("global.level") }} {{ studentExperience.level }}
                    </h3>
                    <span class="text-11 text-gray-400 pt-2 block"> {{ studentExperience.levelPercentage }}% </span>
                </div>
                <ProgressBar
                    :min-value="10"
                    class="w-56"
                    :value="student.pointsXP"
                    :max-value="studentExperience.toNextLevel" />
                <PointTag
                    type="A"
                    :value="student.pointsA" />
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from "components/globals/ProgressBar"
import PointTag from "components/globals/PointTag"
import StudentAvatar from "components/globals/StudentAvatar"

export default {
    name: "StudentHeader2",
    components: { StudentAvatar, PointTag, ProgressBar },
    props: {
        student: Object
    },
    computed: {
        studentExperience() {
            return this.$store.getters.getStudentExperience(this.student)
        }
    }
}
</script>
