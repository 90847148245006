<template>
    <defs v-if="code === '01'">
        <linearGradient
            :id="backgroundName"
            x1="0"
            y1="0"
            x2="0"
            y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#DBE7EF" />
        </linearGradient>
    </defs>
    <defs v-if="code === '02'">
        <linearGradient
            :id="backgroundName"
            x1="0"
            y1="0"
            x2="0"
            y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#FCE4C1" />
        </linearGradient>
    </defs>
    <defs v-if="code === '03'">
        <linearGradient
            :id="backgroundName"
            x1="0"
            y1="0"
            x2="0"
            y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#F0E2FE" />
        </linearGradient>
    </defs>
    <defs v-if="code === '04'">
        <linearGradient
            :id="backgroundName"
            x1="0"
            y1="0"
            x2="0"
            y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#DEF3E0" />
        </linearGradient>
    </defs>
    <defs v-if="code === '05'">
        <linearGradient
            :id="backgroundName"
            x1="9.37043"
            y1="15.625"
            x2="143.521"
            y2="89.5848"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#EFE1FE" />
            <stop
                offset="1"
                stop-color="#FFD9AD" />
        </linearGradient>
    </defs>
</template>

<script>
export default {
    name: "ProductSvgBackground",
    components: {},
    props: {
        code: String,
        backgroundName: String
    }
}
</script>
