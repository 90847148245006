<template>
    <div
        class="relative overflow-hidden w-full h-full bg-white rounded-xl border-8 border-white"
        ref="container">
        <div
            ref="map"
            :style="`width:${currentLevel.bg.width}px;height:${currentLevel.bg.height}px;max-width:none;`"
            class="absolute pointer-events-none z-0">
            <div
                class="relative pointer-events-none"
                :style="`width:${currentLevel.bg.width}px;height:${currentLevel.bg.height}px;max-width:none;`">
                <img
                    :width="currentLevel.bg.width"
                    :height="currentLevel.bg.height"
                    :style="`width:${currentLevel.bg.width}px;height:${currentLevel.bg.height}px;max-width:none;`"
                    class="absolute inset-0 z-0 block max-w-none"
                    :src="currentLevel.bg.layers.underPlayer" />
                <img
                    v-if="currentLevel.bg.layers.overPlayer"
                    :width="currentLevel.bg.width"
                    :height="currentLevel.bg.height"
                    :style="`width:${currentLevel.bg.width}px;height:${currentLevel.bg.height}px;max-width:none;`"
                    class="absolute inset-0 z-10 block max-w-none"
                    :src="currentLevel.bg.layers.overPlayer" />
                <div
                    class="absolute inset-0"
                    :style="`width:${currentLevel.bg.width}px;height:${currentLevel.bg.height}px;max-width:none;`"
                    v-if="debug">
                    <div
                        class="flex flex-row justify-start items-center flex-nowrap"
                        v-for="(zone, yIdx) in currentLevel.zones"
                        :key="yIdx">
                        <div
                            v-for="(item, xIdx) in zone"
                            :key="xIdx"
                            :style="`height:${currentLevel.bg.height / currentLevel.zones.length}px`"
                            class="border w-full h-full border-black flex flex-col justify-center items-center">
                            <span style="font-size: 11px">
                                {{ item ? item.substring(0, 2) : "" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            ref="player"
            :style="`height:${currentLevel.player.height}px;`"
            class="player">
            <Character
                :is-licking="lickData && lickData.doLickingAnimation"
                :direction="direction" />
        </div>

        <div
            class="absolute bottom-2 left-0 right-0 flex flex-row justify-center items-center pointer-events-none"
            style="z-index: 11"
            v-if="currentInteraction && !interactionLoading">
            <div class="bg-white shadow rounded inline-block relative overflow-hidden">
                <div class="px-12 py-6 space-y-3">
                    <h2 class="text-center">
                        <span v-if="currentSpecialZoneBag">
                            {{ currentSpecialZoneBag.title }}
                        </span>
                        <span v-else-if="currentSpecialZoneLevel">
                            {{ currentSpecialZoneLevel.title }}
                        </span>
                        <span v-else>
                            {{ currentInteraction.title }}
                        </span>

                    </h2>
                    <p v-if="currentInteraction.description">
                        {{ currentInteraction.description }}
                    </p>
                    <div v-if="currentInteraction.type === ZONES.LEVEL_CHANGE">
                        <div class="w-full flex flex-row justify-center items-center space-x-2"
                             v-if="!hasPurchasedCurrentSpecialZoneLevel">
                            <div class="w-1/2">
                                <p class="-small text-purple text-right">
                                    {{ $t("games.lick.price") }}:&nbsp;
                                </p>
                            </div>
                            <div class="w-1/2 flex flex-row justify-start items-center space-x-2">
                                <h2 class="font-bold">{{ currentSpecialZoneLevel.price }}</h2>
                                <img
                                    class="block h-8 w-auto object-contain"
                                    :src="moneyItemPng" />
                            </div>
                        </div>

                        <p v-if="!canDoLevelAction" class="text-center -small font-semibold text-red">
                            {{ $t("games.lick.notEnoughMoneyToBuyLevel") }}
                        </p>
                    </div>
                    <div v-if="currentInteraction.type === ZONES.LICK">
                        <div v-if="!canDoLickAction">
                            <p v-if="currentBagIsFull" class="-small font-semibold text-red">
                                {{ $t("games.lick.bagIsFull") }}
                            </p>
                            <p v-else class="-small font-semibold text-red">
                                {{ $t("games.lick.needToAnswerQuestions") }}
                            </p>
                        </div>
                    </div>
                    <div v-if="currentInteraction.type === ZONES.SELL">
                        <p v-if="!canDoSellAction" class="-small font-semibold text-red">
                            {{ $t("games.lick.noBugsToSell") }}
                        </p>
                    </div>
                    <div v-if="currentInteraction.type === ZONES.BAG && currentSpecialZoneBag">

                        <p v-if="currentSpecialZoneBag.id === localExtraData.currentBagId"
                           class="text-center -small font-semibold text-green">
                            {{ $t("games.lick.currentBag") }}
                        </p>
                        <p v-else-if="!canDoBagAction" class="text-center -small font-semibold text-red">
                            {{ $t("games.lick.notEnoughMoneyToBuyBag") }}
                        </p>

                        <div class="flex flex-col justify-center items-center space-y-2">
                            <div class="w-full flex flex-row justify-center items-center space-x-2">
                                <div class="w-1/2">
                                    <p class="-small text-purple text-right">
                                        {{ $t("games.lick.price") }}:&nbsp;
                                    </p>
                                </div>
                                <div class="w-1/2 flex flex-row justify-start items-center space-x-2">
                                    <h2 class="font-bold">{{ currentSpecialZoneBag.price }}</h2>
                                    <img
                                        class="block h-8 w-auto object-contain"
                                        :src="moneyItemPng" />
                                </div>
                            </div>
                            <div class="w-full flex flex-row justify-center items-center space-x-2">
                                <div class="w-1/2">
                                    <p class="-small text-purple text-right">
                                        {{ $t("games.lick.capacity") }}:&nbsp;
                                    </p>
                                </div>
                                <div class="w-1/2 flex flex-row justify-start items-center space-x-2">
                                    <h2 class="font-bold"> {{ currentSpecialZoneBag.limit }}</h2>
                                    <img
                                        class="block h-8 w-auto object-contain"
                                        :src="pointItemPng" />
                                </div>
                            </div>

                        </div>
                    </div>

                    <button
                        class="appearance-none w-full p-2 border-2 font-bold rounded text-xs pointer-events-auto"
                        :class="{
                            'opacity-50 pointer-event-none text-black border-gray-400 bg-white cursor-not-allowed': onInteractionMainBtnDisabled,
                            'bg-red border-red text-white': !onInteractionMainBtnDisabled
                        }"
                        @click.prevent="onInteractionMainBtnClick"
                        type="button">
                        <span
                            v-if="currentInteraction.type === ZONES.LEVEL_CHANGE">
                            <span v-if="!hasPurchasedCurrentSpecialZoneLevel">
                                {{ $t("games.lick.buy") }}
                            </span>
                            <span v-else>
                                {{ $t("games.lick.access") }}
                            </span>
                        </span>
                        <span v-else-if="currentInteraction.type === ZONES.BAG">
                            {{ $t("games.lick.buy") }}
                        </span>
                        <span v-else-if="currentInteraction.type === ZONES.LICK">
                            {{ $t("games.lick.lick") }}
                        </span>
                        <span v-else-if="currentInteraction.type === ZONES.SELL">
                            {{ $t("games.lick.sell") }}
                        </span>
                        <span v-else>
                            {{ $t("games.lick.clickHere") }}
                        </span>
                    </button>
                    <div class="relative flex flex-row justify-center items-center"
                         v-if="!onInteractionMainBtnDisabled">
                        <p class="-small">{{ $t("games.lick.or") }}&nbsp;</p>
                        <p class="-small">{{ $t("games.lick.clickOn") }}&nbsp;</p>
                        <img
                            class="w-auto h-6 block mx-1"
                            :src="enterKeyImg" />
                        <p class="-small">&nbsp;{{ $t("games.lick.for2Seconds") }}</p>
                    </div>
                </div>
                <div
                    class="h-2 bg-red w-0"
                    ref="interactionLoadingBar" />
            </div>
        </div>

        <div
            v-if="lickData.showModal"
            style="z-index: 12"
            class="absolute bottom-2 left-0 right-0 flex flex-col justify-center items-center pointer-events-none">
            <div class="bg-white px-24 py-6 shadow rounded flex flex-col justify-start items-start relative">
                <div v-if="lickData.loading">
                    <h3>
                        {{ $t("games.lick.lookingForBugs") }}
                    </h3>
                </div>
                <div
                    v-else-if="lickData.result"
                    class="w-full space-y-4">
                    <h2 class="text-center text-black">{{ lickData.result.title }}</h2>
                    <div class="flex flex-row justify-center items-center space-x-6">
                        <div>
                            <div class="bg-white p-2 border border-gray-400 shadow w-20 h-20 rounded-full">
                                <img
                                    :src="lickData.result.img"
                                    class="object-contain object-center w-16 h-16 block" />
                            </div>
                        </div>

                        <div class="space-y-1">
                            <div class="flex flex-row justify-start items-center space-x-2">
                                <h2 class="font-bold">= {{ lickData.result.price }}</h2>
                                <img
                                    class="block h-8 w-auto object-contain"
                                    :src="moneyItemPng" />
                            </div>
                        </div>
                    </div>

                    <div class="">
                        <button
                            v-if="canDoLickAction"
                            class="appearance-none pointer-events-auto w-full p-2 ease duration-150 transition border border-red hover:bg-red hover:text-white text-12 text-red font-bold rounded whitespace-nowrap"
                            @click="lickAgain"
                            type="button">
                            {{ $t("games.lick.lickAgain") }}
                        </button>
                        <div v-else class="flex flex-col justify-center items-center space-y-2">
                            <h2>
                                {{ $t("games.lick.lickNotAvailable") }}
                            </h2>
                            <p v-if="currentBagIsFull" class="-small font-semibold text-red">
                                {{ $t("games.lick.bagFull") }}
                            </p>
                            <p v-else class="-small font-semibold text-red">
                                {{ $t("games.lick.answerQuestionsFirst") }}
                            </p>
                        </div>
                    </div>
                </div>
                <button
                    class="appearance-none pointer-events-auto w-8 h-8 bg-red rounded-full shadow-2xl absolute -top-4 -right-4 flex flex-col justify-center items-center"
                    @click="closeLickModal"
                    type="button">
                    <Icons
                        icon="cross"
                        class="text-white w-4 h-4 block" />
                </button>
            </div>
        </div>

        <div
            v-if="sellData.showModal"
            style="z-index: 13"
            class="absolute bottom-2 left-0 right-0 flex flex-col justify-center items-center pointer-events-none">
            <div class="bg-white px-24 py-6 shadow rounded flex flex-col justify-start items-start relative">
                <div v-if="sellData.loading">
                    <h3>
                        {{ $t("games.lick.selling") }}
                    </h3>
                </div>
                <div
                    v-else-if="sellData.result"
                    class="w-full space-y-4">
                    <h2 class="text-center">
                        {{ $t("games.lick.sellingResult", { count: sellData.result.bugCount }) }}</h2>
                    <div class="flex flex-row justify-center items-center space-x-2">
                        <h2 class="font-bold">{{ sellData.result.money }}</h2>
                        <img
                            class="block h-16 w-auto object-contain"
                            :src="moneyItemPng" />
                    </div>
                </div>
                <button
                    class="appearance-none pointer-events-auto w-8 h-8 bg-red rounded-full shadow-2xl absolute -top-4 -right-4 flex flex-col justify-center items-center"
                    @click="closeSellModal"
                    type="button">
                    <Icons
                        icon="cross"
                        class="text-white w-4 h-4 block" />
                </button>
            </div>
        </div>

        <div
            v-if="questionData.showModal"
            style="z-index: 15"
            class="absolute inset-16 flex flex-col justify-center items-center">
            <div
                class="bg-white p-6 relative shadow rounded min-h-72 h-70vh w-full flex flex-col justify-start items-start">
                <GameMissingLetters
                    :current-section="currentSection"
                    :word-group="wordGroup"
                    :game="game"
                    @answer="onSendAnswer" />

                <button
                    class="appearance-none w-8 h-8 bg-red rounded-full shadow-2xl absolute -top-4 -right-4 flex flex-col justify-center items-center"
                    @click="closeQuestionAction"
                    type="button">
                    <Icons
                        icon="cross"
                        class="text-white w-4 h-4 block" />
                </button>
            </div>
        </div>

        <div
            v-if="localExtraData"
            style="z-index: 14"
            class="absolute top-4 left-0 right-0 flex flex-row justify-center items-center">
            <div class="flex flex-row justify-center items-center gap-4">
                <div class="relative shadow rounded-[36px]"
                     :class="{
                    'border-red border-4': currentBagIsFull
                }"
                >
                    <div class="absolute inset-0 flex flex-col justify-center items-center">
                        <img
                            class="w-[44px] h-auto block"
                            :src="pointItemPng" />
                        <h2>{{ localExtraData.captureBugIds.length }} / {{ currentBag.limit }}</h2>
                    </div>
                    <img
                        class="w-32 h-auto block"
                        :src="boardItemImg" />
                </div>
                <div class="relative shadow rounded-[36px] border-transparent border-4">
                    <div class="absolute inset-0 flex flex-col justify-center items-center">
                        <img
                            class="w-[44px] h-auto block"
                            :src="lickItemPng" />
                        <h2 :class="{'text-red': localExtraData.lickLeft === 0}">{{ localExtraData.lickLeft }}</h2>
                    </div>
                    <img
                        class="w-32 h-auto block"
                        :src="boardItemImg" />
                </div>
                <div class="relative shadow rounded-[36px] border-transparent border-4">
                    <div class="absolute inset-0 flex flex-col justify-center items-center">
                        <img
                            class="w-[44px] h-auto block"
                            :src="moneyItemPng" />
                        <h2>{{ localExtraData.money }}</h2>
                    </div>
                    <img
                        class="w-32 h-auto block"
                        :src="boardItemImg" />
                </div>
            </div>
        </div>

        <div
            ref="mapOver"
            class="absolute pointer-events-none z-3"
            :style="`width:${currentLevel.bg.width}px;height:${currentLevel.bg.height}px;max-width:none;`">
            <img
                v-if="currentLevel.bg.layers.overPlayer"
                :width="currentLevel.bg.width"
                :height="currentLevel.bg.height"
                :style="`width:${currentLevel.bg.width}px;height:${currentLevel.bg.height}px;max-width:none;`"
                class="absolute inset-0 z-10 block max-w-none"
                :src="currentLevel.bg.layers.overPlayer" />
        </div>

        <div class="joystick-container">
            <Joystick @move="onJoystickMove" />
        </div>

        <div v-if="levelLoading"
             class="z-modal bg-white absolute inset-0 w-full h-full flex flex-col justify-center items-center space-y-2 p-12">
            <h3>
                {{ $t("games.lick.changingLevel") }}
            </h3>
            <Loader />
        </div>
    </div>
</template>
<script>
import Character from "components/game/map/Character.vue"
import Joystick from "components/game/map/Joystick.vue"
import gsap from "gsap"
import { DIRECTIONS } from "services/constants"
import { BAGS, BUGS, INITIAL_STUDENT_GAME_EXTRA_DATA, LEVELS, ZONES } from "@globals/data/3dGame"
import { sleep } from "@globals/functions"
import boardItemImg from "assets/images/games/item.png"
import moneyItemPng from "assets/images/games/money.png"
import lickItemPng from "assets/images/games/lick.png"
import pointItemPng from "assets/images/games/points.png"
import sample from "lodash-es/sample"
import enterKeyImg from "assets/images/games/enter-key.png"
import GameMissingLetters from "components/game/student/types/GameMissingLetters.vue"
import ajax from "config/ajax"
import Icons from "components/globals/Icons.vue"
import Loader from "components/Loader.vue"

const INTERACTION_SPACE_KEYDOWN_SECONDS = 1.25 //1.25 seconds bu feel like 2 sec

const INTERACTIONS = {
    [ZONES.LEVEL_CHANGE]: {
        type: ZONES.LEVEL_CHANGE,
        title: "Changer de niveau"
    },
    [ZONES.LICK]: {
        type: ZONES.LICK,
        title: "Lécher"
    },
    [ZONES.SELL]: {
        type: ZONES.SELL,
        title: "Vendre"
    },
    [ZONES.QUESTION]: {
        type: ZONES.QUESTION,
        title: "Question"
    },
    [ZONES.GO_BACK]: {
        type: ZONES.GO_BACK,
        title: "Retour"
    },
    [ZONES.BAG]: {
        type: ZONES.BAG,
        title: "Sac"
    }
}

export default {
    name: "Game3D",
    components: { Loader, Icons, GameMissingLetters, Joystick, Character },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object,
        studentGameData: Object
    },
    emits: ["answer", "onUpdatedGame"],
    data() {
        return {
            enterKeyImg,
            ZONES,
            boardItemImg,
            lickItemPng,
            moneyItemPng,
            pointItemPng,

            debug: false,// process.env.NODE_ENV !== "production",

            levelLoading: false,
            interactionLoading: false,

            currentLevel: null,

            direction: null,
            currentInteraction: null,
            currentSpecialZone: null,

            interactionSpaceHoldDate: null,
            interactionSpaceHoldSeconds: 0,

            localExtraData: {
                ...INITIAL_STUDENT_GAME_EXTRA_DATA
            },

            lickData: {
                showModal: false,
                loading: false,
                result: null,
                doLickingAnimation: false
            },

            questionData: {
                showModal: false,
                loading: false,
                result: null
            },

            sellData: {
                showModal: false,
                loading: false,
                result: null
            },

            currentPlayerPosition: {
                x: 0,
                y: 0,
                xPercent: 0,
                yPercent: 0
            },
            currentMapPosition: {
                x: 0,
                y: 0
            },

            currentAction: null
        }
    },
    created() {
        this.movementIntervalId = null

        this.localExtraData = {
            ...this.localExtraData,
            ...(this.studentGameData ? this.studentGameData.extraData || {} : {})
        }

        this.currentLevel = LEVELS[this.localExtraData.level]


        window.addEventListener("keydown", this.onKeyDown)
        window.addEventListener("keypress", this.onKeyPress)
        window.addEventListener("keyup", this.onKeyUp)
    },
    mounted() {
        this.setMapZone()
        this.initPlayerPosition()
        this.setPlayerInCenter()
    },
    beforeUnmount() {
        clearInterval(this.movementIntervalId)
        window.removeEventListener("keydown", this.onKeyDown)
        window.removeEventListener("keypress", this.onKeyPress)
        window.removeEventListener("keyup", this.onKeyUp)
    },
    watch: {
        currentSection(nextValue, prevValue) {
            //reset to empty arr when we changed section
            if (nextValue.isCorrectAnswer) {
                this.localExtraData.lickLeft++
                this.saveExtraData()
            }
        },
        direction: function(newVal, oldVal) {
            if (newVal === null) {
                clearInterval(this.movementIntervalId)
                return
            }
            clearInterval(this.movementIntervalId)
            this.movePlayer(newVal)
            this.movementIntervalId = setInterval(() => {
                this.movePlayer(newVal)
            }, 110)
        }
    },
    computed: {
        onInteractionMainBtnDisabled() {
            if (this.currentInteraction.type === ZONES.LEVEL_CHANGE) {
                return !this.canDoLevelAction
            }
            if (this.currentInteraction.type === ZONES.LICK) {
                return !this.canDoLickAction
            }
            if (this.currentInteraction.type === ZONES.SELL) {
                return !this.canDoSellAction
            }
            if (this.currentInteraction.type === ZONES.BAG) {
                return !this.canDoBagAction
            }
            return false
        },
        currentBag() {
            return BAGS.find(b => b.id === this.localExtraData.currentBagId) || null
        },
        currentBagIsFull() {
            if (!this.currentBag) return false

            return this.localExtraData.captureBugIds.length >= this.currentBag.limit
        },
        canDoBagAction() {
            if (!this.currentSpecialZoneBag) return false
            if (this.currentSpecialZoneBag.price > this.localExtraData.money) {
                return false
            }
            if (this.currentSpecialZoneBag.id === this.localExtraData.currentBagId) {
                return false
            }

            return true
        },
        currentSpecialZoneBag() {
            if (!this.currentInteraction) return null
            if (!this.currentSpecialZone) return null
            if (this.currentInteraction.type !== ZONES.BAG) return null
            const bag = BAGS.find(b => b.id === this.currentSpecialZone.bagId) || null
            return bag
        },
        canDoLevelAction() {
            if (!this.currentSpecialZoneLevel) return false
            if (!this.currentSpecialZone) return false
            //already purchase
            if (this.localExtraData.purchasedLevels.includes(this.currentSpecialZone.levelTo)) {
                return true
            }
            //not enough money
            if (this.currentSpecialZoneLevel.price > this.localExtraData.money) {
                return false
            }
            return true
        },
        hasPurchasedCurrentSpecialZoneLevel() {
            if (!this.currentSpecialZoneLevel) return false

            return this.localExtraData.purchasedLevels.includes(this.currentSpecialZoneLevel.code)
        },
        currentSpecialZoneLevel() {
            if (!this.currentInteraction) return null
            if (!this.currentSpecialZone) return null
            if (this.currentInteraction.type !== ZONES.LEVEL_CHANGE) return null
            const level = LEVELS[this.currentSpecialZone.levelTo]
            return {
                code: level.code,
                title: level.title,
                price: level.price
            }
        },
        canDoLickAction() {
            return this.localExtraData.lickLeft > 0 && !this.currentBagIsFull
        },
        canDoSellAction() {
            return this.localExtraData.captureBugIds.length > 0
        }
    },
    methods: {
        onInteractionMainBtnClick() {
            if (!this.currentInteraction) return
            if (this.currentInteraction.type === ZONES.LEVEL_CHANGE) {
                if (this.currentSpecialZone && this.currentSpecialZone.type === ZONES.LEVEL_CHANGE) {
                    this.levelAction()
                }
            } else if (this.currentInteraction.type === ZONES.LICK) {
                this.lickAction()
            } else if (this.currentInteraction.type === ZONES.SELL) {
                this.sellAction()
            } else if (this.currentInteraction.type === ZONES.BAG) {
                this.bagAction()
            } else if (this.currentInteraction.type === ZONES.QUESTION) {
                this.questionAction()
            }
        },
        closeQuestionAction() {
            this.questionData.result = false
            this.questionData.loading = false
            this.questionData.showModal = false
            this.interactionLoading = false
        },
        async questionAction() {
            this.interactionLoading = true
            //can always do question action
            this.questionData.showModal = true
            this.questionData.loading = false //no loading for now
        },
        async bagAction() {
            if (!this.canDoBagAction) return false
            if (!this.currentSpecialZone) return
            let bag = BAGS.find(b => b.id === this.currentSpecialZone.bagId)
            if (!bag) return

            if (bag.price > this.localExtraData.money) {
                return
            }

            //buy bag
            this.localExtraData.currentBagId = bag.id
            this.localExtraData.money -= bag.price

            this.saveExtraData()
        },
        async sellAction() {
            if (!this.canDoSellAction) return
            if (this.sellData.loading) return //already doing action

            this.interactionLoading = true
            this.sellData.showModal = true
            this.sellData.loading = true
            this.sellData.result = null

            let bugCount = this.localExtraData.captureBugIds.length
            let money = this.localExtraData.captureBugIds.reduce((acc, bugId) => {
                let bug = BUGS.find((b) => b.id === bugId)
                if (!bug) {
                    return acc
                }
                return acc + bug.price
            }, 0)

            await sleep(1500) //1.5sec

            this.localExtraData.money += money //add money to pot
            this.localExtraData.captureBugIds = [] //reset

            this.saveExtraData()

            //show money in modal
            this.sellData.result = {
                money: money,
                bugCount
            }
            this.sellData.showModal = true
            this.sellData.loading = false
        },
        async lickAction() {
            if (!this.canDoLickAction) return
            if (this.lickData.loading) return //already loading

            this.interactionLoading = true
            this.lickData.showModal = true
            this.lickData.loading = true
            this.lickData.result = null
            this.localExtraData.lickLeft--
            this.lickData.doLickingAnimation = true

            await sleep(1500) //1.5sec

            this.lickData.showModal = true
            this.lickData.loading = false
            this.lickData.doLickingAnimation = false

            //make sure we get bugs by current level
            let bugsByLevel = BUGS.filter(b => b.levels.includes(this.currentLevel.code))
            let lickBug = sample(bugsByLevel)//always in random for now //todo
            //get random item
            this.lickData.result = lickBug

            this.localExtraData.captureBugIds.push(lickBug.id)
            this.localExtraData.allCaptureBugIds.push(lickBug.id)//always push to all, duplicate will indicate count

            this.saveExtraData()
        },
        lickAgain() {
            this.lickAction()
        },
        closeLickModal() {
            this.lickData.result = false
            this.lickData.loading = false
            this.lickData.showModal = false
            this.interactionLoading = false
        },
        closeSellModal() {
            this.sellData.result = false
            this.sellData.loading = false
            this.sellData.showModal = false
            this.interactionLoading = false
        },
        levelAction() {
            if (!this.currentSpecialZoneLevel) return
            if (this.localExtraData.purchasedLevels.includes(this.currentSpecialZoneLevel.code)) {
                this.goToLevel(this.currentSpecialZoneLevel.code)//we purchase it
                return
            }

            if (!this.canDoLevelAction) return

            this.purchaseLevel(this.currentSpecialZoneLevel.code)
            this.goToLevel(this.currentSpecialZoneLevel.code)
        },
        purchaseLevel(levelCode) {
            if (typeof LEVELS[levelCode] === "undefined") {
                console.error("wrong level change, dont exist:", levelCode)
                return
            }
            //already purchases
            if (this.localExtraData.purchasedLevels.includes(levelCode)) {
                return
            }

            const level = LEVELS[levelCode]

            //not enough money
            if (level.price > this.localExtraData.money) {
                return
            }

            //buy level
            this.localExtraData.purchasedLevels.push(levelCode)
            this.localExtraData.money -= level.price

            //save data
            this.saveExtraData()
        },
        async goToLevel(levelCode) {
            if (typeof LEVELS[levelCode] === "undefined") {
                console.error("wrong level change, dont exist:", levelCode)
                return
            }

            //not purchase
            if (!this.localExtraData.purchasedLevels.includes(levelCode)) {
                return
            }

            this.interactionLoading = true
            this.levelLoading = true

            this.localExtraData.level = levelCode

            this.currentLevel = LEVELS[levelCode]
            this.currentInteraction = null
            this.currentAction = null
            this.currentSpecialZone = null
            this.direction = null

            this.currentPlayerPosition = {
                x: 0,
                y: 0,
                xPercent: 0,
                yPercent: 0
            }

            this.currentMapPosition = {
                x: 0,
                y: 0
            }

            await this.$nextTick()//make sure we update the render
            await sleep(500)
            this.initPlayerPosition()//make sure level is ok
            await this.$nextTick()//make sure we update the render
            await sleep(500)

            this.levelLoading = false
            this.interactionLoading = false

            this.saveExtraData()
        },
        setPlayerInCenter() {
            //  gsap.to(this.$refs.player, { xPercent: "50%", yPercent: "50%" })
        },
        getZonePositionFromPlayerPosition(playerPos) {
            const zones = this.currentLevel.zones
            const currentX = Math.floor((playerPos.x / this.currentLevel.bg.width) * zones[0].length)
            const currentY = Math.floor((playerPos.y / this.currentLevel.bg.height) * zones.length)

            return {
                x: currentX, //so its index 0
                y: currentY //so its index 0
            }
        },
        getZoneFromPlayerPosition(playerPos) {
            let pos = this.getZonePositionFromPlayerPosition(playerPos)
            if (pos.y < 0 || pos.y + 1 > this.currentLevel.zones.length) return null //outside map
            if (pos.x < 0 || pos.x + 1 > this.currentLevel.zones[pos.y].length) return null //outside map
            return this.currentLevel.zones[pos.y][pos.x]
        },
        initPlayerPosition() {
            const zones = this.currentLevel.zones

            let initialX = this.currentLevel.player.initialZone.x
            let initialY = this.currentLevel.player.initialZone.y

            let xPercent = (initialX / zones[0].length) * 100
            let yPercent = (initialY / zones.length) * 100

            let x = (xPercent / 100) * this.currentLevel.bg.width
            let y = (yPercent / 100) * this.currentLevel.bg.height

            this.currentPlayerPosition = {
                x,
                y,
                xPercent,
                yPercent
            }

            this.currentMapPosition = this.playerPosToMapPos(this.currentPlayerPosition)

            gsap.to([this.$refs.map, this.$refs.mapOver], {
                x: this.currentMapPosition.x,
                y: this.currentMapPosition.y,
                duration: 0.08
            })
        },
        setMapZone() {
            //  this.$refs.map.style.width = `${this.currentLevel.bg.height}px`
        },
        onKeyUp(e) {
            if (e.code === "Enter" && this.currentInteraction) {
                this.interactionSpaceHoldSeconds = 0
                if (this.$refs.interactionLoadingBar) {
                    //use overwrite since we need to overwrite the width 20% issue
                    gsap.set(this.$refs.interactionLoadingBar, { width: 0, overwrite: "auto" })
                }
                this.interactionSpaceHoldDate = null

                return
            }
            this.direction = null //reset
            this.currentAction = null //reset
        },
        onKeyDown(e) {
            if (e.code === "Enter" && this.currentInteraction) {
                if (this.interactionLoading) return //skip process, already loading
                if (!this.interactionSpaceHoldDate) {
                    this.interactionSpaceHoldSeconds = 0.1 //start
                    this.interactionSpaceHoldDate = new Date().getTime()
                    gsap.fromTo(this.$refs.interactionLoadingBar, { width: 0 }, { width: `20%`, duration: 0.5 })
                    return
                }
                this.interactionSpaceHoldSeconds = (new Date().getTime() - this.interactionSpaceHoldDate) / 1000
                let percent = (this.interactionSpaceHoldSeconds / INTERACTION_SPACE_KEYDOWN_SECONDS) * 100

                gsap.to(this.$refs.interactionLoadingBar, {
                    width: `${percent}%`,
                    duration: 0.2
                })

                if (this.interactionSpaceHoldSeconds >= INTERACTION_SPACE_KEYDOWN_SECONDS) {
                    this.onKeyUp(e) //fake a key up event
                    this.onInteractionMainBtnClick()
                }
                return
            }

            this.onKeyPress(e)
        },
        onKeyPress(e) {
            let direction = null
            if (e.key === "ArrowUp" || e.key === "w") {
                direction = DIRECTIONS.UP
            } else if (e.key === "ArrowDown" || e.key === "s") {
                direction = DIRECTIONS.DOWN
            } else if (e.key === "ArrowLeft" || e.key === "a") {
                direction = DIRECTIONS.LEFT
            } else if (e.key === "ArrowRight" || e.key === "d") {
                direction = DIRECTIONS.RIGHT
            } else if (e.code === "Enter") {
                return //for interaction
            } else if (e.code === "Escape") {
                //cant close when loading
                if (this.sellData.showModal && !this.sellData.loading) {
                    this.closeSellModal()
                }
                if (this.questionData.showModal && !this.questionData.loading) {
                    this.closeQuestionAction()
                }
                if (this.lickData.showModal && !this.lickData.loading) {
                    this.closeLickModal()
                }
            }
            if (this.direction === direction) return

            //if  loading, cant move
            if (this.sellData.showModal && this.sellData.loading) {
                return
            }
            if (this.lickData.showModal && this.lickData.loading) {
                return
            }


            this.direction = direction
        },
        onJoystickMove({ direction }) {
            //if loading, cant move
            if (this.sellData.showModal && this.sellData.loading) {
                return
            }
            if (this.lickData.showModal && this.lickData.loading) {
                return
            }


            this.direction = direction
        },
        playerPosToMapPos(playerPos) {
            if (!this.$refs.container) {
                return {
                    x: 0,
                    y: 0
                }
            }

            let halfScreenX = this.$refs.container.offsetWidth / 2
            let halfScreenY = this.$refs.container.offsetHeight / 2

            //parse int so we are not in half pixel
            return {
                x: parseInt((playerPos.x - halfScreenX) * -1),
                y: parseInt((playerPos.y - halfScreenY) * -1)
            }
        },
        mapPosToPlayerPos(mapPos) {
            let halfScreenX = this.$refs.container.offsetWidth / 2
            let halfScreenY = this.$refs.container.offsetHeight / 2
            let x = mapPos.x < 0 ? Math.abs(mapPos.x) + halfScreenX : halfScreenX - mapPos.x
            let y = mapPos.y < 0 ? Math.abs(mapPos.y) + halfScreenY : halfScreenY - mapPos.y
            return {
                x,
                y
            }
        },
        onSendAnswer(e) {
            this.$emit("answer", e)
        },
        movePlayer(direction) {
            if (this.lickData.showModal) {
                this.closeLickModal()
            }
            if (this.questionData.showModal) {
                this.closeQuestionAction()
            }
            if (this.sellData.showModal) {
                this.closeSellModal()
            }
            const SPEED = this.$refs.container.clientWidth / 35 //todo 70 seems OK

            let currentPlayerZone = this.getZoneFromPlayerPosition(this.currentPlayerPosition)

            //todo map limit

            let newPosition = {
                x: this.currentPlayerPosition.x,
                y: this.currentPlayerPosition.y,
                xPercent: this.currentPlayerPosition.xPercent,
                yPercent: this.currentPlayerPosition.yPercent
            }

            //make diagonal move slower by making it / 2
            //up
            if ([DIRECTIONS.UP].includes(direction)) {
                newPosition.y -= SPEED
            }
            if ([DIRECTIONS.RIGHT_UP, DIRECTIONS.LEFT_UP].includes(direction)) {
                newPosition.y -= SPEED / 1.5
            }
            //down
            if ([DIRECTIONS.DOWN].includes(direction)) {
                newPosition.y += SPEED
            }
            if ([DIRECTIONS.RIGHT_DOWN, DIRECTIONS.LEFT_DOWN].includes(direction)) {
                newPosition.y += SPEED / 1.5
            }
            //right
            if ([DIRECTIONS.RIGHT].includes(direction)) {
                newPosition.x += SPEED
            }
            if ([DIRECTIONS.RIGHT_DOWN, DIRECTIONS.RIGHT_UP].includes(direction)) {
                newPosition.x += SPEED / 1.5
            }
            //left
            if ([DIRECTIONS.LEFT].includes(direction)) {
                newPosition.x -= SPEED
            }
            if ([DIRECTIONS.LEFT_DOWN, DIRECTIONS.LEFT_UP].includes(direction)) {
                newPosition.x -= SPEED / 1.5
            }

            //            newPosition.x = clamp(newPosition.x, 0, this.currentLevel.bg.width - this.$refs.player.offsetWidth)
            // newPosition.y = clamp(newPosition.y, 0, this.currentLevel.bg.height - this.$refs.player.offsetHeight)
            newPosition.xPercent = (newPosition.x / this.currentLevel.bg.width) * 100
            newPosition.yPercent = (newPosition.y / this.currentLevel.bg.height) * 100

            let newPlayerZone = this.getZoneFromPlayerPosition(newPosition)
            let zonePosition = this.getZonePositionFromPlayerPosition(newPosition)
            let posStr = `${zonePosition.x}-${zonePosition.y}`

            this.currentSpecialZone =
                typeof this.currentLevel.specialZones[posStr] !== "undefined"
                    ? this.currentLevel.specialZones[posStr]
                    : null

            this.currentInteraction =
                typeof INTERACTIONS[newPlayerZone] !== "undefined" ? INTERACTIONS[newPlayerZone] : null

            if (newPlayerZone === null) {
                return //cant go there
            } else {
                if (newPlayerZone === ZONES.LICK) {
                    return //cant go there
                } else if (newPlayerZone === ZONES.BAG) {
                    return //cant go there
                } else if (newPlayerZone === ZONES.SELL) {
                    return //cant go there
                } else if (newPlayerZone === ZONES.QUESTION) {
                    return //cant go there
                } else if (newPlayerZone === ZONES.LEVEL_CHANGE) {
                    return //cant go there
                }
            }

            this.currentPlayerPosition = newPosition

            this.currentMapPosition = this.playerPosToMapPos(newPosition)

            gsap.to([this.$refs.map, this.$refs.mapOver], {
                x: this.currentMapPosition.x,
                y: this.currentMapPosition.y,
                duration: 0.08
            })
        },
        async saveExtraData() {
            if (!this.$store.getters.isStudentLogin) {
                //like anonymous, dont save
                return
            }

            let res = await ajax.put(`/student/student-games/${this.studentGameData.studentGame.uuid}`, {
                extraData: this.localExtraData || {}
            })

            //only sync when in finish
            //if finish
            if (res.gameData.isFinish) {
                this.$emit("onUpdatedGame", {
                    gameData: res.gameData,
                    game: res.game
                })
            }
        }
    }
}
</script>
<style lang="scss">
.map-game {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.joystick-container {
  position: absolute;
  bottom: 100px;
  right: 100px;
  z-index: 10;

  > div {
    width: 100px;
    height: 100px;
  }
}

.player {
  display: inline-block;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  //border: 1px solid black;
}

.interaction {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
</style>
