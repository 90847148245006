<template>
    <div class="py-6 w-full h-full">
        <Card class="h-full w-full">
            <div class="p-3 flex flex-col justify-start items-start h-full">
                <div class="pt-6 w-full flex flex-col justify-start items-start">
                    <h1>{{ $t("scoreboard.podium") }}</h1>
                    <div class="flex flex-row justify-center items-stretch space-x-2 pt-6 flex-1 w-full">
                        <GamePodiumStep
                            v-if="secondPlace"
                            :step="2"
                            :student-game-data="secondPlace" />
                        <GamePodiumStep
                            v-if="firstPlace"
                            :step="1"
                            :student-game-data="firstPlace" />
                        <GamePodiumStep
                            v-if="thirdPlace"
                            :step="3"
                            :student-game-data="thirdPlace" />
                    </div>
                </div>
                <div class="pt-6 flex-1 w-full">
                    <CurrentGameScoreboard
                        :show-count="scoreboardShowCount"
                        :game-data="gameData" />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import { msToTime } from "@globals/functions"
import CurrentGameScoreboard from "components/game/CurentGameScoreboard"
import GamePodiumStep from "components/game/GamePodiumStep"

export default {
    name: "UserLiveGameResult",
    emits: [],
    components: { GamePodiumStep, CurrentGameScoreboard, Card },
    props: {
        gameData: Object,
        game: Object,
        wordGroup: Object
    },
    computed: {
        totalGameTimer() {
            return msToTime(this.studentGameData.totalGameTimeMs)
        },
        thirdPlace() {
            return this.gameData.studentGames.find(sG => sG.position === 3) || null
        },
        secondPlace() {
            return this.gameData.studentGames.find(sG => sG.position === 2) || null
        },
        firstPlace() {
            return this.gameData.studentGames.find(sG => sG.position === 1) || null
        },
        scoreboardShowCount() {
            if (this.gameData.studentGames.length <= 4) return null //show all under or equal 4
            return Math.ceil(this.gameData.studentGames.length / 2) //show half the list
        }
    },
    methods: {}
}
</script>
