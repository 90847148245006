<template>
    <UserHeader align="start">
        <GoBackButton />
        <h1>
            {{ $t("classroom.title") }} <span v-if="classroom">{{ classroom.title }}</span>
        </h1>
    </UserHeader>

    <div class="w-full h-full flex flex-col justify-start items-start flex-1 h-0 my-6">
        <TabbedButtons
            container-class="flex flex-row justify-between items-center"
            @click="currentOptionValue = $event"
            :value="currentOptionValue"
            :options="layouts">
            <div class="w-auto">
                <div class="form flex flex-row justify-end items-center pr-3 space-x-3">
                    <div class="c__input">
                        <label>
                            {{ $t("stats.filterByDate") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model="filteredDate"
                                name="filteredDate"
                                class="form-select">
                                <option
                                    v-for="choice in choices"
                                    :key="choice"
                                    :value="choice.value">
                                    {{ choice.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div
                        class="c__input"
                        v-if="filteredDate === FILTERED_CHOICES.MONTH">
                        <label>
                            {{ $t("stats.month") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model="month"
                                name="month"
                                class="form-select">
                                <option
                                    v-for="month in MONTHS"
                                    :key="month"
                                    :value="month.value">
                                    {{ month.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div
                        class="c__input"
                        v-if="filteredDate === FILTERED_CHOICES.MONTH || filteredDate === FILTERED_CHOICES.YEAR">
                        <label>
                            {{ $t("stats.year") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model="year"
                                name="year"
                                class="form-select">
                                <option
                                    v-for="year in YEARS"
                                    :key="year"
                                    :value="year.value">
                                    {{ year.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </TabbedButtons>
        <Card class="w-full flex-1 h-0 mt-6">
            <simplebar class="scroll-container">
                <div class="px-6">
                    <LoadingContainer :is-loading="isLoading">
                        <keep-alive>
                            <ClassroomGamesTable
                                v-if="currentOptionValue === 'games'"
                                :games="classroomGames"
                                :students="allStudentsForTeacher" />
                            <ClassroomStudentsTable
                                v-else-if="currentOptionValue === 'students-ranking'"
                                :data="studentsTotalRanking" />
                            <ClassroomWordGroupsTable
                                v-else-if="currentOptionValue === 'word-groups'"
                                :data="wordGroupStats" />
                        </keep-alive>
                    </LoadingContainer>
                </div>
            </simplebar>
        </Card>
    </div>
</template>

<script>
import * as types from "store/types"
import UserHeader from "components/globals/UserHeader"
import GoBackButton from "components/globals/GoBackButton"
import Card from "components/cards/Card"
import Simplebar from "components/globals/Simplebar"
import ClassroomGamesTable from "views/user/classroom/ClassroomGamesTable"
import TabbedButtons from "components/globals/TabbedButtons"
import ClassroomStudentsTable from "views/user/classroom/ClassroomStudentsTable"
import { orderBy } from "lodash-es"
import ajax from "config/ajax"
import ClassroomWordGroupsTable from "views/user/classroom/ClassroomWordGroupsTable.vue"
import { FILTERED_CHOICES } from "@globals/constants"
import { MONTHS, YEARS } from "services/constants"
import LoadingContainer from "components/LoadingContainer"
import moment from "moment"

export default {
    name: "UserClassroomStats",
    components: {
        LoadingContainer,
        ClassroomWordGroupsTable,
        ClassroomStudentsTable,
        TabbedButtons,
        ClassroomGamesTable,
        Simplebar,
        Card,
        GoBackButton,
        UserHeader
    },
    data() {
        return {
            FILTERED_CHOICES,
            YEARS,
            MONTHS,

            filteredDate: FILTERED_CHOICES.ALL,
            month: null,
            year: null,
            isLoading: false,
            choices: [
                {
                    label: this.$t("stats.all"),
                    value: FILTERED_CHOICES.ALL
                },
                {
                    label: this.$t("stats.month"),
                    value: FILTERED_CHOICES.MONTH
                },
                {
                    label: this.$t("stats.year"),
                    value: FILTERED_CHOICES.YEAR
                }
            ],

            layouts: [
                {
                    value: "students-ranking",
                    label: this.$t("stats.students")
                },
                {
                    value: "word-groups",
                    label: this.$t("stats.wordGroups")
                },
                {
                    value: "games",
                    label: this.$t("stats.games")
                }
            ],

            currentOptionValue: "students-ranking",

            wordGroupStats: [],
            studentStats: []
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_CLASSROOM_BY_UUID, this.$route.params.uuid)
        this.$store.dispatch(types.USER_DATA.GET_STUDENTS)
        this.$store.dispatch(types.USER_DATA.GET_GAMES_BY_CLASSROOM_UUID, this.$route.params.uuid)

        this.fetchStats()
    },
    watch: {
        filteredDate(nextValue) {
            if (nextValue === FILTERED_CHOICES.ALL) {
                this.year = null
                this.month = null
                this.$nextTick(() => {
                    this.fetchStats()
                })
            }

            if (nextValue === FILTERED_CHOICES.MONTH) {
                this.month = 1
                this.year = new Date().getFullYear()
            }

            if (nextValue === FILTERED_CHOICES.YEAR) {
                this.month = null
                this.year = new Date().getFullYear()
            }
        },
        month() {
            this.$nextTick(() => {
                this.fetchStats()
            })
        },
        year() {
            this.$nextTick(() => {
                this.fetchStats()
            })
        }
    },
    computed: {
        classroom() {
            return this.$store.state.userData.classrooms.find((c) => c.uuid === this.$route.params.uuid)
        },
        allStudentsForTeacher() {
            return this.$store.state.userData.students.filter((s) => s.teacherId === this.$store.state.auth.user.id)
        },
        classroomGames() {
            if (!this.classroom) return []
            let games = this.$store.state.userData.games.filter((g) => g.classroomId === this.classroom.id)

            if (this.filteredDate === FILTERED_CHOICES.MONTH) {
                games = games.filter((game) => {
                    let createdAt = moment(game.createdAt)
                    //createdAt.month() return 0 for janvier
                    return createdAt.month() + 1 === this.month && createdAt.year() === this.year
                })
            }

            if (this.filteredDate === FILTERED_CHOICES.YEAR) {
                games = games.filter((game) => {
                    let createdAt = moment(game.createdAt)
                    return createdAt.year() === this.year
                })
            }
            return games
        },
        atHomeGames() {
            if (!this.classroom || !this.classroom.students) return []
            return this.$store.state.userData.games.filter((g) =>
                this.classroom.students.some((s) => s.id === g.studentCreatorId)
            )
        },
        allGames() {
            return [].concat(this.classroomGames).concat(this.atHomeGames)
        },
        studentsTotalRanking() {
            if (!this.classroom || !this.classroom.students || !this.studentStats) return []

            let studentsByPoints = []
            this.studentStats.forEach((studentStat) => {
                let student = this.classroom.students.find((s) => s.id === studentStat.studentId)
                if (!student) return

                studentsByPoints.push({
                    ...studentStat,
                    student: student
                })
            })

            studentsByPoints = orderBy(studentsByPoints, ["totalScore"], ["desc"])

            return studentsByPoints
        }
    },
    methods: {
        async fetchStats() {
            if (this.isLoading) return //skip

            this.isLoading = true
            if (this.statsController) {
                this.statsController.abort()
                this.statsController = null
            }

            this.statsController = new AbortController()

            let params = {
                filteredDate: this.filteredDate,
                ...(this.month
                    ? {
                          month: this.month
                      }
                    : {}),
                ...(this.year
                    ? {
                          year: this.year
                      }
                    : {})
            }

            let [resWordGroupStats, resStudentStats] = await Promise.all([
                ajax.get(`/user/classrooms/uuid/${this.$route.params.uuid}/word-groups/stats`, {
                    params: params,
                    signal: this.statsController.signal
                }),
                ajax.get(`/user/classrooms/uuid/${this.$route.params.uuid}/students/stats`, {
                    params: params,
                    signal: this.statsController.signal
                })
            ])

            this.wordGroupStats = resWordGroupStats.wordGroupStats
            this.studentStats = resStudentStats.studentStats

            this.statsController = null
            this.isLoading = false
        }
    }
}
</script>
