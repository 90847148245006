<template>
    <modal
        size="big"
        :show="true"
        @close="$emit('close')">
        <div class="p-3 flex flex-col justify-start items-start h-full">
            <div class="pt-6 w-full flex flex-col justify-start items-start">
                <h1>{{ $t("scoreboard.podium") }}</h1>
                <div class="flex flex-row justify-center items-stretch space-x-2 pt-6 flex-1 w-full">
                    <GamePodiumStep
                        v-if="secondPlace"
                        :step="2"
                        :student-game-data="secondPlace" />
                    <GamePodiumStep
                        v-if="firstPlace"
                        :step="1"
                        :student-game-data="firstPlace" />
                    <GamePodiumStep
                        v-if="thirdPlace"
                        :step="3"
                        :student-game-data="thirdPlace" />
                </div>
            </div>
            <div class="pt-6 flex-1 w-full">
                <CurrentGameScoreboard :game-data="fakeGameData" />
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import { msToTime } from "@globals/functions"
import moment from "moment"
import GamePodiumStep from "components/game/GamePodiumStep"
import CurrentGameScoreboard from "components/game/CurentGameScoreboard"

export default {
    name: "UserGameStatModal",
    components: { CurrentGameScoreboard, GamePodiumStep, Modal },
    emits: ["close"],
    props: {
        game: Object
    },
    computed: {
        //fake since it was probably deleted from our server so we need to match data from the game DB data
        fakeGameData() {
            let studentGames = this.game.studentGames.map((sG) => {
                return {
                    totalScore: sG.score,
                    isFinish: false, //so we hide the timer
                    goodResult: null,
                    badResult: null,
                    student: sG.student
                }
            })

            return {
                studentGames: studentGames,
                hasLeftStudentGames: [],
                game: this.game
            }
        },
        totalGameTimer() {
            let ms = 0
            if (this.game.finishedAt) {
                ms = moment.utc(this.game.finishedAt).valueOf() - moment.utc(this.game.startedAt).valueOf()
            }
            return msToTime(ms)
        },
        thirdPlace() {
            if (this.game.studentGames.length < 3) return null
            return this.game.studentGames[2]
        },
        secondPlace() {
            if (this.game.studentGames.length < 2) return null
            return this.game.studentGames[1]
        },
        firstPlace() {
            if (this.game.studentGames.length < 1) return null
            return this.game.studentGames[0]
        }
    }
}
</script>
