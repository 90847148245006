<template>
    <div class="h-full flex flex-col justify-between items-center space-y-6">
        <div
            class="w-full h-1/2 md:flex flex-row justify-between items-center md:space-x-6 rounded p-2 md:p-0 bg-white md:bg-transparent">
            <div class="w-full md:w-1/2 h-1/2 md:h-full rounded relative">
                <template v-if="isBonus">
                    <div class="p-2 border-8 rounded border-red-200 m-6 z-0 absolute inset-0"></div>
                    <div class="absolute top-0 z-1 left-0 right-0 flex flex-row justify-center items-center">
                        <div class="bg-white py-2 px-6">
                            <h1 class="text-red-200 text-center">
                                {{ $t("game.bonusQuestion") }}
                            </h1>
                            <p class="text-red-200 text-center">
                                {{ $t("game.2xPoints") }}
                            </p>
                        </div>
                    </div>
                </template>
                <div class="w-full h-full rounded bg-white flex flex-col justify-center items-center">
                    <h3 class="mb-6">{{ $t("game.hearTheWord") }}</h3>
                    <button
                        type="button"
                        class="relative z-2 appearance-none cursor-pointer"
                        @click.prevent.stop="playSound">
                        <Card
                            rounded
                            color="yellow">
                            <div class="p-1">
                                <Icons
                                    icon="game-type-hear"
                                    class="w-8 h-8 md:w-12 md:h-12 block text-white" />
                            </div>
                        </Card>
                    </button>
                    <audio
                        ref="audioTag"
                        class="hidden"
                        autoplay="false"
                        autostart="0">
                        <source
                            v-if="gameTraduction"
                            :src="gameTraduction.soundUrl"
                            :type="`${gameTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                    </audio>
                </div>
            </div>

            <div
                v-if="currentSection"
                class="w-full md:w-1/2 h-1/2 md:h-full rounded pt-3 md:pt-0"
                :class="{
                    'bg-red': currentSection.isWrongAnswer,
                    'bg-green': currentSection.isCorrectAnswer,
                    'bg-white': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                }">
                <div class="w-full h-full flex flex-col justify-center items-center">
                    <h3
                        class="mb-6"
                        :class="{
                            'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }">
                        {{ $t("game.wordOfXLetters", { lettersCount: gameTraduction.text.length }) }}&nbsp;/&nbsp;
                        <span
                            v-for="(type, idx) in word.type"
                            :key="type">
                            {{ idx > 0 ? ", " : "" }}{{ $t(`global.wordTypes.${type}`) }}
                        </span>
                    </h3>
                    <h1 v-if="!currentSection.isWrongAnswer"
                        class="h-12 tracking-widest flex flex-row justify-center items-center">
                        <span
                            v-for="(letter, index) in currentAnswerLetters"
                            :key="index"
                            class=""
                            :class="{
                                'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                                'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                            }">
                            <template v-if="letter !== ' '">
                                {{ letter }}
                            </template>
                            <template v-else> &#9679;</template>
                        </span>
                        <template
                            v-if="gameTraduction && gameTraduction.text.length - currentAnswerLetters.length > 0">
                            <span
                                v-for="(_, index) in gameTraduction.text.length - currentAnswerLetters.length"
                                :key="index"
                                class="black block bg-gray-150 h-6 md:h-10 w-6 md:w-10 mr-2 mb-2 rounded" />
                        </template>
                    </h1>
                    <h1 v-else class="h-12 tracking-widest flex flex-row justify-center items-center">
                        <span class="text-white">{{ gameTraduction.text }}</span>
                    </h1>
                    <div
                        class="w-3/4 mx-auto border-dashed border-t-2 h-2"
                        :class="{
                            'border-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'border-black': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }"></div>
                </div>
            </div>
        </div>
        <div class="w-full h-1/2">
            <VirtualKeyboard
                accept-real-keys
                class="w-full h-full"
                @update:modelValue="onKeyboardPress"
                :model-value="currentAnswer"
                ref="keyboard" />
        </div>
        <div class="w-full flex flex-row justify-center items-center space-x-6">
            <button
                type="button"
                class="w-full appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center h3"
                :class="{
                    'bg-gray-400 text-gray-200': lettersLeftCount > 0,
                    'bg-green text-white': lettersLeftCount === 0
                }"
                @click.prevent="sendAnswer">
                {{ $t("game.validate") }}
            </button>
        </div>
    </div>
</template>
<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"
import VirtualKeyboard from "components/globals/VirtualKeyboard.vue"
import { FLAGS } from "services/countryFlags"

export default {
    name: "GameBattle",
    components: { VirtualKeyboard, Icons, Card },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            FLAGS,

            currentAnswer: ""
        }
    },
    computed: {
        isBonus() {
            return this.currentSection && this.currentSection.isBonus
        },
        currentAnswerLetters() {
            return this.currentAnswer.split("")
        },
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        gameTraduction() {
            if (!this.word || !this.game) return null
            return this.word.traductions.find((t) => t.lang === this.game.lang)
        },
        lettersLeftCount() {
            if (!this.gameTraduction) return 0
            return this.gameTraduction.text.length - this.currentAnswer.length
        }
    },
    watch: {
        currentSection(nextValue) {
            //make sure we play sound only on new word value
            if (!nextValue.isReal) return

            this.currentAnswer = ""
            this.$nextTick(() => {
                this.playSound()
            })
        }
    },
    mounted() {
        //play on init
        this.playSound()
    },
    methods: {
        async playSound() {
            if (!this.$refs.audioTag) return
            this.$refs.audioTag.autoplay = false

            try {
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
                this.$refs.audioTag.playbackRate = 0.8
            } catch (e) {
            }
        },
        onKeyboardPress(newValue) {
            if (!this.gameTraduction) return
            //we are already at full length needed and we try to add keys
            if (
                this.currentAnswer.length === this.gameTraduction.text.length &&
                newValue.length > this.currentAnswer.length
            )
                return
            this.currentAnswer = newValue
        },
        sendAnswer() {
            if (!this.currentSection) return
            if (!this.currentSection.isReal) return
            if (!this.gameTraduction) return
            if (this.lettersLeftCount > 0) return
            let isCorrectAnswer = this.gameTraduction.text.toLowerCase() === this.currentAnswer.toLowerCase()

            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: isCorrectAnswer ? this.word.uuid : null, //pass null for wrong answer
                inputText: this.currentAnswer
            })
        }
    }
}
</script>
