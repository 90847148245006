<template>
    <StudentHeader class="mb-6">
        <h1 class="text-white">
            {{ $t("notifications.title") }}
        </h1>
    </StudentHeader>

    <div class="flex-1 mb-6 w-full">
        <Card class="w-full h-full">
            <div class="px-6 w-full">
                <simplebar class="scroll-container">
                    <div class="pb-6">
                        <NotificationCard
                            class="border-purple-10"
                            v-for="(notification, idx) in $store.state.studentData.notifications"
                            :key="notification.id"
                            :notification="notification"
                            :class="{
                                'border-t': idx !== 0,
                                'border-b': idx + 1 === $store.state.studentData.notifications.length
                            }" />
                        <p
                            class="-small text-purple"
                            v-if="$store.state.studentData.notifications.length === 0">
                            {{ $t("notifications.noNotifications") }}
                        </p>
                    </div>
                </simplebar>
            </div>
        </Card>
    </div>
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import StudentHeader from "components/globals/StudentHeader"
import Simplebar from "components/globals/Simplebar"
import NotificationCard from "components/cards/NotificationCard"

export default {
    name: "Notifications",
    components: { Card, NotificationCard, Simplebar, StudentHeader },
    data() {
        return {}
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_NOTIFICATIONS)
    },
    methods: {}
}
</script>
