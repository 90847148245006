<template>
    <div class="py-6 h-full w-full">
        <Card class="h-full w-full">
            <div class="p-4 h-full flex flex-col justify-start items-start">
                <div class="w-full flex flex-row justify-between items-center">
                    <h1>
                        {{ $t("game.inGame") }}
                    </h1>

                    <button
                        type="button"
                        @click.prevent="showWords = !showWords">
                        <Icons
                            :icon="showWords ? 'eye-slash' : 'eye'"
                            class="w-6 h-6 block text-purple" />
                    </button>
                </div>
                <simplebar
                    v-if="game.gameType.slug === GAME_TYPES.BATTLE"
                    class="w-full">
                    <div class="min-w-lg">
                        <div class="w-full flex flex-row flex-nowrap justify-start items-start space-x-3 mt-6">
                            <div
                                :style="`width:${(1 / gameData.sections.length) * 100}%`"
                                v-for="section in gameData.sections"
                                :key="section.sectionIdx">
                                <div class="h-20 w-full bg-gray-100 rounded">
                                    <!-- no image for now   <img
                                           v-if="section.words[0].imageUrl"
                                           class="block h-full object-contain object-center w-full"
                                           :src="section.words[0].imageUrl" />  -->
                                </div>

                                <p
                                    v-if="!showWords"
                                    class="text-center text-purple -small">
                                    _____
                                </p>
                                <p
                                    class="text-center text-purple -small"
                                    v-else-if="getGameTraduction(section.words[0])">
                                    {{ getGameTraduction(section.words[0]).text }}
                                </p>
                                <p
                                    class="text-center text-purple -small"
                                    v-else>
                                    {{ section.words[0].text }}
                                </p>
                            </div>
                            <div
                                class="flex flex-col justify-center items-center h-20 rounded bg-gray-100"
                                :style="`width:${(1 / gameData.sections.length) * 100}%`">
                                <p class="text-purple -small">
                                    {{ $t("game.end") }}
                                </p>
                            </div>
                        </div>
                        <div class="w-full px-8 py-12">
                            <LiveBattleRace
                                version="color"
                                :game-data="gameData" />
                        </div>
                    </div>
                </simplebar>
                <div
                    class="w-full flex flex-col justify-start items-center space-x-2 mt-6 mb-6"
                    v-if="game.gameType.slug === GAME_TYPES.CONTEST">
                    <div
                        class="w-full rounded bg-white p-2 flex flex-row justify-center items-center"
                        v-if="sentence">
                        <h1 class="text-center">
                            {{ sentence.text }}
                        </h1>
                        <button
                            type="button"
                            class="appearance-none cursor-pointer ml-4"
                            @click.prevent.stop="playSentenceSound">
                            <Card
                                rounded
                                color="yellow">
                                <div class="p-1">
                                    <Icons
                                        icon="game-type-hear"
                                        class="min-w-8 w-8 h-8 block text-white" />
                                </div>
                            </Card>
                        </button>
                    </div>
                </div>

                <div class="w-full flex-1 h-0">
                    <CurrentGameScoreboard
                        :show-count="scoreboardShowCount"
                        :game-data="gameData" />
                </div>
                <div class="pt-6 w-full flex flex-row justify-between items-center">
                    <div class="w-1/2">
                        <GameTimer
                            class="block font-medium"
                            :timer="timer"
                            v-if="timer" />
                    </div>
                    <div class="w-1/2 flex flex-row justify-end items-center">
                        <p class="text-purple -small">
                            {{ $t("game.waitingForAllPlayers") }}
                        </p>
                    </div>
                </div>
            </div>
        </Card>

        <audio
            ref="audioTag"
            class="hidden"
            autoplay="false"
            autostart="0"
            v-if="game.gameType.slug === GAME_TYPES.CONTEST">
            <source
                v-if="sentence"
                :src="sentence.soundUrl"
                :type="`${sentence.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
        </audio>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import GameTimer from "components/game/GameTimer"
import LiveBattleRace from "components/game/student/live/LiveBattleRace"
import CurrentGameScoreboard from "components/game/CurentGameScoreboard"
import { GAME_TYPES } from "@globals/constants"
import Icons from "components/globals/Icons"
import orderBy from "lodash-es/orderBy"
import Simplebar from "components/globals/Simplebar"

export default {
    name: "UserLiveGameDuring",
    emits: ["restart"],
    components: { Simplebar, Icons, CurrentGameScoreboard, LiveBattleRace, GameTimer, Card },
    props: {
        gameData: Object,
        game: Object,
        timer: Object
    },
    data() {
        return {
            GAME_TYPES,

            showWords: false
        }
    },
    computed: {
        sentence() {
            if (this.game.gameType.slug !== GAME_TYPES.CONTEST) return null
            if (this.gameData.studentGames.length === 0) return null //no student in game
            //grab the lowest sectionsDoneCount so we always show the current sentence
            let studentGame = orderBy(this.gameData.studentGames, ["sectionsDoneCount"], ["asc"])[0]
            let currentSectionIdx = studentGame.sectionsDoneCount

            let section = this.gameData.sections[currentSectionIdx]
            if (section.words.length === 0) return null
            let word = section.words[0] //backend will have place the right one in the first position
            let traduction = this.getGameTraduction(word)
            if (!traduction.sentences) return null
            if (traduction.sentences.length === 0) return null
            return traduction.sentences[0] //backend will have place the right one in the first position
        },
        scoreboardShowCount() {
            if (this.gameData.studentGames.length <= 4) return null //show all under or equal 4
            return Math.ceil(this.gameData.studentGames.length / 2) //show half the list
        }
    },
    methods: {
        async playSentenceSound() {
            if (!this.$refs.audioTag) return
            this.$refs.audioTag.autoplay = false

            try {
                await this.$refs.audioTag.pause()
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
                this.$refs.audioTag.playbackRate = 0.8
            } catch (e) {}
        },
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        }
    }
}
</script>
