<template>
    <div class="rounded overflow-hidden">
        <img
            class="w-full block h-full object-center object-cover"
            v-if="imageUrl"
            :alt="alt"
            :src="imageUrl" />
        <div
            v-else
            class="bg-gray-100 border border-gray-200 rounded w-full h-full flex flex-row justify-center items-center">
            <Icons
                :icon="icon"
                class="w-12 h-12 block text-purple" />
        </div>
    </div>
</template>

<script>
import Icons from "components/globals/Icons"

export default {
    name: "Image",
    components: { Icons },
    props: {
        alt: String,
        imageUrl: {
            required: true,
            type: String
        },
        icon: {
            required: false,
            default: "list",
            type: String
        }
    }
}
</script>
