<template>
    <div class="w-full">
        <div class="c__input">
            <label>
                {{ $t("form.listGroupTitle") }}
            </label>
            <input
                type="text"
                name="title"
                min="4"
                max="191"
                v-model="listGroup.title" />
            <p
                class="error"
                v-if="errors.title">
                {{ errors.title }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListGroupForm",
    components: {},
    props: {
        listGroup: Object,
        errors: Object
    },
    data() {
        return {}
    },
    methods: {
        validate() {
            let errors = {}

            if (!this.listGroup.title) {
                errors.title = this.$t("validationErrors.title")
            }
            if (this.listGroup.title.length < 4) {
                errors.title = this.$t("validationErrors.titleLength")
            }
            return errors
        }
    }
}
</script>
