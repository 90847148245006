<template>
    <StudentHeader
        class="block lg:hidden"
        experience-class="hidden">
        <h1>
            {{ $t("stats.title") }}
        </h1>
    </StudentHeader>

    <div class="py-6 w-full h-0 flex-1">
        <Card class="w-full h-full">
            <StudentProgression
                :student="$store.state.auth.student"
                :stats-words="$store.state.studentData.statsWords" />
        </Card>
    </div>
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import StudentProgression from "components/layouts/StudentProgression"
import StudentHeader from "components/globals/StudentHeader"

export default {
    name: "Progression",
    components: {
        StudentHeader,
        Card,
        StudentProgression
    },
    data() {
        return {}
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_STATS_WORDS)
    }
}
</script>
