<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="">{{ $t("form.edit") }} {{ classroom.title }}</h2>
        <h3 class="pt-4">{{ $t("form.classroomCode") }}: {{ classroom.code }}</h3>
        <form
            @submit.prevent="editClassroom"
            class="mt-6">
            <ClassroomForm
                ref="classroomForm"
                :classroom="cloneClassroom"
                :errors="errors" />
            <div class="flex flex-row justify-center items-center my-4 w-full">
                <CustomButton
                    type="submit"
                    class="mt-6">
                    {{ $t("form.btnEdit") }}
                </CustomButton>
            </div>
        </form>
    </modal>
</template>

<script>
import * as types from "store/types"
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import ClassroomForm from "components/forms/ClassroomForm"
import cloneDeep from "lodash-es/cloneDeep"

export default {
    name: "EditClassroomModal",
    emits: ["close"],
    components: { ClassroomForm, CustomButton, Modal },
    props: {
        classroom: Object
    },
    data() {
        return {
            cloneClassroom: cloneDeep(this.classroom),
            errors: {}
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS)

        if (!this.cloneClassroom.listGroupId) {
            this.cloneClassroom.listGroupId = ""
        }
    },
    methods: {
        editClassroom() {
            this.errors = this.$refs.classroomForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store.dispatch(types.USER_DATA.EDIT_CLASSROOM, this.cloneClassroom)
            this.$emit("close")
        }
    }
}
</script>
