<template>
    <component
        :is="componentName"
        class="w-full font-medium text-12 lg:text-14 xl:text-16 flex flex-row justify-start items-center rounded-sm py-2 px-2 xl:px-4 mb-1 transition duration-75 hover:text-purple hover:bg-gray-100"
        :class="{
            'text-purple bg-gray-100': active,
            'text-purple': item.textActive,
            'text-gray-200': !active && !item.textActive
        }"
        v-bind="customProps"
        @click="$emit('click', item)"
        >
        <div class="relative mr-2 xl:mr-4">
            <Icons
                :icon="item.icon"
                class="w-7 h-7 block" />
            <span
                v-if="item.badge"
                class="block bg-red w-1.5 h-1.5 rounded-full absolute top-0.5 right-0.5" />
        </div>
        <span class="text-inherit">{{ item.name }}</span>
    </component>
</template>

<script>
import Icons from "components/globals/Icons"

export default {
    name: "MenuItem",
    emits: ['click'],
    components: { Icons },
    props: {
        item: Object
    },
    computed: {
        active() {
            if (this.item.active) return true
            return this.item.exact ? this.$route.path === this.item.to : this.$route.path.startsWith(this.item.to)
        },
        customProps(){
            if (this.item.to.startsWith('http')){
                return {
                    href: this.item.to,
                    target: '_blank'
                }
            }

            return {
                to: this.item.to
            }
        },
        componentName() {
            if (this.item.to.startsWith('http')){
                return 'a'
            }
            return 'router-link'
        }
    }
}
</script>
