<template>
    <div class="grid grid-cols-12 grid-rows-4 lg:grid-rows-1 gap-6 w-full h-full">
        <div class="row-span-2 lg:row-span-4 col-span-12 lg:col-span-8 2xl:col-span-9 grid-rows-1">
            <Card class="w-full h-full">
                <div class="p-4 max-h-full w-full h-full grid grid-cols-1 md:grid-cols-2 gap-6">

                    <div class="rounded-sm overflow-hidden h-full">
                        <img
                            :src="lickGameImg"
                            class="object-cover object-center w-full h-full" />
                    </div>
                    <!--  -->

                    <div class="pb-12 px-12">
                        <h1 class="">Jeux de mouche</h1>
                        <h3 class="mt-12">Instructions:</h3>
                        <div class="flex flex-col justify-start items-start space-y-6 mt-6">
                            <div class="flex flex-row justify-start items-center space-x-6">
                                <img class="block w-12 h-auto" :src="lickItemPng" />
                                <p class="-small text-purple">
                                    Répondez à des questions, accumulez votre nombre de coups de langue disponibles.
                                </p>
                            </div>
                            <div class="flex flex-row justify-start items-center space-x-6">
                                <img class="block w-12 h-auto" :src="pointItemPng" />
                                <p class="-small text-purple">
                                    Récoltez des mouches en léchant des roches. Vendez ces mouches pour de l'argent.
                                </p>
                            </div>
                            <div class="flex flex-row justify-start items-center space-x-6">
                                <img class="block w-12 h-auto" :src="moneyItemPng" />
                                <p class="-small text-purple">
                                    Accumulez votre argent ou achetez des avantages pour accélérer vos actions.
                                </p>
                            </div>

                            <div class="w-full pt-16">
                                <CustomButton class="w-full" @click.prevent="onClickStart">
                                    {{ $t("game.btnStartGame") }}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
        <div class="col-span-12 lg:col-span-4 2xl:col-span-3 row-span-2 lg:row-span-4">
            <Card class="w-full h-full">
                <div class="p-4 max-h-full w-full h-full">
                    <div class="flex flex-col justify-start items-start w-full h-full max-h-full">
                        <LickGameOptions
                            v-model="options"
                        />
                    </div>
                </div>
            </Card>
        </div>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import { GAME_TYPES } from "@globals/constants"
import lickGameImg from "assets/images/games/lick-game.png"
import moneyItemPng from "assets/images/games/money.png"
import pointItemPng from "assets/images/games/points.png"
import lickItemPng from "assets/images/games/lick.png"
import LickGameOptions from "components/game/student/types/lobby/LickGameOptions.vue"

export default {
    name: "Game3DLobby",
    components: { LickGameOptions, CustomButton, Card },
    emits: ["exit", "start"],
    props: {
        wordGroup: Object,
        gameType: Object
    },
    data() {
        return {
            GAME_TYPES,
            lickItemPng,
            moneyItemPng,
            pointItemPng,
            lickGameImg,

            options: {
                type: "time", //time or money
                min: 5,
                money: null
            },
            errors: {}
        }
    },
    methods: {
        onChangeMoney(money) {
            this.options.min = null
            this.options.money = money
            this.options.type = "money"
        },
        onChangeMinutes(min) {
            this.options.money = null
            this.options.min = min
            this.options.type = "time"//make sure its time
        },
        onClickStart() {
            this.$emit("start", {
                options: this.options
            })
        }
    }
}
</script>
