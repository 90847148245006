<template>
    <modal
        :show="true"
        @close="$emit('close')"
        size="big"
        :scrollable="false">
        <div class="flex flex-col justify-start items-start max-h-full h-full">
            <h2 class="mb-6">
                {{ $t("form.createWordGroup") }}
            </h2>
            <div class="form w-full max-h-full h-full flex flex-col justify-start items-start overflow-y-hidden">
                <WordGroupForm
                    ref="wordGroupForm"
                    :word-group="wordGroup"
                    :errors="errors" />

                <div class="flex flex-row justify-center items-center my-4 w-full">
                    <CustomButton
                        type="button"
                        @click.prevent="createWordGroup">
                        {{ $t("form.btnCreate") }}
                    </CustomButton>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import WordGroupForm from "components/forms/WordGroupForm"
import * as types from "store/types"

export default {
    name: "CreateWordGroupModal",
    emits: ["close"],
    components: { WordGroupForm, CustomButton, Modal },
    props: {
        listGroupId: Number
    },

    data() {
        return {
            wordGroup: {
                text: "",
                orderIdx: 0,
                isCurrent: false,
                difficulty: "", //grab from all words
                wordIds: [],
                listGroupId: null
            },
            errors: {}
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_WORDS)
        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)

        this.wordGroup.listGroupId = this.listGroupId
    },
    watch: {
        listGroupId(nextValue) {
            this.wordGroup.listGroupId = nextValue
        }
    },
    methods: {
        createWordGroup() {
            this.errors = this.$refs.wordGroupForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store.dispatch(types.USER_DATA.CREATE_WORD_GROUP, this.wordGroup)
            this.$emit("close")
        }
    }
}
</script>
