<template>
    <div class="p-2 shadow-xl bg-white rounded-full">
        <div class="flex flex-row justify-between items-center">
            <p
                class="text-purple"
                :class="{
                    'px-4': size === 'normal',
                    'px-2 -small': size === 'small'
                }">
                {{ label }}
            </p>
            <div
                class="bg-gray-100 rounded-full flex flex-row justify-center items-center"
                :class="{
                    'h-12 min-w-12 w-12 p-3': size === 'normal',
                    'h-8 min-w-8 w-8 p-1': size === 'small'
                }">
                <slot name="icon" />
                <span
                    class="font-medium text-center text-purple"
                    :class="{
                        'text-16': size === 'normal',
                        'text-11': size === 'small'
                    }"
                    v-if="typeof value !== 'undefined'">
                    {{ value }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WordStatCard",
    props: {
        label: String,
        value: Number,
        size: {
            type: String,
            default: "normal"
        }
    },
    computed: {}
}
</script>
