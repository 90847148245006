<template>
    <Card v-if="studentGameData">
        <div class="flex flex-row justify-between items-center p-3">
            <div class="w-1/3">
                <GameTimer
                    tag="h2"
                    :show-hundreth="gameType && gameType.slug !== GAME_TYPES.LICK_3D_GAME"
                    class="block font-medium"
                    :timer="timer" />
            </div>
            <div
                class="w-1/3 flex flex-row justify-center items-center"
                v-if="showPointsSection">
                <div class="pr-6">
                    <h3 class="">
                        <span v-if="studentGameData.sectionsDoneCount >= studentGameData.sectionsCount">
                            {{ studentGameData.sectionsDoneCount }} {{ $t("global.of") }}
                            {{ studentGameData.sectionsCount }}
                        </span>
                        <span v-else>
                            {{ studentGameData.sectionsDoneCount + 1 }} {{ $t("global.of") }}
                            {{ studentGameData.sectionsCount }}
                        </span>
                    </h3>
                </div>

                <div class="flex flex-col justify-center items-center pl-6">
                    <h2 class="text-red-200 text-center">
                        {{ studentGameData.totalScore }}
                    </h2>
                    <p class="text-red-200 font-bold text-center text-13">{{ $t("global.points") }}</p>
                </div>
            </div>

            <div class="w-1/3">
                <div class="flex flex-row justify-end items-center space-x-3 md:space-x-6">
                    <div class="flex flex-row justify-center items-center">
                        <Icons
                            icon="thumb-up"
                            class="w-6 h-6 md:w-8 md:h-8 mr-1 block text-purple" />
                        <h3>
                            {{ studentGameData.goodResult }}
                        </h3>
                    </div>
                    <div class="flex flex-row justify-center items-center">
                        <Icons
                            icon="smiley"
                            class="w-6 h-6 md:w-8 md:h-8 mr-1 block text-purple" />
                        <h3>
                            {{ studentGameData.badResult }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "components/cards/Card"
import GameTimer from "components/game/GameTimer"
import Icons from "components/globals/Icons"
import { GAME_TYPES } from "@globals/constants"

export default {
    name: "GameFooter",
    components: { Icons, GameTimer, Card },
    props: {
        gameType: {
            type: Object,
            required: false
        },
        timer: {
            required: true,
            type: Object
        },
        studentGameData: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            GAME_TYPES
        }
    },
    computed: {
        showPointsSection() {
            if (!this.gameType) return true
            if (this.gameType.slug === GAME_TYPES.LICK_3D_GAME) return false
            return true
        }
    }
}
</script>
