import { createI18n } from "vue-i18n"
import ajax from "config/ajax"
import fr from "@globals/langs/fr.json"
import moment from "moment"
import "moment/locale/fr" //default load fr
import { AVAILABLE_LANGS, LANGS } from "@globals/constants"

let currentLang = LANGS.FR
try {
    let lang = window.localStorage.getItem("adimo:lang")
    if (lang && Object.values(AVAILABLE_LANGS).includes(lang)) {
        currentLang = lang
    }
} catch (e) {}

export const i18n = createI18n({
    locale: currentLang, // set locale
    fallbackLocale: LANGS.FR,
    legacy: true,
    messages: {
        fr //preload fr
    } // set locale messages
})

const loadedLanguages = [LANGS.FR] // our default language that is preloaded

function setI18nLanguage(lang) {
    i18n.locale = lang
    i18n.global.locale = lang
    ajax.defaults.headers.common["Accept-Language"] = lang
    ajax.defaults.headers.common["x-locale"] = lang
    moment.locale(lang)
    document.querySelector("html").setAttribute("lang", lang)
    return lang
}

export function getCurrentLang() {
    return i18n.global.locale
}

export function selectLang(lang, force = false) {
    // If the same language
    if (i18n.global.locale === lang && !force) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    window.localStorage.setItem("adimo:lang", lang)

    // If the language was already loaded
    if (loadedLanguages.includes(lang) && !force) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    //load locale from moment
    //EN always exist in moment
    if (lang !== LANGS.EN) {
        try {
            import("moment/locale/" + lang + ".js")
        } catch (e) {}
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `@globals/langs/${lang}.json`).then((messages) => {
        i18n.global.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
    })
}

export default i18n
