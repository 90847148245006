<template>
    <p class="text-purple pb-4 text-center">
        {{ $t("auth.registerDescription") }}
    </p>

    <div class="w-3/4 mx-auto lg:w-1/2 2xl:w-1/3">
        <CustomButton
            to="/auth/register/student"
            class="w-full mb-4">
            {{ $t("auth.student") }}
        </CustomButton>
        <CustomButton
            to="/auth/register/teacher"
            class="w-full mb-4">
            {{ $t("auth.teacher") }}
        </CustomButton>
        <CustomButton
            to="/auth/register/parent"
            class="w-full mb-4">
            {{ $t("auth.parent") }}
        </CustomButton>
        <CustomButton
            to="/auth/register/school-manager"
            class="w-full mb-4">
            {{ $t("auth.schoolManager") }}
        </CustomButton>
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton"

export default {
    name: "RegisterWelcome",
    components: { CustomButton }
}
</script>
