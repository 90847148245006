<template>
    <h1 class="text-center mb-6">
        {{ $t("auth.loginIntro") }}
    </h1>

    <login-anonymous-form />
    <!--
  <login-student-form v-else/>


  <CustomButton v-if="!withCode" @click.prevent="withCode = true" version="text" class="mt-6">
     {{ $t('auth.btnLoginWithCode') }}
  </CustomButton>
  <CustomButton v-else @click.prevent="withCode = false" version="text" class="mt-6">
     {{ $t('auth.btnLoginWithUsername') }}
  </CustomButton>
-->
    <!--
    <CustomButton class="mb-4" @click.prevent="mode = 'student'">
      Student
    </CustomButton>

    <CustomButton class="mb-4" @click.prevent="mode = 'teacher'">
      Teacher
    </CustomButton>
    <CustomButton class="mb-4" @click.prevent="mode = 'parent'">
      Parent
    </CustomButton>

    <div v-if="mode === 'student'">
      <login-student-form/>
    </div>
    <div v-else-if="mode === 'teacher' || mode === 'parent'">
      <login-user-form/>
    </div>
  -->

    <div class="md:flex flex-row justify-center items-center md:space-x-3 mt-6">
        <CustomButton
            version="text"
            to="/auth/forgot-pwd"
            class="mt-6 md:mt-0">
            {{ $t("auth.btnForgotPwd") }}
        </CustomButton>
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton"
import LoginAnonymousForm from "views/auth/login/LoginAnonymousForm"

export default {
    name: "Login",
    components: { LoginAnonymousForm, CustomButton },
    data() {
        return {
            withCode: false
        }
    }
}
</script>
