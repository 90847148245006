<template>
    <div
        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10"
        v-if="stats.length > 0">
        <div class="w-4/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.word") }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.tries") }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.goodResult") }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.badResult") }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.successRate") }}
            </p>
        </div>
    </div>
    <StudentWordTableItem
        v-for="(item, idx) in statsForPage"
        :key="item.word.id"
        :word="item.word"
        :stats="item.stats"
        :learning-lang="student.learningLang"
        :class="{
            'border-t border-purple-10': idx !== 0
        }" />
    <div
        class="mt-6 flex flex-row justify-center items-center"
        v-if="statsForPage.length > 0">
        <CustomButton
            version="text"
            v-if="statsForPage.length !== stats.length"
            @click.prevent="page++">
            {{ $t("scoreboard.seeMore") }}
        </CustomButton>
    </div>

    <p
        class="text-purple -small mt-2"
        v-if="stats.length === 0">
        {{ $t("stats.noWords") }}
    </p>
</template>

<script>
import CustomButton from "components/globals/CustomButton.vue"
import StudentWordTableItem from "components/layouts/items/StudentWordTableItem.vue"

export default {
    name: "StudentWordsTable",
    components: { StudentWordTableItem, CustomButton },
    props: {
        stats: Array,
        student: Object
    },
    data() {
        return {
            page: 1,
            perPage: 10
        }
    },
    computed: {
        statsForPage() {
            return this.stats.slice(0, this.page * this.perPage)
        }
    }
}
</script>
