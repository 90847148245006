export const addOrReplaceArr = (arr, item, key = "id") => {
    let existIdx = arr.findIndex((p) => p[key] === item[key])
    if (existIdx === -1) {
        arr.push(item)
    } else {
        arr[existIdx] = item
    }
    return arr
}

export const deleteArr = (arr, item, key = "id") => {
    let existIdx = arr.findIndex((p) => p[key] === item[key])
    if (existIdx !== -1) {
        arr.splice(existIdx, 1)
    }
    return arr
}

export const collide = (el1, el2) => {
    let rect1 = el1.getBoundingClientRect()
    let rect2 = el2.getBoundingClientRect()

    return !(
        rect1.top > rect2.bottom ||
        rect1.right < rect2.left ||
        rect1.bottom < rect2.top ||
        rect1.left > rect2.right
    )
}

export const cleanGameCode = (code) => {
    if (!code) return ''
    let str = code.toString()
    let half = parseInt(str.length / 2)
    return [str.substring(0, half), str.substring(half, str.length)].join(" ")
}

export const downloadFile = (url) => {
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    // the filename you want
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()

    setTimeout(() => {
        document.body.removeChild(a)
    }, 250)
}

export const loadScript = (url) => {
    return new Promise((resolve) => {
        //dont load twice
        let _scriptsInHead = Array.from(document.querySelectorAll("head script"))
        for (let _script of _scriptsInHead) {
            if (_script.getAttribute("src") === url) {
                resolve()
                return
            }
        }

        let script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url

        script.onload = resolve

        document.head.appendChild(script)
    })
}

