<template>
    <div
        class="w-full flex flex-row items-center justify-between pt-6"
        :class="`${isInGame ? 'lg:pt-6' : 'lg:pt-12'}`">
        <div class="block lg:hidden mr-3 lg:mr-0">
            <CircleButton
                @click.prevent="openMobileMenu"
                icon="menu"
                icon-class="w-5 h-5" />
        </div>

        <div
            class="flex flex-row items-center w-full space-x-3"
            :class="`justify-${align}`">
            <slot />
        </div>
        <slot name="end"></slot>
    </div>
</template>

<script>
import EventBus, { EVENTS } from "@/eventbus"
import CircleButton from "components/globals/CircleButton"

export default {
    name: "UserHeader",
    components: { CircleButton },
    props: {
        align: {
            type: String,
            default: "between"
        }
    },
    computed: {
        isInGame() {
            return !!this.$route.meta.inGame
        }
    },
    methods: {
        openMobileMenu() {
            EventBus.emit(EVENTS.OPEN_MOBILE_MENU)
        }
    }
}
</script>
