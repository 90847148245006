<template>
    <div class="relative">
        <div
            class="p-1.5 shadow-xl bg-white rounded-full cursor-pointer relative"
            @click.prevent="toggleDropdown">
            <div class="flex flex-row justify-between items-center">
                <div
                    class="bg-gray-100 rounded-full h-10 min-w-10 w-10 p-1.5 flex flex-row justify-center items-center">
                    <Icons
                        icon="users"
                        class="w-full h-full block text-purple" />
                </div>
                <p class="text-purple font-medium px-2 whitespace-nowrap">
                    <span v-if="currentClassroom">
                        {{ currentClassroom.title }}
                    </span>
                    <span v-else>
                        {{ $t("global.noClassrooms") }}
                    </span>
                </p>
                <div
                    class="mx-0.5"
                    v-if="classrooms.length > 0">
                    <Icons
                        icon="caret"
                        class="text-purple w-6 h-6 block" />
                </div>
            </div>
        </div>
        <div
            v-if="showDropdown"
            class="absolute right-0 z-2">
            <div
                v-for="classroom in classrooms"
                :key="classroom.id"
                class="my-3">
                <div
                    type="button"
                    @click.prevent="setCurrentClassroom(classroom.uuid)"
                    class="p-1.5 shadow-xl bg-white rounded-full cursor-pointer relative">
                    <div class="flex flex-row justify-start items-center">
                        <div
                            class="bg-gray-100 rounded-full h-10 min-w-10 w-10 p-1.5 flex flex-row justify-center items-center">
                            <Icons
                                icon="users"
                                class="w-full h-full block text-purple" />
                        </div>
                        <p class="text-purple font-medium px-2 whitespace-nowrap">
                            {{ classroom.title }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icons from "components/globals/Icons"
import * as types from "store/types"

export default {
    name: "ClassroomDropdown",
    components: { Icons },
    data() {
        return {
            showDropdown: false
        }
    },
    computed: {
        currentClassroom() {
            return this.$store.state.userData.classrooms.find(
                (c) => c.uuid === this.$store.state.userData.currentClassroomUuid
            )
        },
        classrooms() {
            return this.$store.state.userData.classrooms.filter(
                (c) => c.uuid !== this.$store.state.userData.currentClassroomUuid
            )
        }
    },
    async created() {
        await this.$store.dispatch(types.USER_DATA.GET_CLASSROOMS)
        await this.$nextTick()

        //autoselect first
        if (this.$store.state.userData.classrooms.length > 0 && !this.currentClassroom) {
            this.$store.commit(
                types.USER_DATA.SET_CURRENT_CLASSROOM_UUID,
                this.$store.state.userData.classrooms[0].uuid
            )
        }
    },
    methods: {
        setCurrentClassroom(uuid) {
            this.showDropdown = false
            this.$store.commit(types.USER_DATA.SET_CURRENT_CLASSROOM_UUID, uuid)
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        }
    }
}
</script>
