<template>
    <div :class="`${readOnly ? 'pointer-events-none opacity-75' : ''}`">
        <div class="md:grid grid-cols-2 gap-x-6">
            <div class="c__input">
                <label>
                    {{ $t("form.firstName") }}
                </label>
                <input
                    type="text"
                    name="firstName"
                    min="4"
                    max="191"
                    v-model="student.firstName"
                    required />
                <p
                    class="error"
                    v-if="errors.firstName">
                    {{ errors.firstName }}
                </p>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("form.lastName") }}
                </label>
                <input
                    type="text"
                    name="lastName"
                    min="4"
                    max="191"
                    v-model="student.lastName"
                    required />
                <p
                    class="error"
                    v-if="errors.lastName">
                    {{ errors.lastName }}
                </p>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("form.username") }}
                    <span
                        v-if="!readOnly"
                        class="text-11"
                    >({{ $t("form.autoGeneratedIfEmpty") }})</span
                    >
                </label>
                <input
                    type="text"
                    name="studentU"
                    min="3"
                    max="191"
                    v-lowercase
                    v-model="student.username" />
                <p
                    class="error"
                    v-if="errors.username">
                    {{ errors.username }}
                </p>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("form.gender") }}
                    <span
                        v-if="!readOnly"
                        class="text-11"
                    >({{ $t("form.genderInfo") }})</span
                    >
                </label>
                <div class="c__select">
                    <select
                        v-model="student.gender"
                        name="gender"
                        class="form-select">
                        <option value="">
                            {{ $t("global.noSelect") }}
                        </option>
                        <option
                            v-for="gender in Object.values(GENDERS)"
                            :key="gender"
                            :value="gender">
                            {{ $t(`global.genders.${gender}`) }}
                        </option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.gender">
                    {{ errors.gender }}
                </p>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("form.lang") }}
                </label>
                <div class="c__select">
                    <select
                        v-model="student.lang"
                        name="lang"
                        class="form-select">
                        <option
                            v-for="lang in Object.values(AVAILABLE_LANGS)"
                            :key="lang"
                            :value="lang">
                            {{ $t(`global.langs.${lang}`) }}
                        </option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.lang">
                    {{ errors.lang }}
                </p>
            </div>
            <!--     <div class="c__input">
                <label>
                    {{ $t("form.learningLang") }}
                </label>
                <div class="c__select">
                    <select
                        v-model="student.learningLang"
                        name="learningLang"
                        class="form-select">
                        <option
                            v-for="lang in Object.values(AVAILABLE_LEARNING_LANGS)"
                            :key="lang"
                            :value="lang">
                            {{ $t(`global.langs.${lang}`) }}
                        </option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.learningLang">
                    {{ errors.learningLang }}
                </p>
            </div> -->
            <div class="c__input">
                <label>
                    {{ $t("form.grade") }}
                </label>
                <div class="c__select">
                    <select
                        v-model.number="student.grade"
                        name="grade"
                        class="form-select">
                        <option
                            value=""
                            selected>
                            {{$t('global.select')}}
                        </option>
                        <option
                            v-for="grade in Object.values(GRADES)"
                            :key="grade"
                            :value="grade">
                            {{ grade }}
                        </option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.grade">
                    {{ errors.grade }}
                </p>
            </div>
            <div class="c__input">
                <label>
                    {{ $t("form.difficulty") }}
                </label>
                <div class="c__select">
                    <select
                        v-model.number="student.difficulty"
                        name="difficulty"
                        class="form-select">
                        <option
                            v-for="wordDifficulty in Object.values(DIFFICULTY)"
                            :key="wordDifficulty"
                            :value="wordDifficulty">
                            {{ $t(`global.difficulty.${wordDifficulty}`) }}
                        </option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.difficulty">
                    {{ errors.difficulty }}
                </p>
            </div>
            <div class="c__input" v-if="$store.getters.isUserLogin && !readOnly">
                <label>
                    {{ $t("form.password") }}
                    <span
                        v-if="version === VERSIONS.EDIT"
                        class="text-11"
                    >({{ $t("form.notChangedIfEmpty") }})</span
                    >
                    <span
                        v-if="version === VERSIONS.CREATE"
                        class="text-11"
                    >({{ $t("form.autoGeneratedIfEmpty") }})</span
                    >
                </label>
                <input
                    type="password"
                    name="studentP"
                    v-model="student.password" />
                <p
                    class="error"
                    v-if="errors.password">
                    {{ errors.password }}
                </p>
            </div>
            <div
                v-if="$store.getters.isUserLogin && !readOnly && version === VERSIONS.EDIT"
                class="c__input">
                <label>
                    {{ $t("form.classroom") }}
                </label>
                <div class="c__select">
                    <select
                        v-model.number="student.classroomId"
                        name="classroomId"
                        class="form-select">
                        <option
                            v-for="classroom in $store.state.userData.classrooms"
                            :key="classroom.id"
                            :value="classroom.id">
                            {{ classroom.title }}
                        </option>
                    </select>
                </div>
                <p
                    class="error"
                    v-if="errors.classroomId">
                    {{ errors.classroomId }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { AVAILABLE_LANGS, AVAILABLE_LEARNING_LANGS, DIFFICULTY, GENDERS, GRADES } from "@globals/constants"

const VERSIONS = {
    CREATE: "create",
    EDIT: "edit"
}

export default {
    name: "StudentForm",
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        version: String,
        student: Object,
        errors: Object
    },
    data() {
        return {
            AVAILABLE_LEARNING_LANGS,
            VERSIONS,
            DIFFICULTY,
            GRADES,
            AVAILABLE_LANGS,
            GENDERS
        }
    },
    watch: {
        "student.lang"(lang) {
            this.student.learningLang = lang //sync with learning lang
        }
    },
    methods: {
        validate() {
            let errors = {}

            if (this.student.firstName === "") {
                errors.firstName = this.$t("validationErrors.firstName")
            }
            if (this.student.lastName === "") {
                errors.lastName = this.$t("validationErrors.lastName")
            }
            if (this.$store.getters.isUserLogin && this.$store.state.auth.user.isTeacher) {
                if (!this.student.classroomId) {
                    errors.classroomId = this.$t("validationErrors.classroom")
                }
            }

            return errors
        }
    }
}
</script>
