<template>
    <modal
        :show="true"
        :closeable="false">
        <h1>
            {{ $t("modal.anonymousNoGameLeft.title") }}
        </h1>
        <p class="text-purple -small my-4">
            {{ $t("modal.anonymousNoGameLeft.description") }}
        </p>

        <div class="flex flex-row justify-center items-center pt-6">
            <CustomButton @click.prevent="createAnAccount">
                {{ $t("auth.btnCreateAccount") }}
            </CustomButton>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import ajax from "config/ajax"

export default {
    components: { CustomButton, Modal },
    name: "NoGameLeftAnonymousModal",
    emits: ["close"],
    data() {
        return {}
    },
    created() {},
    methods: {
        onClose() {
            this.$router.push("/auth/register")
        },
        createAnAccount() {
            ajax.post("/anonymous/stats/conversion")
            this.$router.push("/auth/register")
        }
    }
}
</script>
