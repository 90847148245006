<template>
    <StudentHeader class="mb-6" />
    <div class="flex flex-col justify-center items-center w-full">
        <h1 class="text-center">
            {{ $t("modal.joinGame.title") }}
        </h1>
        <p class="my-4 text-center">
            {{ $t("modal.joinGame.description") }}
        </p>
        <form
            @submit.prevent="joinGame"
            class="flex flex-col justify-center items-center w-full">
            <div class="c__input">
                <label>
                    {{ $t("form.gameCode") }}
                </label>
                <GameCodeInput
                    @change="onGameCodeChange"
                    @complete="joinGame" />

                <p
                    class="error"
                    v-if="errors.code">
                    {{ errors.code }}
                </p>
            </div>

            <p
                class="error"
                v-if="errors.global">
                {{ errors.global }}
            </p>

            <CustomButton
                type="submit"
                class="mt-6">
                {{ $t("play.btnJoinGame") }}
            </CustomButton>
        </form>
    </div>
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import ajax from "config/ajax"
import CustomButton from "components/globals/CustomButton"
import GameCodeInput from "components/game/GameCodeInput"

export default {
    name: "AnonymousJoinLiveGame",
    components: { GameCodeInput, CustomButton, StudentHeader },
    data() {
        return {
            code: "",
            errors: {}
        }
    },
    created() {
        this.$store.dispatch(types.ANONYMOUS_DATA.GET_GLOBALS)
        this.$store.dispatch(types.ANONYMOUS_DATA.GET_WORD_GROUPS)
    },
    methods: {
        goToWordGroup(uuid) {
            this.$router.push(`/anonymous/list/${uuid}`)
        },
        onGameCodeChange(code) {
            this.code = code
        },
        async joinGame() {
            let errors = {}
            if (!this.code) {
                errors.code = this.$t("validationErrors.gameCode")
            }
            this.errors = errors
            if (Object.keys(errors).length > 0) {
                return
            }

            let res
            try {
                res = await ajax.post(`/anonymous/live-games/join/${this.code}`)
            } catch (e) {
                this.errors.global = this.$t("modal.joinGame.noGameFound")
                return
            }

            const { success, game } = res

            if (!success) {
                this.errors.global = this.$t("modal.joinGame.noGameFound")
                return
            }

            this.$router.push(`/anonymous/live/${game.uuid}/${game.gameType.slug}`)
        }
    }
}
</script>
