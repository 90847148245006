<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2>
            {{ $t("modal.paymentMethod.title") }}
        </h2>
        <p class="text-purple -small my-4">
            {{ $t("modal.paymentMethod.description") }}
        </p>

        <add-payment-method @changed="$emit('changed', $event)" />
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import AddPaymentMethod from "components/payment/AddPaymentMethod.vue"

export default {
    name: "AddPaymentMethodModal",
    components: { AddPaymentMethod, Modal },
    emits: ["close", 'changed']
}
</script>
