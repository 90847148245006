<template>
    <div class="flash-message">
        <Card>
            <div class="p-2 flex flex-row justify-start items-center space-x-2">
                <Icons
                    :icon="`flash-${type_}`"
                    class="block w-6 h-6"
                    :class="color_" />
                <p
                    class="-small"
                    :class="color_">
                    {{ message_ }}
                </p>
            </div>
        </Card>
    </div>
</template>

<script>
import EventBus, { EVENTS } from "@/eventbus"
import gsap from "gsap"
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"

const DEFAULT_PROPS = {
    message: "Vous avez un message",
    type: "success",
    time: 3000 //2 seconds
}

export default {
    name: "Flash",
    components: { Icons, Card },
    created() {
        this.showFlash = this.showFlash.bind(this)
        this.hideFlash = this.hideFlash.bind(this)
        EventBus.on(EVENTS.SHOW_FLASH, this.showFlash)
        EventBus.on(EVENTS.HIDE_FLASH, this.hideFlash)

        this.id = -1
    },
    data() {
        return {
            isVisible: false,
            message_: DEFAULT_PROPS.message,
            type_: DEFAULT_PROPS.type,
            color_: "green",
            time_: DEFAULT_PROPS.time
        }
    },
    mounted() {
        gsap.set(this.$el, { right: this.getHideRight() })
    },
    beforeUnmount() {
        EventBus.off(EVENTS.SHOW_FLASH, this.showFlash)
        EventBus.off(EVENTS.HIDE_FLASH, this.hideFlash)
    },
    props: {
        message: {
            type: String,
            default: DEFAULT_PROPS.message
        },
        type: {
            type: String,
            default: DEFAULT_PROPS.type
        },
        time: {
            //in MS
            type: Number,
            default: DEFAULT_PROPS.time
        }
    },
    methods: {
        getHideRight() {
            return -(this.$el.offsetWidth + 60)
        },
        showFlash(props = {}) {
            if (this.isVisible) {
                //hide the flash and call again
                this.hideFlash().then(() => {
                    //make sure the data is change, so we escape a infinite loop
                    this.isVisible = false

                    this.showFlash(props)
                })
                return
            }
            clearTimeout(this.id)

            //set props
            this.message_ = props.message ? props.message : DEFAULT_PROPS.message
            this.type_ = props.type ? props.type : DEFAULT_PROPS.type
            this.time_ = props.time ? props.time : DEFAULT_PROPS.time

            if (props.type === "success") {
                this.color_ = "text-green"
            }
            if (props.type === "warning") {
                this.color_ = "text-red"
            }
            if (props.type === "error") {
                this.color_ = "text-error-red"
            }

            this.isVisible = true
            gsap.set(this.$el, { right: 0 })
            gsap.from(this.$el, 0.5, { right: this.getHideRight() })

            //call hide in
            this.id = setTimeout(this.hideFlash, this.time_)
        },
        hideFlash() {
            return new Promise((resolve) => {
                gsap.to(this.$el, 0.5, {
                    right: this.getHideRight(),
                    onComplete: () => {
                        this.isVisible = false
                        this.$nextTick(() => {
                            resolve()
                        })
                    }
                })
            })
        }
    }
}
</script>

<style lang="scss">
.flash-message {
    @apply fixed top-2 right-0 mr-2 overflow-hidden rounded shadow-xl;
    z-index: 999999;

    &.js-hide {
        height: 0;
    }
}
</style>
