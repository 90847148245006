<template>
    <path
        v-if="code === '01'"
        d="M30 87C30 51.6538 58.6538 23 94 23V23C129.346 23 158 51.6538 158 87V87C158 122.346 129.346 151 94 151V151C58.6538 151 30 122.346 30 87V87Z"
        :fill="fill" />

    <path
        v-else-if="code === '02'"
        d="M30 31C30 26.5817 33.5817 23 38 23H136C140.418 23 144 26.5817 144 31V129C144 133.418 140.418 137 136 137H38C33.5817 137 30 133.418 30 129V31Z"
        :fill="fill" />

    <path
        v-else-if="code === '03'"
        d="M77.0295 30.9706C86.402 21.598 101.598 21.598 110.971 30.9706L150.029 70.0295C159.402 79.402 159.402 94.598 150.029 103.971L110.971 143.029C101.598 152.402 86.4021 152.402 77.0295 143.029L37.9706 103.971C28.598 94.598 28.598 79.4021 37.9706 70.0295L77.0295 30.9706Z"
        :fill="fill" />

    <path
        v-else-if="code === '04'"
        d="M76 26.9282C83.4256 22.641 92.5743 22.641 100 26.9282L133.158 46.0718C140.583 50.3589 145.158 58.282 145.158 66.8564V105.144C145.158 113.718 140.583 121.641 133.158 125.928L100 145.072C92.5743 149.359 83.4256 149.359 76 145.072L42.8423 125.928C35.4167 121.641 30.8423 113.718 30.8423 105.144V66.8564C30.8423 58.282 35.4167 50.3589 42.8423 46.0718L76 26.9282Z"
        :fill="fill" />

    <path
        v-else-if="code === '05'"
        d="M87.877 24.5363C91.7975 22.9124 96.2024 22.9124 100.123 24.5363L133.839 38.5019C137.759 40.1258 140.874 43.2406 142.498 47.1611L156.464 80.8772C158.088 84.7976 158.088 89.2026 156.464 93.123L142.498 126.839C140.874 130.76 137.759 133.874 133.839 135.498L100.123 149.464C96.2024 151.088 91.7975 151.088 87.877 149.464L54.161 135.498C50.2405 133.874 47.1257 130.76 45.5018 126.839L31.5362 93.123C29.9123 89.2026 29.9123 84.7976 31.5362 80.8771L45.5018 47.1611C47.1257 43.2406 50.2405 40.1258 54.161 38.5019L87.877 24.5363Z"
        :fill="fill" />
</template>

<script>
export default {
    name: "ProductSvgInnerShape",
    props: {
        code: String,
        fill: {
            default: "#F6F8FA",
            type: String
        }
    }
}
</script>
