<template>
    <div class="w-full">

        <loading-container :is-loading="loading">
            <div class="w-full" v-if="currentStep === 1">
                <h2 class="text-center mb-6">
                    {{ $t(`auth.teacher`) }}
                </h2>

                <register-user-form @created="onCreatedUser" role="teacher" />
            </div>

            <div class="w-full" v-if="currentStep === 2">
                <h2 class="text-center mb-12">
                    {{ $t("auth.choosePlan") }}
                </h2>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <plan-type-card v-for="(plan) in plans"
                                    :key="plan.handle"
                                    :plan="plan"
                                    @clicked="onPlanSelected"
                                    :selected="selectedPlan === plan.handle"
                    />
                </div>
                <p
                    class="error"
                    v-if="errors.plan">
                    {{ errors.plan }}
                </p>

                <h2 class="text-center mb-3 mt-24">
                    {{ $t("modal.paymentMethod.title") }}
                </h2>

                <add-payment-method btn-class="min-w-32" class="w-full"
                                    @changed="onChangedPaymentMethod" />
            </div>
            <div class="w-full" v-if="currentStep === 3">

                <h2 class="text-center mb-3">
                    {{ $t("auth.createFirstClassroom") }}
                </h2>

                <div class="form flex flex-col justify-center items-center">
                    <div class="c__input w-full">
                        <label
                            for="classroomTitle"
                            class="">
                            {{ $t("form.classroomTitle") }}
                        </label>

                        <input
                            v-model="classroomTitle"
                            type="text"
                            required
                            name="classroomTitle" />

                        <p
                            class="error"
                            v-if="errors.classroomTitle">
                            {{ errors.classroomTitle }}
                        </p>
                    </div>
                    <div class="c__input w-full">
                        <label>
                            {{ $t("form.grade") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model.number="classroomGrade"
                                name="grade"
                                class="form-select">
                                <option
                                    value=""
                                    selected>
                                    {{ $t("global.select") }}
                                </option>
                                <option
                                    v-for="grade in Object.values(GRADES)"
                                    :key="grade"
                                    :value="grade">
                                    {{ grade }}
                                </option>
                            </select>
                        </div>
                        <p
                            class="error"
                            v-if="errors.grade">
                            {{ errors.grade }}
                        </p>
                    </div>

                    <CustomButton
                        class="min-w-32 mt-6"
                        @click.prevent="onCreateClassroom">
                        {{ $t("classroom.btnCreate") }}
                    </CustomButton>
                </div>
            </div>
            <div class="w-full" v-if="currentStep === 4">
                <h2 class="text-center mb-3">
                    {{ $t("auth.createStudents") }}
                </h2>
                <div class="flex flex-col justify-center items-center">
                    <div v-for="(student, idx) in students" :key="idx">
                        <div class="form flex flex-row justify-center items-center gap-4">
                            <div class="c__input w-full" v-for="fieldName in ['firstName', 'lastName']"
                                 :key="fieldName">
                                <label
                                    :for="fieldName"
                                    class="">
                                    {{ $t("form." + fieldName) }}
                                </label>

                                <input
                                    v-model="student[fieldName]"
                                    type="text"
                                    required
                                    :name="fieldName" />
                            </div>

                            <SmallCircleButton
                                class="mt-6"
                                @click.prevent="removeStudent(idx)"
                                icon="trash" />
                        </div>
                        <p
                            class="error"
                            v-if="errors[`students.${idx}.firstName`]">
                            {{ errors[`students.${idx}.firstName`] }}
                        </p>
                        <p
                            class="error"
                            v-if="errors[`students.${idx}.lastName`]">
                            {{ errors[`students.${idx}.lastName`] }}
                        </p>
                    </div>
                    <div class="w-full flex flex-row justify-end items-center pt-2">
                        <CustomButton size="small" @click.prevent="addStudent">
                            {{ $t("students.addStudent") }}
                        </CustomButton>
                    </div>

                    <CustomButton
                        class="min-w-32 mt-6"
                        @click.prevent="onCreateStudents">
                        {{ $t("form.btnFinish") }}
                    </CustomButton>
                </div>


            </div>

            <p
                class="error"
                v-if="errors.global">
                {{ errors.global }}
            </p>

            <div v-if="[3, 4].includes(currentStep)">
                <CustomButton
                    class="mt-6"
                    version="text"
                    @click.prevent="skipStep">
                    {{ $t("global.skipForNow") }}
                </CustomButton>
            </div>


            <CustomButton
                v-if="currentStep === 1"
                class="mt-6"
                version="text"
                @click.prevent="goBack">
                {{ $t("global.goBack") }}
            </CustomButton>
        </loading-container>
    </div>
</template>

<script>
import RegisterUserForm from "views/auth/register/RegisterUserForm"
import CustomButton from "components/globals/CustomButton"
import AddPaymentMethod from "components/payment/AddPaymentMethod.vue"
import Icons from "components/globals/Icons.vue"
import * as types from "store/types"
import { getCurrentLang } from "config/i18n"
import { GRADES } from "@globals/constants"
import cloneDeep from "lodash-es/cloneDeep"
import SmallCircleButton from "components/globals/SmallCircleButton.vue"
import { tryAsyncFunc } from "@globals/helpers/async"
import ajax from "config/ajax"
import PlanTypeCard from "components/cards/PlanTypeCard.vue"
import { teacherPlans } from "@globals/data/plans"
import LoadingContainer from "components/LoadingContainer.vue"

const STUDENT_INIT_DATA = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    gender: "",
    lang: "",
    country: "",
    region: "",
    learningLang: "",
    grade: 1,
    difficulty: 1,
    classroomId: null
}

export default {
    name: "RegisterInstructionsTeacher",
    components: {
        LoadingContainer,
        PlanTypeCard,
        SmallCircleButton,
        Icons,
        AddPaymentMethod,
        CustomButton,
        RegisterUserForm
    },
    data() {
        return {
            GRADES,
            loading: false,
            selectedPlan: null,
            errors: {},
            classroomTitle: "",
            classroomGrade: 1,
            classroom: null,
            students: [],
            currentStep: 1,
            currentUser: null,

            plans: teacherPlans
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        onPlanSelected(planHandle) {
            this.selectedPlan = planHandle
        },
        onCreatedUser(user) {
            this.currentUser = user
            this.nextStep()
        },
        async onCreateStudents() {
            this.errors = {}

            this.students.forEach((student, idx) => {
                if (!student.firstName) {
                    this.errors[`students.${idx}.firstName`] = this.$t("validationErrors.firstName")
                }
                if (!student.lastName) {
                    this.errors[`students.${idx}.lastName`] = this.$t("validationErrors.lastName")
                }
            })

            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.loading = true
            this.errors = {}
            for (let student of this.students) {
                try {
                    await this.$store.dispatch(types.USER_DATA.CREATE_STUDENT, student)
                } catch (e) {
                    console.error(e)
                }
            }
            this.nextStep()
        },
        addStudent() {
            let newStudent = cloneDeep(STUDENT_INIT_DATA)

            newStudent.lang = this.$store.state.auth.user.lang //from parent
            newStudent.country = this.$store.state.auth.user.country
            newStudent.region = this.$store.state.auth.user.region

            if (this.classroom) {
                newStudent.classroomId = this.classroom.id
                newStudent.grade = this.classroom.grade
            }

            if (this.$store.state.auth.user.teachingLangs.length > 0) {
                newStudent.learningLang = this.$store.state.auth.user.teachingLangs[0]
            }

            this.students.push(newStudent)
        },
        removeStudent(idx) {
            this.students.splice(idx, 1)
        },
        async onChangedPaymentMethod(paymentMethod) {

            this.errors = {}

            if (!this.selectedPlan) {
                this.errors.plan = this.$t("validationErrors.plan")
                return
            }

            this.loading = true
            //create plan
            const [res, err] = await tryAsyncFunc(ajax.post(`/user/me/subscription/create`, {
                planType: this.selectedPlan
            }))

            if (err) {
                this.loading = false
                this.errors = err.errors || {plan:this.$t("validationErrors.plan")}
                return
            }

            await this.$store.dispatch(types.GET_USER)//make sure we refresh user data with license, etc

            this.loading = false
            this.nextStep()
        },
        async onCreateClassroom() {
            this.errors = {}
            if (!this.classroomTitle) {
                this.errors.classroomTitle = this.$t("validationErrors.classroomTitle")
                return
            }
            if (!this.classroomGrade) {
                this.errors.classroomGrade = this.$t("validationErrors.classroomGrade")
                return
            }

            this.loading = true
            const classroom = await this.$store.dispatch(types.USER_DATA.CREATE_CLASSROOM, {
                title: this.classroomTitle,
                grade: this.classroomGrade,
                lang: getCurrentLang(),
                listGroupId: null
            }).catch(() => {
                //on error, just cancel process
                this.$router.push("/")
                return null
            })

            this.loading = false

            if (!classroom) {
                return
            }

            this.classroom = classroom//will be use to create student
            this.addStudent()//add 1 first for class
            this.nextStep()
        },
        skipStep() {
            if (this.currentStep === 3) {
                //if we skip creating classroom, we need to skip students too
                this.nextStep()
            }
            this.nextStep()
        },
        prevStep() {
            this.errors = {}
            if (this.currentStep === 0) return
            this.currentStep--
        },
        nextStep() {
            this.errors = {}

            //finish, go to dashboard
            if (this.currentStep === 4) {
                this.$router.push("/user/students")//will land into view of students
                return
            }

            this.currentStep++
        }
    }
}
</script>
