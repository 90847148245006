<template>
    <modal
        :show="true"
        @close="$emit('close')"
        :scrollable="false">
        <div class="flex flex-col justify-start items-start h-full">
            <div class="flex flex-row justify-between items-center w-full">
                <h2>
                    {{ $t("modal.classroomPdf.title", { title: classroom.title }) }}
                </h2>
                <CustomButton
                    type="button"
                    @click.prevent="download">
                    {{ $t("global.btnDownload") }}
                </CustomButton>
            </div>
            <div class="flex-1 h-0 my-6 overflow-auto mx-auto">
                <Loader v-if="isLoading" />
                <canvas
                    ref="canvas"
                    class="w-full h-full"></canvas>
            </div>

            <div
                class="flex flex-row justify-center items-center space-x-2 w-full"
                v-if="hasPages">
                <CircleButton
                    icon="arrow-left"
                    @click.prevent="prev" />
                <p>{{ currentPage }}</p>
                <CircleButton
                    class="transform rotate-180"
                    icon="arrow-left"
                    @click.prevent="next" />
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import Loader from "components/Loader"
import ajax from "config/ajax"
import CustomButton from "components/globals/CustomButton"
import { downloadFile } from "helpers/helpers"
import CircleButton from "components/globals/CircleButton"

export default {
    name: "ShowClassroomBulkDocument",
    components: { CircleButton, CustomButton, Loader, Modal },
    props: {
        classroom: Object
    },
    emits :['close'],
    data() {
        return {
            isLoading: true,
            hasPages: false,
            currentPage: 1,
            zoom: 1,
            bulkDocument: null
        }
    },
    mounted() {
        this.ctx = this.$refs.canvas.getContext("2d")
        this.loadPdfJs()
    },
    beforeUnmount() {
        if (this.bulkDocument) {
            URL.revokeObjectURL(this.bulkDocument.documentUrl)
        }
    },
    methods: {
        prev() {
            if (this.currentPage === 1) return
            this.currentPage--

            this.$nextTick(() => {
                this.createPdf()
            })
        },
        next() {
            if (!this.pdf) return
            if (this.currentPage >= this.pdf._pdfInfo.numPages) return
            this.currentPage++

            this.$nextTick(() => {
                this.createPdf()
            })
        },
        async loadDocument() {
            let res = await ajax.get(`/user/bulk/documents/classrooms/${this.classroom.uuid}`)
            this.bulkDocument = res.bulkDocument

            window.pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdfjs/build/pdf.worker.js"
            this.pdf = await window.pdfjsLib.getDocument(res.bulkDocument.documentUrl).promise

            this.hasPages = this.pdf._pdfInfo.numPages > 1
            this.createPdf()
        },
        loadPdfJs() {
            let scriptSrc = "/pdfjs/build/pdf.js"

            //dont double load
            if (document.head.innerHTML.includes(scriptSrc)) {
                this.loadDocument()
                return
            }
            var script = document.createElement("script")
            script.onload = () => {
                this.loadDocument()
            }
            script.src = scriptSrc

            document.head.appendChild(script) //or something of the likes
        },
        download() {
            downloadFile(this.bulkDocument.documentUrl)
        },
        async createPdf() {
            let page = await this.pdf.getPage(this.currentPage)

            let viewport = page.getViewport({ scale: this.zoom })

            // Support HiDPI-screens.
            let outputScale = window.devicePixelRatio || 1

            this.$refs.canvas.width = Math.floor(viewport.width * outputScale)
            this.$refs.canvas.height = Math.floor(viewport.height * outputScale)
            this.$refs.canvas.style.width = Math.floor(viewport.width) + "px"
            this.$refs.canvas.style.height = Math.floor(viewport.height) + "px"

            var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null

            page.render({
                canvasContext: this.ctx,
                transform: transform,
                viewport: viewport
            })

            this.isLoading = false
        }
    }
}
</script>
