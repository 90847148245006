<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 v-if="isUser">
            {{ $t(`modal.${langStr}.titleSelf`) }}
        </h2>
        <h2 v-else>
            {{ $t(`modal.${langStr}.title`, { name: user.name }) }}
        </h2>

        <p
            class="my-4"
            v-if="isUser">
            {{ $t(`modal.${langStr}.descriptionSelf`) }}
        </p>
        <p
            class="my-4"
            v-else>
            {{ $t(`modal.${langStr}.description`) }}
        </p>

        <CustomButton @click.prevent="$emit('cancel')">
            {{ $t(`modal.${langStr}.btn`) }}
        </CustomButton>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "CancelUserSubscriptionModal",
    components: { CustomButton, Modal },
    emits: ["close", "cancel"],
    props: {
        user: Object
        // license: Object,
    },
    computed: {
        langStr() {
            return this.user.isParent ? "cancelParentSubscription" : "cancelTeacherSubscription"
        },
        /* endDateStr(){
            return moment(this.license.finishedAt).utc().local().format("LL")
        }, */
        isUser() {
            return this.user.id === this.$store.state.auth.user.id
        }
    }
}
</script>
