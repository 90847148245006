<template>
    <div class="w-full h-full max-h-full">
        <Loader v-if="isLoading" />
        <div
            class="flex flex-col justify-start items-start w-full h-full max-h-full"
            v-else>
            <h2>
                {{ $t("billing.paymentMethod") }}
            </h2>
            <simplebar class="w-full h-0 flex-1">
                <div
                    class="flex flex-row justify-between items-center space-x-3 mt-4 p-2 border rounded border-gray-200"
                    v-if="paymentData">
                    <img
                        :alt="paymentData.cardType"
                        :src="paymentData.imageUrl"
                        class="w-12 h-auto block" />
                    <p class="text-purple -small">
                        {{ paymentData.cardType }}
                    </p>
                    <p class="text-purple -small">XXXX XXXX XXXX {{ paymentData.last4 }}</p>
                    <p class="text-purple -small">
                        {{ paymentData.expirationDate }}
                    </p>
                </div>
                <p
                    class="text-purple -small pt-2"
                    v-else>
                    {{ $t("billing.noPaymentMethod") }}
                </p>
                <div class="inline-block mt-6">
                    <CustomButton @click.prevent="addPaymentMethod">
                        <template v-if="paymentData">
                            {{ $t("billing.changePaymentMethod") }}
                        </template>
                        <template v-else>
                            {{ $t("billing.addPaymentMethod") }}
                        </template>
                    </CustomButton>
                </div>
            </simplebar>
        </div>

        <AddPaymentMethodModal
            v-if="showAddPaymentMethodModal"
            @close="showAddPaymentMethodModal = false"
            @changed="onChanged" />
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton"
import AddPaymentMethodModal from "components/modals/user/AddPaymentMethodModal"
import Simplebar from "components/globals/Simplebar"
import Loader from "components/Loader"

export default {
    name: "PaymentMethod",
    components: { Loader, Simplebar, AddPaymentMethodModal, CustomButton },
    emits: ["changed"],
    props: {
        isLoading: Boolean
    },
    data() {
        return {
            showAddPaymentMethodModal: false
            // transactions: []
        }
    },
    computed: {
        paymentData() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.paymentData
        }
    },
    methods: {
        addPaymentMethod() {
            this.showAddPaymentMethodModal = true
        },
        onChanged(paymentMethod){
            this.showAddPaymentMethodModal = false
            this.$emit('changed', paymentMethod)
        }
    }
}
</script>
