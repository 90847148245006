<template>
    <div class="w-full h-full flex flex-col justify-start items-start">
        <h2 class="pb-3">
            {{ $t("scoreboard.title") }}
        </h2>
        <div class="w-full h-0 flex-1">
            <simplebar class="">
                <div
                    v-for="(studentGame, idx) in scoreboard"
                    :key="studentGame.student.id">
                    <div
                        v-if="!localShowCount || (localShowCount && idx < localShowCount)"
                        class="flex flex-row flex-wrap md:flex-nowrap justify-between items-center w-full p-3 border-b border-purple-10"
                        :class="{
                            'border-t': idx === 0,
                            'bg-gray-100': isStudent(studentGame.student)
                        }">
                        <div class="w-full md:w-1/2 flex flex-row justify-start items-center">
                            <span class="block font-bold text-purple min-w-6 text-12 md:text-14">
                                {{ idx + 1 }}
                            </span>
                            <span class="block text-12 md:text-14 text-purple">
                                {{ studentGame.student.name }} ({{ studentGame.student.username }})
                                <span v-if="isStudent(studentGame.student)">
                                    ({{ capitalize($t("global.you")) }})
                                </span>
                            </span>
                        </div>
                        <div
                            class="w-full md:w-1/2 flex flex-row justify-start md:justify-end items-center space-x-4 ml-6 md:ml-0 pt-1 md:pt-0">
                            <p class="text-purple">
                                <span class="flex flex-row justify-center items-center space-x-2" v-if="gameData.game.gameTypeSlug === GAME_TYPES.LICK_3D_GAME">
                                    <span class="font-bold">
                                        {{ studentGame.extraData.money }}
                                    </span>
                                    <img
                                        class="block h-8 w-auto object-contain"
                                        :src="moneyItemPng" />
                                </span>
                                <span v-else>
                                    <span class="font-bold">{{ studentGame.totalScore }}</span>
                                    &nbsp;{{ $t("global.points") }}
                                </span>
                            </p>
                            <div
                                class="min-w-20 flex flex-row justify-center items-center"
                                v-if="studentGame.isFinish && showTotalGameTime">
                                <GameTimer
                                    tag="p"
                                    class="block text-purple font-bold"
                                    :timer="msToTime(studentGame.totalGameTimeMs)" />
                            </div>
                            <div
                                class="flex-row flex-nowrap justify-center items-center hidden xl:flex"
                                v-if="studentGame.goodResult !== null">
                                <Icons
                                    icon="thumb-up"
                                    class="w-5 h-5 mr-1 block text-purple" />
                                <p class="font-bold text-purple">
                                    {{ studentGame.goodResult }}
                                </p>
                            </div>
                            <div
                                class="flex-row flex-nowrap justify-center items-center hidden xl:flex"
                                v-if="studentGame.badResult !== null">
                                <Icons
                                    icon="smiley"
                                    class="w-5 h-5 mr-1 block text-purple" />
                                <p class="font-bold text-purple">
                                    {{ studentGame.badResult }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="flex flex-row justify-between items-center pt-1"
                    v-if="showCount && scoreboard.length > showCount">
                    <div class="pl-8">
                        <p
                            v-if="!!localShowCount"
                            class="text-purple -small">
                            {{ $t("scoreboard.moreStudents") }}
                        </p>
                    </div>
                    <div>
                        <CustomButton
                            v-if="localShowCount"
                            version="text"
                            @click.prevent="localShowCount = null"
                            type="button">
                            {{ $t("scoreboard.showAllStudents") }}
                        </CustomButton>
                        <CustomButton
                            v-else
                            version="text"
                            @click.prevent="localShowCount = showCount"
                            type="button">
                            {{ $t("scoreboard.hideAllStudents") }}
                        </CustomButton>
                    </div>
                </div>
            </simplebar>
        </div>
    </div>
</template>

<script>
import orderBy from "lodash-es/orderBy"
import { GAME_TYPES } from "@globals/constants"
import GameTimer from "components/game/GameTimer"
import { msToTime } from "@globals/functions"
import Simplebar from "components/globals/Simplebar"
import Icons from "components/globals/Icons"
import CustomButton from "components/globals/CustomButton.vue"
import moneyItemPng from "assets/images/games/money.png"

export default {
    name: "CurrentGameScoreboard",
    components: { CustomButton, Icons, Simplebar, GameTimer },
    props: {
        gameData: Object,
        showCount: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            GAME_TYPES,
            moneyItemPng,
            localShowCount: null
        }
    },
    watch: {
        showCount: {
            handler: function(nextValue) {
                this.localShowCount = nextValue
            },
            immediate: true
        }
    },
    created() {
    },
    computed: {
        allStudentGames() {
            return [].concat(this.gameData.studentGames).concat(this.gameData.hasLeftStudentGames)
        },
        showTotalGameTime() {
            if (!this.gameData) return false
            //dont show total game time on contest since its the same for everybody
            if (this.gameData.game.gameTypeSlug === GAME_TYPES.CONTEST) return false
            if (this.gameData.game.gameTypeSlug === GAME_TYPES.LICK_3D_GAME) return false
            return true
        },
        //todo dont update student when isStudentLogin and game context so he dosent know the right answer
        scoreboard() {
            if (this.gameData.isFinish) {
                return this.allStudentGames
            }

            if (this.gameData.game.gameTypeSlug === GAME_TYPES.CONTEST) {
                return orderBy(this.allStudentGames, ["totalScore"], ["desc"])
            } else if (this.gameData.game.gameTypeSlug === GAME_TYPES.LICK_3D_GAME) {
                return orderBy(this.allStudentGames, ["extraData.money"], ["desc"])
            }

            //during game, just show who has completed the most
            return orderBy(this.allStudentGames, ["sectionsDoneCount"], ["desc"])
        }
    },
    methods: {
        msToTime(s) {
            return msToTime(s)
        },
        isStudent(student) {
            if (!student) return false
            if (this.$store.getters.isStudentLogin) {
                return student.id === this.$store.state.auth.student.id
            }
            if (this.$store.getters.isAnonymousLogin) {
                return student.id === this.$store.state.auth.anonymous.id
            }
            return false
        }
    }
}
</script>
