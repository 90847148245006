<template>
    <Card class="relative">
        <div class="sm:flex flex-row justify-between items-stretch p-3 md:space-x-3">
            <div class="flex flex-row justify-start sm:justify-between items-stretch space-x-3">
                <Image
                    class="h-28 w-32 min-w-32 md:w-40 md:min-w-40"
                    :image-url="wordGroup.imageUrl"
                    :alt="wordGroup.text" />
                <div class="flex flex-col justify-start items-start py-2">
                    <div class="mb-3 flex flex-row justify-start items-center space-x-3">
                        <Popper
                            v-if="wordGroup.isCurrent"
                            class="block -mt-1"
                            :content="$t(`list.isCurrent`)">
                            <Icons
                                icon="sparkles"
                                class="text-red w-6 h-6" />
                        </Popper>
                        <h5 class="opacity-60 text-purple uppercase space-x-2">
                            <span>{{ wordGroup.wordIds.length }}&nbsp;{{ $t(`multi.words.${learningLang}`) }}</span>
                            <span v-if="wordGroup.externalCode || wordGroup.shortcode">|</span>
                            <span v-if="wordGroup.shortcode">#{{ wordGroup.shortcode }}</span>
                            <span v-else-if="wordGroup.externalCode">#{{ wordGroup.externalCode }}</span>
                        </h5>
                    </div>

                    <h2 v-if="learningTraduction">
                        {{ capitalize(learningTraduction.text) }}
                    </h2>
                    <h2 v-else-if="traduction">
                        {{ capitalize(traduction.text) }}
                    </h2>
                    <h2 v-else>
                        {{ capitalize(wordGroup.text) }}
                    </h2>
                </div>
            </div>
        </div>
    </Card>
</template>
<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"
import { LANGS } from "@globals/constants"
import Image from "components/globals/Image"
import Popper from "components/globals/Popper"


export default {
    name: "SmallWordGroupCard",
    components: { Popper, Image, Icons, Card },
    props: {
        wordGroup: {
            required: true,
            type: Object
        },
    },
    created() {
        this.lang = LANGS.FR

        if (this.$store.getters.isStudentLogin) {
            this.lang = this.$store.state.auth.student.lang
        }
        if (this.$store.getters.isUserLogin) {
            this.lang = this.$store.state.auth.user.lang
        }
        if (this.$store.getters.isAnonymousLogin) {
            this.lang = this.$store.state.auth.anonymous.lang
        }
    },
    computed: {
        traduction() {
            if (!this.wordGroup) return null
            return this.wordGroup.traductions.find((t) => t.lang === this.lang)
        },
        learningLang() {
            if (this.$store.getters.isUserLogin) {
                if (this.$store.state.auth.user.teachingLangs.length > 0) {
                    return this.$store.state.auth.user.teachingLangs[0]
                }
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.state.auth.student.learningLang
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.state.auth.anonymous.learningLang
            }

            return LANGS.EN //default
        },
        learningTraduction() {
            if (!this.wordGroup) return null

            if (this.$store.getters.isUserLogin) {
                return this.$store.getters.getUserTeachingTraduction(this.wordGroup)
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentLearningTraduction(this.wordGroup)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousLearningTraduction(this.wordGroup)
            }
            return null
        }
    },
    methods: {
    }
}
</script>
