<template>
    <div class="w-full">
        <div
            ref="braintreeContainer"
            class="my-12 p-4 w-full border border-gray-200 rounded braintree-container-dropin" />

        <p
            class="error"
            v-if="hasError">
            {{ $t("validationErrors.paymentMethod") }}
        </p>

        <div class="flex flex-row justify-center items-center mt-4">
            <CustomButton :class="btnClass" @click.prevent="finish">
                {{ $t("form.btnFinish") }}
            </CustomButton>
        </div>
    </div>
</template>

<script>
import ajax from "config/ajax"
import { LANG_CODES } from "@globals/constants"
import CustomButton from "components/globals/CustomButton"
import { loadScript } from "helpers/helpers"

export default {
    name: "AddPaymentMethod",
    components: { CustomButton },
    emits: ["changed"],
    props: {
        btnClass:String
    },
    data() {
        return {
            hasError: false
        }
    },
    async mounted() {
        let [tokenRes, _] = await Promise.all([
            ajax.get("/user/payments/token"),
            loadScript("https://js.braintreegateway.com/web/dropin/1.33.4/js/dropin.min.js")
        ])

        this.token = tokenRes.token
        this.initBraintreeForm()
    },
    created() {
        this.instance = null
    },
    methods: {
        initBraintreeForm() {
            window.braintree.dropin.create(
                {
                    authorization: this.token,
                    container: this.$refs.braintreeContainer,
                    locale: LANG_CODES[this.$store.state.auth.user.lang.toUpperCase()].replace("-", "_"),
                    paypal: {
                        flow: "checkout"
                    }
                },
                (createErr, instance) => {
                    this.instance = instance
                }
            )
        },
        finish() {
            if (!this.instance) return

            //todo show error if there the payment method is not valid
            //ask for a valid payment method, if not show error
            this.instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                if (requestPaymentMethodErr) {
                    //todo send to sentry
                    this.hasError = true
                    return
                }

                //paymentMethod
                this.$emit("changed", payload) //this will tell the server that we change the payment method
            })
        }
    }
}
</script>
