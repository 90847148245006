<template>
    <div class="w-full">

        <loading-container :is-loading="loading">
            <div class="w-full" v-if="currentStep === 1">
                <h2 class="text-center mb-6">
                    {{ $t(`auth.schoolManager`) }}
                </h2>

                <register-user-form @created="onCreatedUser" role="school-manager" />
            </div>

            <div class="w-full" v-if="currentStep === 2">

                <h2 class="text-center mb-3 mt-24">
                    {{ $t("modal.paymentMethod.title") }}
                </h2>
                <p class="text-center mb-3 text-purple">
                    {{ $t("auth.onlyChargedWhenTeachers") }}
                </p>

                <add-payment-method btn-class="min-w-32" class="w-full"
                                    @changed="onChangedPaymentMethod" />
            </div>

            <p
                class="error"
                v-if="errors.global">
                {{ errors.global }}
            </p>

            <CustomButton
                v-if="currentStep === 1"
                class="mt-6"
                version="text"
                @click.prevent="goBack">
                {{ $t("global.goBack") }}
            </CustomButton>
        </loading-container>
    </div>
</template>

<script>
import RegisterUserForm from "views/auth/register/RegisterUserForm"
import CustomButton from "components/globals/CustomButton"
import AddPaymentMethod from "components/payment/AddPaymentMethod.vue"
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer.vue"


export default {
    name: "RegisterInstructionsSchoolManager",
    components: {
        LoadingContainer,
        AddPaymentMethod,
        CustomButton,
        RegisterUserForm
    },
    data() {
        return {
            loading: false,
            errors: {},
            currentStep: 1,
            currentUser: null
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        onCreatedUser(user) {
            this.currentUser = user
            this.nextStep()
        },
        async onChangedPaymentMethod(paymentMethod) {
            this.loading = true

            await this.$store.dispatch(types.GET_USER)//make sure we refresh user data with license, etc

            this.loading = false

            this.nextStep()
        },
        prevStep() {
            this.errors = {}
            if (this.currentStep === 0) return
            this.currentStep--
        },
        nextStep() {
            this.errors = {}

            //finish, go to dashboard
            if (this.currentStep === 2) {
                this.$router.push("/user/billing")//will land into view of students
                return
            }

            this.currentStep++
        }
    }
}
</script>
