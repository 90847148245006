<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="">{{ $t("form.edit") }} {{ student.name }}</h2>
        <!--  <h3 class="pt-4">
      {{ $t('settings.shortcode') }}: {{ student.shortcode }}
    </h3> -->
        <h3
            class="pt-4"
            v-if="student.teacherId">
            {{ $t("settings.teacher") }}: {{ student.teacher.name }}
        </h3>
        <h3
            class="pt-4"
            v-if="student.parentId">
            {{ $t("settings.parent") }}: {{ student.parent.name }}
        </h3>
        <form
            @submit.prevent="editStudent"
            class="mt-6">
            <Loader v-if="isLoading" />
            <StudentForm
                ref="studentForm"
                version="edit"
                :student="cloneStudent"
                :errors="errors"
                v-else />
            <div class="flex flex-row justify-center items-center my-4 w-full">
                <CustomButton type="submit">
                    {{ $t("form.btnEdit") }}
                </CustomButton>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import StudentForm from "components/forms/StudentForm"
import cloneDeep from "lodash-es/cloneDeep"
import Loader from "components/Loader"

export default {
    name: "EditStudentModal",
    emits: ["close"],
    components: { Loader, StudentForm, CustomButton, Modal },
    props: {
        student: Object,
        classroomId: Number
    },
    data() {
        return {
            // usernameAvailable: false,
            cloneStudent: cloneDeep(this.student),
            isLoading: false,
            errors: {}
        }
    },
    async created() {
        //make sure we dont update object directly
        if (this.classroom) {
            this.cloneStudent.classroomId = this.classroom.id
        }
    },
    computed: {
        classroom() {
            if (!this.classroomId) return null
            return this.$store.state.userData.classrooms.find((c) => c.id === this.classroomId)
        }
    },
    methods: {
        /*  async checkUsernameAvailable(){
        let res = await ajax.post('/user/students/available', {
          username: this.student.username
        })

        this.usernameAvailable = res.available

      },*/
        async editStudent() {
            this.errors = this.$refs.studentForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.isLoading = true

            this.$store
                .dispatch(types.USER_DATA.EDIT_STUDENT, this.cloneStudent)
                .then((res) => {
                    this.isLoading = false
                    this.$emit("close")

                    //if we changed class
                    if (this.classroom) {
                        if (this.cloneStudent.classroomId !== this.student.classroomId) {
                            this.$store.dispatch(types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID, this.classroom.uuid)
                        }
                    }
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
