<template>
    <div class="relative">
        <img
            class="w-full h-full block object-center object-contain"
            ref="map"
            :src="currentLevel.bg.layers.underPlayer" />
        <img
            class="w-full h-full block object-center object-contain absolute inset-0"
            ref="mapOver"
            :src="currentLevel.bg.layers.overPlayer" />

        <div class="w-full h-full absolute inset-0 z-3">
            <div
                class="flex flex-row justify-start items-center flex-nowrap"
                v-for="(zone, yIdx) in currentLevel.zones"
                :key="yIdx">
                <div
                    v-for="(item, xIdx) in zone"
                    :key="xIdx"
                    @click="onClickZone(yIdx, xIdx)"
                    :style="`height:${itemHeight}px`"
                    class="border w-full h-full border-black flex flex-col justify-center items-center">
                    <span style="font-size: 11px">
                        {{ item ? item.substring(0, 2) : "" }}
                    </span>
                </div>
            </div>
        </div>
        Y:{{ currentMapZoneX }} - X:{{ currentMapZoneY }}
    </div>
</template>
<script>
import { LEVELS } from "@globals/data/3dGame"

export default {
    name: "MapZoneViewer",
    components: {},
    data() {
        return {
            currentMapZoneX: 0,
            currentMapZoneY: 0,
            currentMapZoneXPercent: 0,
            currentMapZoneYPercent: 0,

            currentLevel: LEVELS["level1"], //todo,

            itemHeight: 0
        }
    },
    created() {},
    mounted() {
        this.$refs.map.onload = () => {
            this.setItemHeight()
        }

        window.addEventListener("resize", () => {
            this.setItemHeight()
        })
    },
    watch: {},
    computed: {
        currentMapZone() {
            const zones = this.currentLevel.zones
            const currentX = Math.round((this.currentMapZoneXPercent / 100) * zones[0].length)
            const currentY = Math.round((this.currentMapZoneYPercent / 100) * zones.length)
            return zones[currentY][currentX]
        }
    },
    methods: {
        setItemHeight() {
            if (!this.$refs.map) return
            this.itemHeight = this.$refs.map.clientHeight / this.currentLevel.zones.length
        },
        onClickZone(y, x) {
            this.currentMapZoneY = y
            this.currentMapZoneX = x
        },
        setMapZone() {
            //  this.$refs.map.style.width = `${this.$refs.map.offsetHeight}px`
        }
    }
}
</script>
<style lang="scss">
.map-game {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
</style>
