<template>
    <Card>
        <div class="flex flex-row justify-start items-stretch p-3 space-x-3">
            <img
                v-if="imageUrl"
                class="h-28 min-w-32 w-32 object-cover object-center rounded overflow-hidden"
                :alt="word.text"
                :src="imageUrl" />
            <div
                class="py-2"
                :class="{
                    'flex flex-col justify-between items-start': imageUrl,
                    'w-full flex flex-row justify-between items-center': !imageUrl
                }">
                <div>
                    <h3 v-if="currentWordText">
                        {{ currentWordText }}
                    </h3>
                    <div
                        class="pt-1"
                        v-if="questionIsShow">
                        <p class="text-purple -smallest">
                            {{ $t("game.wordOfXLetters", { lettersCount: currentWordText.length }) }}
                        </p>
                        <p
                            class="text-purple -smallest"
                            v-for="(type) in word.type"
                            :key="type">
                            {{ $t(`global.wordTypes.${type}`) }}
                        </p>
                    </div>
                </div>
                <div
                    class="flex flex-row justify-start items-center space-x-2"
                    :class="{
                        'mt-2': imageUrl
                    }">
                    <div
                        class="cursor-pointer w-10 h-10 rounded-full border-2 border-gray-200 p-1"
                        @click.prevent="toggleQuestion"
                        :class="`${questionIsShow ? 'bg-gray-200' : 'bg-white'}`">
                        <Popper
                            :offset-y="10"
                            class="w-full h-full flex flex-row justify-center items-center"
                            :content="$t(`global.help.seeMoreInfo`)">
                            <span
                                class="text-18 font-medium"
                                :class="`${questionIsShow ? 'text-white' : 'text-gray-200'}`">
                                ?
                            </span>
                        </Popper>
                    </div>
                    <div
                        class="cursor-pointer w-10 h-10 rounded-full border-2 border-gray-200 p-1"
                        @click.prevent="hear"
                        :class="`${soundIsPlaying ? 'bg-gray-200' : 'bg-white'}`"
                        v-if="soundTraduction && soundTraduction.soundPath">
                        <Popper
                            :offset-y="10"
                            class="w-full h-full block"
                            :content="$t(`global.help.sound`)">
                            <Icons
                                icon="game-type-hear"
                                class="w-full h-auto block"
                                :class="`${soundIsPlaying ? 'text-white' : 'text-gray-200'}`" />
                        </Popper>
                    </div>

                    <!-- Only show switch button lang if lang is not the same -->
                    <button
                        class="appearance-none cursor-pointer w-10 h-10 rounded-full border-2 border-gray-200 p-1"
                        v-if="learningTraduction && traduction && traduction.lang !== learningTraduction.lang"
                        :class="{
                            'bg-white text-gray-200': !showWordInOriginalLang,
                            'bg-gray-200 text-white': showWordInOriginalLang
                        }"
                        @click.prevent="switchLang">
                        <Popper
                            :offset-y="10"
                            class="w-full h-full block"
                            :content="$t(`global.help.switchLang`)">
                            <Icons
                                icon="game-type-translate"
                                class="w-full h-auto block" />
                        </Popper>
                    </button>
                </div>
            </div>
            <div
                class="hidden"
                v-if="soundTraduction && soundTraduction.soundPath">
                <audio
                    ref="audioTag"
                    class="hidden"
                    preload="none">
                    <source
                        :src="soundTraduction.soundUrl"
                        :type="`${soundTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                </audio>
            </div>
        </div>
    </Card>
</template>
<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"
import Popper from "components/globals/Popper"

export default {
    name: "WordCard",
    components: { Popper, Icons, Card },
    emits: ["mounted"],
    props: {
        word: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            showWordInOriginalLang: false,
            soundIsPlaying: false,
            questionIsShow: false
        }
    },
    mounted() {
        this.$emit("mounted")
    },
    watch: {
        //make sure we set listener when we have sound data
        soundTraduction: {
            immediate: true,
            handler: function() {
                this.$nextTick(() => {
                    if (this.$refs.audioTag) {
                        this.$refs.audioTag.addEventListener("play", this.isPlaying)
                        this.$refs.audioTag.addEventListener("ended", this.isNotPlaying)
                    }
                })
            }
        }
    },
    beforeUnmount() {
        if (this.$refs.audioTag) {
            this.$refs.audioTag.removeEventListener("play", this.isPlaying)
            this.$refs.audioTag.removeEventListener("ended", this.isNotPlaying)
        }
    },
    computed: {
        imageUrl() {
            if (!this.word) return null
            if (this.traduction && this.traduction.imagePath) {
                return this.traduction.imageUrl
            }
            return this.word.imageUrl
        },
        traduction() {
            if (!this.word) return null

            if (this.$store.getters.isUserLogin) {
                return this.$store.getters.getUserTraduction(this.word)
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentTraduction(this.word)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousTraduction(this.word)
            }

            return null
        },
        learningTraduction() {
            if (!this.word) return null

            if (this.$store.getters.isUserLogin) {
                return this.$store.getters.getUserTeachingTraduction(this.word)
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentLearningTraduction(this.word)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousLearningTraduction(this.word)
            }
            return null
        },
        currentWordText() {
            if (this.showWordInOriginalLang && this.traduction) {
                return this.traduction.text
            } else if (this.learningTraduction) {
                return this.learningTraduction.text
            } else if (this.traduction) {
                return this.traduction.text
            }
            return this.word ? this.word.text : ""
        },
        soundTraduction() {
            if (this.learningTraduction) return this.learningTraduction
            if (this.traduction) return this.traduction
            return null
        }
    },
    methods: {
        toggleQuestion() {
            this.questionIsShow = !this.questionIsShow
        },
        async hear() {
            //load then play
            if (!this.$refs.audioTag || this.soundIsPlaying) return

            try {
                await this.$refs.audioTag.play()
                this.$refs.audioTag.playbackRate = 0.8
            } catch (e) {
            }
        },
        isPlaying() {
            this.soundIsPlaying = true
        },
        isNotPlaying() {
            this.soundIsPlaying = false
        },
        switchLang() {
            this.showWordInOriginalLang = !this.showWordInOriginalLang
        }
    }
}
</script>
