<template>
    <div
        class="p-1.5"
        :class="[nodeColor, rounded ? 'rounded-full' : 'rounded-xl']">
        <div
            class="w-full h-full"
            :class="[insideNodeColor, rounded ? 'rounded-full' : 'rounded']">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {
        color: {
            default: "white",
            type: String
        },
        rounded: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        nodeColor() {
            switch (this.color) {
                case "white":
                    return "shadow-xl bg-white"
                case "gray":
                    return "bg-gray-100"
                case "purple":
                    return "shadow-xl bg-gradient-purple"
                case "yellow":
                    return "shadow-xl bg-gradient-red"
                case "yellow-20":
                    return "shadow-xl bg-white"
            }

            return ""
        },
        insideNodeColor() {
            switch (this.color) {
                case "white":
                    return "shadow-xl bg-white"
                case "purple":
                    return "shadow-xl bg-gradient-purple-inside"
                case "gray":
                    return ""
                case "yellow":
                    return "shadow-xl bg-gradient-red-inside"
                case "yellow-20":
                    return "shadow-xl bg-yellow-20"
            }

            return ""
        }
    }
}
</script>
