<template>
    <h1 class="text-center mb-6">
        {{ $t("auth.registerIntro") }}
    </h1>

    <router-view />

    <CustomButton
        class="mt-6"
        version="text"
        v-if="showGoBack"
        @click.prevent="goBack">
        {{ $t("global.goBack") }}
    </CustomButton>
</template>

<script>
import CustomButton from "components/globals/CustomButton"

export default {
    name: "Register",
    components: { CustomButton },
    computed:{
        showGoBack(){
            return this.$route.name === 'auth-register-index'
        }
    }
}
</script>
