<template>
    <div class="h-full flex flex-col justify-between items-center space-y-6">
        <div class="w-full h-full flex flex-row justify-between items-center space-x-6">
            <div class="w-full md:w-1/3 h-full rounded">
                <div
                    v-if="word"
                    class="w-full max-h-full h-full bg-white bg-contain bg-no-repeat bg-center border-6 rounded border-white"
                    :style="`background-image: url(${word.imageUrl})`"></div>
            </div>
            <div>
                <button
                    type="button"
                    class="appearance-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-1 cursor-pointer"
                    @click.prevent.stop="playSound">
                    <Card
                        rounded
                        color="yellow">
                        <div class="p-6">
                            <Icons
                                icon="game-type-hear"
                                class="w-8 h-8 md:w-12 md:h-12 block text-white" />
                        </div>
                    </Card>
                </button>
                <audio
                    ref="audioTag"
                    class="hidden"
                    autoplay="false"
                    autostart="0">
                    <source
                        v-if="gameTraduction"
                        :src="gameTraduction.soundUrl"
                        :type="`${gameTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                </audio>
            </div>
            <div
                v-if="currentSection"
                class="w-full md:w-2/3 h-full rounded"
                :class="{
                    'bg-red': currentSection.isWrongAnswer,
                    'bg-green': currentSection.isCorrectAnswer,
                    'bg-white': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                }">
                <div class="w-full h-full flex flex-col justify-center items-center">
                    <div class="w-full h-24 flex flex-row justify-center items-center space-x-3">
                        <button
                            v-for="letter in currentAnswerLetters"
                            :key="letter.id"
                            type="button"
                            class="h-24 appearance-none cursor-pointer rounded flex flex-col justify-center items-center text-center text-white"
                            :class="{
                                h3: letter.isSpace,
                                h1: !letter.isSpace,
                                'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                                'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                            }"
                            @click.prevent="removeLetter(letter.id)">
                            <template v-if="!letter.isSpace">
                                {{ letter.letter }}
                            </template>
                            <template v-else> ESPACE</template>
                        </button>
                    </div>
                    <div
                        class="w-3/4 mx-auto border-dashed border-t-2 h-2"
                        :class="{
                            'border-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'border-black': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }"></div>
                </div>
            </div>
        </div>
        <div class="w-full h-1/2 pt-6 md:pt-0">
            <div class="flex flex-row justify-center items-center space-x-3 h-full">
                <button
                    v-for="letter in letters"
                    :key="letter.id"
                    type="button"
                    class="w-full h-full appearance-none cursor-pointer rounded flex flex-col justify-center items-center text-center bg-white text-purple"
                    :class="{
                        'opacity-50': currentAnswerLettersIds.includes(letter.id)
                    }"
                    @click.prevent="toggleLetter(letter.id)">
                    <span
                        class="h1"
                        v-if="!letter.isSpace">
                        {{ letter.letter }}
                    </span>
                    <span
                        class="h3"
                        v-else>
                        ESPACE
                    </span>
                </button>
            </div>
        </div>

        <div class="w-full flex flex-row justify-center items-center space-x-6">
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center bg-white text-purple h3"
                @click.prevent="skip">
                {{ $t("game.skipQuestion") }}
            </button>
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center h3"
                :class="{
                    'bg-gray-400 text-gray-200': lettersLeft.length > 0,
                    'bg-green text-white': lettersLeft.length === 0
                }"
                @click.prevent="sendAnswer">
                {{ $t("game.validate") }}
            </button>
        </div>
    </div>
</template>
<script>
import { FLAGS } from "services/countryFlags"
import shuffle from "lodash-es/shuffle"
import { v4 } from "uuid"
import { removeAccents } from "@globals/functions"
import Card from "components/cards/Card.vue"
import Icons from "components/globals/Icons.vue"

export default {
    name: "GameOrder",
    components: { Icons, Card },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            FLAGS,

            currentAnswerLettersIds: [],
            letters: []
        }
    },
    watch: {
        currentSection(nextValue, prevValue) {
            //reset to empty arr when we changed section
            if (nextValue && prevValue) {
                if (prevValue.sectionIdx !== nextValue.sectionIdx) {
                    this.currentAnswerLettersIds = []
                }
            }

            if (nextValue.words.length === prevValue.words.length && !nextValue.isReal) return

            this.currentAnswerLettersIds = []
            this.setLetters()
            this.playSound()
        }
    },
    created() {
        this.setLetters()
    },
    mounted() {
        //play on init
        this.playSound()
    },
    computed: {
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        gameTraduction() {
            if (!this.word || !this.game) return null
            return this.word.traductions.find((t) => t.lang === this.game.lang)
        },
        currentAnswerLetters() {
            return this.currentAnswerLettersIds.reduce((acc, id) => {
                let letter = this.letters.find((l) => l.id === id)
                if (!letter) return acc
                acc.push(letter)
                return acc
            }, [])
        },
        currentAnswerText() {
            return this.currentAnswerLettersIds.reduce((acc, id) => {
                let letter = this.letters.find((l) => l.id === id)
                if (!letter) return acc
                acc += letter.letter
                return acc
            }, "")
        },
        lettersLeft() {
            return this.letters.reduce((acc, letter) => {
                let isInAnswer = this.currentAnswerLettersIds.some((id) => id === letter.id)
                if (isInAnswer) return acc
                acc.push(letter)
                return acc
            }, [])
        }
    },
    methods: {
        async playSound() {
            if (!this.$refs.audioTag) return
            this.$refs.audioTag.autoplay = false
            try {
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
                this.$refs.audioTag.playbackRate = 0.8
            } catch (e) {}
        },
        setLetters() {
            if (!this.gameTraduction) return []
            let texts = this.gameTraduction.text.split("")

            this.letters = shuffle(
                texts.map((letter, index) => {
                    let isSpace = letter === " "
                    return {
                        id: v4(),
                        letter: letter,
                        realIndex: index,
                        isSpace: isSpace
                    }
                })
            )
        },
        removeLetter(id) {
            //remove by index
            let idx = this.currentAnswerLettersIds.indexOf(id)
            this.currentAnswerLettersIds.splice(idx, 1)
        },
        toggleLetter(id) {
            if (this.currentAnswerLettersIds.includes(id)) {
                this.removeLetter(id)
            } else {
                this.addLetter(id)
            }
        },
        addLetter(id) {
            this.currentAnswerLettersIds.push(id)
        },
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        },
        sendAnswer() {
            if (!this.currentSection.isReal) return
            if (this.lettersLeft.length > 0) return
            let isCorrectAnswer = removeAccents(this.gameTraduction.text) === removeAccents(this.currentAnswerText)
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: isCorrectAnswer ? this.word.uuid : null, //pass null for wrong answer
                inputText: this.currentAnswerText
            })
        },
        skip() {
            if (!this.currentSection.isReal) return
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: null,
                skipQuestion: true
            })
        }
    }
}
</script>
