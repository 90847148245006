<template>
    <UserHeader class="mb-6">
        <div>
            <h1>{{ $t("play.title") }}</h1>
            <h2 v-if="liveGames.length === 0" class="pt-3">
                {{ $t("play.createLiveGame") }}
            </h2>
        </div>


        <template
            v-slot:end
            v-if="$store.state.auth.user.isTeacher">
            <ClassroomDropdown />
        </template>
    </UserHeader>

    <div
        v-if="liveGames.length > 0"
        class="w-full pb-12">
        <h2 class="pb-6">
            {{ $t("play.joinLiveGame") }}
        </h2>
        <div class="grid grid-cols-1 gap-6">
            <LiveGameCard
                v-for="game in liveGames"
                :key="game.id"
                :game="game"
                @join="joinLiveBattle(game)"
                @remove="removeLiveBattle(game)" />
        </div>
    </div>

    <div class="w-full">
        <h2 class="pb-6" v-if="liveGames.length > 0">
            {{ $t("play.createLiveGame") }}
        </h2>
        <div
            class="w-full"
            v-if="$store.state.auth.user.isTeacher">
            <TabbedButtons
                size="small"
                @click="onClickType"
                container-class="flex flex-row justify-between items-center space-x-3 w-full"
                :value="selectedWordGroupType"
                value-key="key"
                :options="types">

                <form
                    @submit.prevent="searchWordGroups"
                    class="inline-block pr-1.5">
                    <div class="c__input">
                        <div class="relative">
                            <input
                                :placeholder="$t('list.searchWordGroupsPlaceholder')"
                                type="text"
                                v-model="searchValue"
                                name="search"
                                class="pl-8"
                                @keyup.enter.prevent.stop="searchWordGroups" />
                            <Icons
                                icon="search"
                                @click.prevent.stop="searchWordGroups"
                                class="min-w-5 w-5 h-5 block cursor-pointer absolute top-1/2 -translate-y-1/2 transform left-2" />
                        </div>
                    </div>
                </form>
            </TabbedButtons>
        </div>
    </div>
    <div class="w-full pt-3" v-if="selectedWordGroupType === WORD_GROUP_TYPES.LIST">
        <div class="w-full">
            <TabbedButtons
                size="small"
                @click="onClickWordGroupGrade"
                :value="selectedWordGroupGrade"
                :options="wordGroupGrades" />
        </div>
    </div>
    <!--   <div
        class="flex flex-row justify-start items-center space-x-3 mb-6 w-full"
        v-if="$store.state.auth.user.isTeacher">
        <BadgeButton
            v-for="type in types"
            :key="type.key"
            @click.prevent="onClickType(type)"
            :icon="type.icon"
            :label="type.label"
            :active="selectedWordGroupType === type.key" />
    </div> -->

    <div class="relative w-full">
        <div ref="cardSize" class="w-full absolute" style="z-index: -1;">
            <div class="w-full h-full" v-if="wordGroupsForScroller.length > 0">
                <WordGroupCard
                    @mounted="setItemSize"
                    class="w-full opacity-0 pointer-events-none invisible"
                    :word-group="wordGroupsForScroller[0].wordGroup"
                />
            </div>
        </div>
    </div>
    <template v-if="filteredWordGroups.length > 0">
        <RecycleScroller
            :items="wordGroupsForScroller"
            class="w-full flex-1 py-6 virtual-scroller"
            :item-size="itemSize"
            ref="scroller"
            key-field="keyForScroller"
            type-field="typeForScroller"
            v-slot="{ item }"
        >
            <div class="w-full h-full">
                <WordGroupCard
                    class="cursor-pointer"
                    :word-group="item.wordGroup"
                    version="live"
                    @create="createGame"
                    @click.prevent="goToWordGroup(item.wordGroup.uuid)" />
            </div>
        </RecycleScroller>
    </template>
    <div v-else class="pt-6">
        <p class="-small text-purple">
            {{ $t("play.noCustomList") }}
        </p>
        <div class="inline-block">
            <CustomButton
                v-if="currentClassroom"
                version="text"
                :to="`/user/students?classroomUuid=${currentClassroom.uuid}`">
                {{ $t("play.ensureCustomListIsConnected") }}
            </CustomButton>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import WordGroupCard from "components/cards/WordGroupCard"
import ajax from "config/ajax"
import LiveGameCard from "components/cards/LiveGameCard"
import ClassroomDropdown from "components/globals/ClassroomDropdown"
import UserHeader from "components/globals/UserHeader"
import { WORD_GROUP_TYPES } from "@globals/constants"
import CustomButton from "components/globals/CustomButton.vue"
import TabbedButtons from "components/globals/TabbedButtons.vue"
import { RecycleScroller } from "vue-virtual-scroller"
import throttle from "lodash-es/throttle"
import Icons from "components/globals/Icons.vue"

export default {
    name: "UserPlay",
    components: {
        Icons,
        RecycleScroller,
        TabbedButtons,
        CustomButton,
        UserHeader,
        ClassroomDropdown,
        LiveGameCard,
        WordGroupCard
    },
    data() {
        return {
            WORD_GROUP_TYPES,
            liveGames: [],
            switchCount: 0,
            searchValue: "",

            itemSize: 120,//default

            selectedWordGroupType: null,
            selectedWordGroupGrade: 1,//default to 1 so we dont show all

            types: [
                {
                    key: WORD_GROUP_TYPES.CUSTOM,
                    icon: "list",
                    label: this.$t("play.typeCustom")
                },
                {
                    key: WORD_GROUP_TYPES.LIST,
                    icon: "list",
                    label: this.$t("play.typeList")
                }
            ]
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize, { passive: true })
        this.setItemSize()
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.onResize)
    },
    async created() {
        if (this.$store.state.auth.user.isSchoolManager) {
            this.$router.replace("/user/billing")
            return
        }

        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)
        this.$store.dispatch(types.USER_DATA.GET_CLASSROOMS)
        this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS)

        if (this.$store.state.auth.user.isTeacher) {
            this.selectedWordGroupType = WORD_GROUP_TYPES.CUSTOM
        }
        if (this.$store.state.auth.user.isParent) {
            this.selectedWordGroupType = WORD_GROUP_TYPES.LIST
        }

        let res = await ajax.get(`/user/live-games`)
        this.liveGames = res.games
    },
    watch: {
        currentClassroom(nextValue) {
            if (!nextValue) {
                this.selectedWordGroupGrade = 1
                return
            }
            this.selectedWordGroupGrade = nextValue.grade ? nextValue.grade : 1
        }
    },
    computed: {
        currentClassroom() {
            return (
                this.$store.state.userData.classrooms.find(
                    (c) => c.uuid === this.$store.state.userData.currentClassroomUuid
                ) || null
            )
        },
        currentListGroup() {
            if (!this.currentClassroom) return null
            return (
                this.$store.state.userData.listGroups.find((lG) => lG.id === this.currentClassroom.listGroupId) || null
            )
        },
        filteredWordGroups() {
            const lowerSearchValue = this.searchValue.toLowerCase()
            if (this.selectedWordGroupType === WORD_GROUP_TYPES.CUSTOM) {
                return this.$store.state.userData.wordGroups.filter(
                    (wG) => {
                        if (wG.type !== this.selectedWordGroupType) {
                            return false
                        }

                        if (this.currentListGroup) {
                            if (this.currentListGroup.id !== wG.listGroupId) {
                                return false
                            }
                        }

                        if (this.searchValue){
                            if (!wG.text.toLowerCase().includes(lowerSearchValue)) {
                                return false
                            }
                        }
                        return true
                    }
                )
            }

            //filter by group and if classroom, filter this grade
            return this.$store.state.userData.wordGroups.filter((wG) => {
                if (wG.type !== this.selectedWordGroupType) {
                    return false
                }

                if (wG.grade !== this.selectedWordGroupGrade) {
                    return false
                }

                if (this.searchValue){
                    if (!wG.text.toLowerCase().includes(lowerSearchValue)) {
                        return false
                    }
                }
                return true
            })
        },
        wordGroupsForScroller() {
            return this.filteredWordGroups.map(wG => ({
                wordGroup: wG,
                keyForScroller: wG.id,
                typeForScroller: "wordGroup"
            }))
        },
        wordGroupGrades() {
            return this.$store.state.userData.wordGroups.reduce((acc, wordGroup) => {
                if (!wordGroup.grade) return acc

                if (acc.some(w => w.value === wordGroup.grade)) {
                    return acc
                }
                let suffix = wordGroup.grade === 1 ? "re" : "e"
                acc.push({
                    label: `${wordGroup.grade}${suffix} ${this.$t("list.year")}`,
                    value: wordGroup.grade
                })
                return acc
            }, [])
        }
    },
    methods: {
        onResize: throttle(function() {
            this.setItemSize()
        }, 250, {
            leading: true,
            trailing: true
        }),
        setItemSize() {
            if (this.$refs.cardSize && this.$refs.cardSize.clientHeight > 0) {
                this.itemSize = this.$refs.cardSize.clientHeight + 18//18px padding
            }

            if (this.$refs.scroller) {
                this.$refs.scroller?.updateVisibleItems(true)
            }
        },
        searchWordGroups() {

        },
        onClickType(typeKey) {
            this.switchCount++
            this.selectedWordGroupType = typeKey
        },
        onClickWordGroupGrade(grade) {
            this.selectedWordGroupGrade = grade
        },
        joinLiveBattle(game) {
            this.$router.replace(`/user/live/${game.uuid}/${game.gameType.slug}`)
        },
        removeLiveBattle(game) {
            let idx = this.liveGames.findIndex((g) => g.id === game.id)
            if (idx === -1) return
            this.liveGames.splice(idx, 1)
        },
        goToWordGroup(uuid) {
            this.$router.push(`/user/play/${uuid}`)
        },
        async createGame({ gameTypeSlug, wordGroupUuid }) {
            let res = await ajax.post("/user/live-games", {
                classroomUuid: this.$store.state.userData.currentClassroomUuid,
                wordGroupUuid: wordGroupUuid,
                gameTypeSlug: gameTypeSlug
            })

            let { success, game } = res

            if (!success) {
                //todo handle error
                return
            }

            this.$router.replace(`/user/live/${game.uuid}/${gameTypeSlug}`)
        }
    }
}
</script>
