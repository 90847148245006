<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <div class="flex flex-col justify-center items-center w-full">
            <h2>
                {{ $t("modal.joinGame.title") }}
            </h2>
            <p class="my-4">
                {{ $t("modal.joinGame.description") }}
            </p>
            <form
                @submit.prevent="joinGame"
                class="flex flex-col justify-center items-center w-full">
                <div class="c__input w-auto">
                    <label>
                        {{ $t("form.gameCode") }}
                    </label>
                    <GameCodeInput
                        @change="onGameCodeChange"
                        @complete="joinGame" />

                    <p
                        class="error"
                        v-if="errors.code">
                        {{ errors.code }}
                    </p>
                </div>

                <p
                    class="error"
                    v-if="errors.global">
                    {{ errors.global }}
                </p>

                <div class="flex flex-row justify-center items-center mt-6">
                    <CustomButton type="submit">
                        {{ $t("play.btnJoinGame") }}
                    </CustomButton>
                </div>
            </form>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import ajax from "config/ajax"
import GameCodeInput from "components/game/GameCodeInput"

export default {
    components: { GameCodeInput, CustomButton, Modal },
    emits: ["close"],
    name: "JoinGameByCodeModal",
    props: {},
    data() {
        return {
            code: "",
            errors: {}
        }
    },
    created() {},
    methods: {
        onGameCodeChange(code) {
            this.code = code
        },
        async joinGame() {
            let errors = {}
            if (!this.code || this.code.length !== 6) {
                errors.code = this.$t("validationErrors.gameCode")
            }
            this.errors = errors
            if (Object.keys(errors).length > 0) {
                return
            }

            let res
            try {
                res = await ajax.post(`/student/live-games/join/${this.code}`)
            } catch (e) {
                this.errors.global = this.$t("modal.joinGame.noGameFound")
                return
            }

            const { success, game } = res

            if (!success) {
                this.errors.global = this.$t("modal.joinGame.noGameFound")
                return
            }

            this.$router.push(`/student/live/${game.uuid}/${game.gameType.slug}`)
        }
    }
}
</script>
