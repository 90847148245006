import * as types from "store/types"
import ajax from "config/ajax"
import sortBy from "lodash-es/sortBy"
import orderBy from "lodash-es/orderBy"
import { addOrReplaceArr, deleteArr } from "helpers/helpers"
import cloneDeep from "lodash-es/cloneDeep"
import i18n from "config/i18n"
import { GAME_TYPES } from "@globals/constants"

const state = {
    games: [],
    wordGroups: [],
    playableWordGroupIds: {
        [GAME_TYPES.CONTEST]: []
    },
    words: [],
    myProducts: [],
    allProducts: [],
    boughtProducts: [],
    leagues: [],
    friends: [],
    classrooms: [],
    notifications: [],
    leagueInvitations: [],

    standings: {
        general: [],
        school: [],
        leagues: []
    },

    lastHourScoreboard: [],

    statsWords: null,

    global: {
        productCategories: [],
        gameTypes: [],
        currentWordGroups: [],
        lastUnlockedProduct: null
    },

    isFetching: {
        productsById: {}
    },

    hasFetch: {
        leagueInvitations: false,
        classrooms: false,
        lastHourScoreboard: false,
        leagues: false,
        statsWords: false,
        standings: false,
        wordGroups: false,
        myProducts: false,
        boughtProducts: false,
        usedProducts: false
    }
}

const originalState = cloneDeep(state)

// getters, make function easy to access by vue
const getters = {
    productCategoriesOptions: (state) => {
        return [
            {
                value: null,
                label: i18n.global.t(`global.productCategories.all`)
            },
            ...state.global.productCategories.map((pc) => {
                return {
                    value: pc.id,
                    label: i18n.global.t(`global.productCategories.${pc.slug}`)
                }
            })
        ]
    },
    sortedMyProducts: (state) => {
        return sortBy(state.myProducts, "unlockAtLevel")
    },
    sortedWordGroups: (state) => {
        return sortBy(state.wordGroups, "difficulty")
    },
    getStudentTraduction: (state, getters, rootState) => (item) => {
        return item.traductions.find((t) => t.lang === rootState.auth.student.lang) || null
    },
    getStudentLearningTraduction: (state, getters, rootState) => (item) => {
        return item.traductions.find((t) => t.lang === rootState.auth.student.learningLang) || null
    }
    /* getWordGroupDifficulty: (state, getters) => weekNb => {
         if (state.wordGroups.length === 0) return 0
         let max = getters.sortedWordGroups[getters.sortedWordGroups.length - 1]
         if (!max) return 0
         let newMax = 5//between 1 and 5
         let newMin = 1
         let ratio = max.weekNb > 0 ? weekNb / max.weekNb : 0//dont divide by 0
         let value = parseInt(ratio * newMax, 10)
         return clamp(value, newMin, newMax)
     } */
}

// actions
const actions = {
    [types.STUDENT_DATA.JOIN_LEAGUE](store, uuid) {
        return ajax.post(`/student/leagues/${uuid}/join`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_LEAGUE_INVITATION_BY_UUID, res.leagueInvitation)
            return Promise.resolve(res.leagueInvitation)
        })
    },
    [types.STUDENT_DATA.GET_LEAGUE_INVITATIONS](store, payload) {
        if (store.state.hasFetch.leagueInvitations) {
            return Promise.resolve(store.state.leagueInvitations)
        }
        return ajax.get(`/student/league-invitations`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_LEAGUE_INVITATIONS, res.leagueInvitations)
            return Promise.resolve(res.leagueInvitations)
        })
    },
    [types.STUDENT_DATA.GET_LEAGUE_INVITATION_BY_UUID](store, uuid) {
        return ajax.get(`/student/league-invitations/uuid/${uuid}`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_LEAGUE_INVITATION_BY_UUID, res.leagueInvitation)
            return Promise.resolve(res.leagueInvitation)
        })
    },
    [types.STUDENT_DATA.GET_CLASSROOMS](store, payload) {
        if (store.state.hasFetch.classrooms) {
            return Promise.resolve(store.state.classrooms)
        }
        return ajax.get(`/student/classrooms`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_CLASSROOMS, res.classrooms)
            return Promise.resolve(res.classrooms)
        })
    },
    [types.STUDENT_DATA.GET_STATS_WORDS](store, payload) {
        if (store.state.hasFetch.statsWords) {
            return Promise.resolve(store.state.statsWords)
        }
        return ajax.get(`/student/stats/words`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_STATS_WORDS, res.statsWords)
            return Promise.resolve(res.statsWords)
        })
    },
    [types.STUDENT_DATA.GET_STANDINGS](store, payload) {
        if (store.state.hasFetch.standings) {
            return Promise.resolve(store.state.standings)
        }
        return ajax.get(`/student/standings`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_STANDINGS, res.standings)
            return Promise.resolve(res.standings)
        })
    },
    [types.STUDENT_DATA.GET_FRIENDS](store, payload) {
        return ajax.get(`/student/students/friends`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_FRIENDS, res.friends)
            return Promise.resolve(res.friends)
        })
    },
    [types.STUDENT_DATA.GET_NOTIFICATIONS](store, payload) {
        return ajax.get(`/student/notifications`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_NOTIFICATIONS, res.notifications)
            return Promise.resolve(res.notifications)
        })
    },
    [types.STUDENT_DATA.SEEN_NOTIFICATION_BY_UUID](store, uuid) {
        return ajax.post(`/student/notifications/${uuid}/seen`).then((res) => {
            store.commit(types.STUDENT_DATA.SEEN_NOTIFICATION_BY_UUID, res.notification)
            return Promise.resolve(res.notification)
        })
    },
    [types.STUDENT_DATA.REMOVE_NOTIFICATION_BY_UUID](store, uuid) {
        return ajax.delete(`/student/notifications/${uuid}`).then((res) => {
            store.commit(types.STUDENT_DATA.REMOVE_NOTIFICATION_BY_UUID, uuid)
            return Promise.resolve(res)
        })
    },
    [types.STUDENT_DATA.REMOVE_NOTIFICATION](store, uuid) {
        return ajax.delete(`/student/notifications/${uuid}`).then((res) => {
            store.commit(types.STUDENT_DATA.REMOVE_NOTIFICATION, uuid)
            return Promise.resolve(res)
        })
    },
    [types.STUDENT_DATA.CREATE_LEAGUE](store, payload) {
        return ajax.post(`/student/leagues`, payload).then((res) => {
            store.commit(types.STUDENT_DATA.CREATE_LEAGUE, res.league)
            return Promise.resolve(res.league)
        })
    },
    [types.STUDENT_DATA.UPDATE_LEAGUE](store, payload) {
        return ajax.put(`/student/leagues/${payload.uuid}`, payload).then((res) => {
            store.commit(types.STUDENT_DATA.UPDATE_LEAGUE, res.league)
            return Promise.resolve(res.league)
        })
    },
    [types.STUDENT_DATA.DELETE_LEAGUE](store, payload) {
        return ajax.delete(`/student/leagues/${payload.uuid}`, payload).then((res) => {
            store.commit(types.STUDENT_DATA.DELETE_LEAGUE, payload.id)
            return Promise.resolve(payload.id)
        })
    },
    [types.STUDENT_DATA.GET_LEAGUES](store, payload) {
        if (store.state.hasFetch.leagues) {
            return Promise.resolve(store.state.leagues)
        }
        return ajax.get(`/student/leagues`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_LEAGUES, res.leagues)
            return Promise.resolve(res.leagues)
        })
    },
    [types.STUDENT_DATA.USE_PRODUCT_BY_UUID](store, uuid) {
        return ajax.post(`/student/products/${uuid}/use`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_PRODUCT_BY_ID, res.product)
            store.commit(types.UPDATE_STUDENT, res.student)
            return Promise.resolve(res.product)
        })
    },
    [types.STUDENT_DATA.BUY_PRODUCT_BY_UUID](store, uuid) {
        return ajax.post(`/student/products/${uuid}/buy`).then((res) => {
            store.commit(types.STUDENT_DATA.BUY_PRODUCT_BY_UUID, res.product)
            store.commit(types.UPDATE_STUDENT, res.student)
            return Promise.resolve(res.product)
        })
    },
    [types.STUDENT_DATA.GET_BOUGHT_PRODUCTS](store, payload) {
        if (store.state.hasFetch.boughtProducts) {
            return Promise.resolve(store.state.boughtProducts)
        }
        return ajax.get("/student/products/bought").then((res) => {
            store.commit(types.STUDENT_DATA.GET_BOUGHT_PRODUCTS, res.products)
            return Promise.resolve(res.products)
        })
    },
    [types.STUDENT_DATA.GET_USED_PRODUCTS](store, payload) {
        if (store.state.hasFetch.usedProducts) {
            return Promise.resolve()
        }
        return ajax.get("/student/products/used").then((res) => {
            store.commit(types.STUDENT_DATA.GET_USED_PRODUCTS, res.products)
            return Promise.resolve(res.products)
        })
    },
    [types.STUDENT_DATA.GET_MY_PRODUCTS](store, payload) {
        if (store.state.hasFetch.myProducts) {
            return Promise.resolve(store.state.myProducts)
        }
        return ajax.get("/student/products").then((res) => {
            store.commit(types.STUDENT_DATA.GET_MY_PRODUCTS, res.products)
            return Promise.resolve(res.products)
        })
    },
    [types.STUDENT_DATA.GET_PRODUCT_BY_ID](store, id) {
        if (store.state.allProducts.length > 0) {
            let exist = store.state.allProducts.find((p) => p.id === id)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        //skip, we are already loading it
        if (typeof store.state.isFetching.productsById[id] !== "undefined") {
            return Promise.resolve(null)
        }
        store.commit(types.STUDENT_DATA.IS_FETCHING_PRODUCT_BY_ID, { id, isFetching: true })

        return ajax.get(`/student/products/id/${id}`).then((res) => {
            store.commit(types.STUDENT_DATA.IS_FETCHING_PRODUCT_BY_ID, { id, isFetching: false })
            store.commit(types.STUDENT_DATA.GET_PRODUCT_BY_ID, res.product)
            return Promise.resolve(res.product)
        })
    },
    [types.STUDENT_DATA.GET_GLOBALS](store, payload) {
        return ajax.get(`/student/globals`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_GLOBALS, res.global)
            return Promise.resolve(res.global)
        })
    },
    [types.STUDENT_DATA.GET_WORD_BY_ID](store, id) {
        if (store.state.words.length > 0) {
            let exist = store.state.words.find((w) => w.id === id)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        return ajax.get(`/student/words/id/${id}`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_WORD_BY_ID, res.word)
            return Promise.resolve(res.word)
        })
    },
    [types.STUDENT_DATA.GET_WORDS_BY_WORD_GROUP_UUID](store, wordGroupUuid) {
        //todo cache
        return ajax.get(`/student/word-groups/uuid/${wordGroupUuid}/words`).then((res) => {
            res.words.forEach((word) => {
                store.commit(types.STUDENT_DATA.GET_WORD_BY_ID, word)
            })

            return Promise.resolve(res.words)
        })
    },
    [types.STUDENT_DATA.GET_WORD_GROUP_BY_UUID](store, uuid) {
        if (store.state.wordGroups.length > 0) {
            let exist = store.state.wordGroups.find((w) => w.uuid === uuid)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        return ajax.get(`/student/word-groups/uuid/${uuid}`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_WORD_GROUP_BY_UUID, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.STUDENT_DATA.GET_WORD_GROUP_BY_ID](store, id) {
        if (store.state.wordGroups.length > 0) {
            let exist = store.state.wordGroups.find((w) => w.id === id)
            if (exist) {
                return Promise.resolve(exist)
            }
        }
        return ajax.get(`/student/word-groups/id/${id}`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_WORD_GROUP_BY_ID, res.wordGroup)
            return Promise.resolve(res.wordGroup)
        })
    },
    [types.STUDENT_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS](store, payload) {
        if (store.state.hasFetch.wordGroups) {
            return Promise.resolve(store.state.playableWordGroupIds[GAME_TYPES.CONTEST])
        }
        return ajax.get(`/student/word-groups/playable/${GAME_TYPES.CONTEST}`).then((res) => {
            store.commit(types.STUDENT_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS, res.wordGroupIds)
            return Promise.resolve(res.wordGroupIds)
        })
    },
    [types.STUDENT_DATA.GET_WORD_GROUPS](store, payload) {
        if (store.state.hasFetch.wordGroups) {
            return Promise.resolve(store.state.wordGroups)
        }
        //fetch playable
        store.dispatch(types.STUDENT_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS)

        return ajax.get("/student/word-groups").then((res) => {
            store.commit(types.STUDENT_DATA.GET_WORD_GROUPS, res.wordGroups)
            return Promise.resolve(res.wordGroups)
        })
    },
    [types.STUDENT_DATA.GET_MY_GAMES](store, payload) {
        return ajax.get("/student/games").then((res) => {
            store.commit(types.STUDENT_DATA.GET_MY_GAMES, res)
            return Promise.resolve(res)
        })
    },
    [types.STUDENT_DATA.GET_GAME_BY_CODE](store, code) {
        return ajax.get("/game/code/" + encodeURI(code))
    },
    [types.STUDENT_DATA.GET_LAST_HOUR_LIVE_BATTLE_SCOREBOARD](store, payload) {
        if (store.state.hasFetch.lastHourScoreboard) {
            return Promise.resolve(store.state.lastHourScoreboard)
        }
        return ajax.get("/student/standings/live").then((res) => {
            store.commit(types.STUDENT_DATA.GET_LAST_HOUR_LIVE_BATTLE_SCOREBOARD, res.scoreboard)
            return Promise.resolve(res.scoreboard)
        })
    }
}

// mutations
const mutations = {
    [types.STUDENT_DATA.GET_LEAGUE_INVITATIONS](state, leagueInvitations) {
        state.leagueInvitations = leagueInvitations
        state.hasFetch.leagueInvitations = true
    },
    [types.STUDENT_DATA.GET_LEAGUE_INVITATION_BY_UUID](state, leagueInvitation) {
        addOrReplaceArr(state.leagueInvitations, leagueInvitation)
        state.hasFetch.leagueInvitations = false
    },
    [types.STUDENT_DATA.GET_LAST_HOUR_LIVE_BATTLE_SCOREBOARD](state, scoreboard) {
        state.lastHourScoreboard = scoreboard
        state.hasFetch.lastHourScoreboard = true
    },
    [types.STUDENT_DATA.GET_CLASSROOMS](state, classrooms) {
        state.classrooms = classrooms
        state.hasFetch.classrooms = true
    },
    [types.STUDENT_DATA.GET_FRIENDS](state, friends) {
        state.friends = friends
    },
    [types.STUDENT_DATA.CREATE_LEAGUE](state, league) {
        addOrReplaceArr(state.leagues, league)
    },
    [types.STUDENT_DATA.UPDATE_LEAGUE](state, league) {
        addOrReplaceArr(state.leagues, league)
    },
    [types.STUDENT_DATA.DELETE_LEAGUE](state, leagueId) {
        deleteArr(state.leagues, { id: leagueId }, 'id')
    },
    [types.STUDENT_DATA.GET_LEAGUES](state, leagues) {
        state.hasFetch.leagues = true
        state.leagues = leagues
    },
    [types.STUDENT_DATA.GET_WORD_GROUPS](state, wordGroups) {
        wordGroups.forEach((wordGroup) => {
            addOrReplaceArr(state.wordGroups, wordGroup)
        })

        state.hasFetch.wordGroups = true
    },
    [types.STUDENT_DATA.GET_CONTEST_PLAYABLE_WORD_GROUPS](state, wordGroupIds) {
        state.playableWordGroupIds[GAME_TYPES.CONTEST] = wordGroupIds
    },
    [types.STUDENT_DATA.GET_WORD_GROUP_BY_ID](state, wordGroup) {
        addOrReplaceArr(state.wordGroups, wordGroup)
    },
    [types.STUDENT_DATA.GET_WORD_GROUP_BY_UUID](state, wordGroup) {
        addOrReplaceArr(state.wordGroups, wordGroup)
    },
    [types.STUDENT_DATA.GET_WORD_BY_ID](state, word) {
        addOrReplaceArr(state.words, word)
    },
    [types.STUDENT_DATA.GET_GLOBALS](state, globals) {
        if (globals.currentWordGroups) {
            state.global.currentWordGroups = globals.currentWordGroups
            globals.currentWordGroups.forEach((wG) => {
                addOrReplaceArr(state.wordGroups, wG)
            })
        }

        state.global.lastUnlockedProduct = globals.lastUnlockedProduct
        state.global.productCategories = globals.productCategories
        state.global.gameTypes = globals.gameTypes
    },
    [types.STUDENT_DATA.GET_MY_GAMES](state, games) {
        state.games = games
    },
    [types.STUDENT_DATA.GET_MY_PRODUCTS](state, products) {
        //dont replace arr, may have fetch for image of other student
        products.forEach((product) => {
            addOrReplaceArr(state.myProducts, product)
        })
        state.hasFetch.myProducts = true
    },
    [types.STUDENT_DATA.GET_PRODUCT_BY_ID](state, product) {
        addOrReplaceArr(state.allProducts, product)
    },
    [types.STUDENT_DATA.REMOVE_NOTIFICATION_BY_UUID](state, uuid) {
        let idx = state.notifications.findIndex((n) => n.uuid === uuid)
        if (idx === -1) return
        state.notifications.splice(idx, 1)
    },
    [types.STUDENT_DATA.SEEN_NOTIFICATION_BY_UUID](state, notification) {
        addOrReplaceArr(state.notifications, notification)
        orderBy(state.notifications, ["createdAt"], ["desc"])
    },
    [types.STUDENT_DATA.GET_NOTIFICATION_BY_UUID](state, notification) {
        addOrReplaceArr(state.notifications, notification)
        orderBy(state.notifications, ["createdAt"], ["desc"])
    },
    [types.STUDENT_DATA.GET_NOTIFICATIONS](state, notifications) {
        state.notifications = notifications
        orderBy(state.notifications, ["createdAt"], ["desc"])
    },
    [types.STUDENT_DATA.GET_USED_PRODUCTS](state, products) {
        products.forEach((product) => {
            addOrReplaceArr(state.myProducts, product)
        })
        state.hasFetch.usedProducts = true
    },
    [types.STUDENT_DATA.BUY_PRODUCT_BY_UUID](state, newBoughtProduct) {
        addOrReplaceArr(state.boughtProducts, newBoughtProduct)
    },
    [types.STUDENT_DATA.IS_FETCHING_PRODUCT_BY_ID](state, { id, isFetching }) {
        if (isFetching) {
            state.isFetching.productsById[id] = isFetching
        } else if (typeof state.isFetching.productsById[id] !== "undefined") {
            delete state.isFetching.productsById[id]
        }
    },
    [types.STUDENT_DATA.GET_BOUGHT_PRODUCTS](state, boughtProducts) {
        state.boughtProducts = boughtProducts
        state.hasFetch.boughtProducts = true
    },
    [types.STUDENT_DATA.STUDENT_PLAYED_GAME](state, payload) {
        state.hasFetch.standings = false
        state.hasFetch.statsWords = false
    },
    [types.STUDENT_DATA.GET_STANDINGS](state, standings) {
        state.hasFetch.standings = true
        state.standings = standings
    },
    [types.STUDENT_DATA.GET_STATS_WORDS](state, statsWords) {
        state.hasFetch.statsWords = true
        state.statsWords = statsWords
    },
    [types.LOGOUT](state) {
        //reset all data
        let clone = cloneDeep(originalState)
        Object.keys(state).forEach((key) => {
            state[key] = clone[key]
        })
        Object.assign(state, clone)
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
