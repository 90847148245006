<template>
    <StudentHeader class="mb-6">
        <h1 class="text-white">
            {{ $t("inventory.title") }}
        </h1>
    </StudentHeader>

    <div class="w-full">
        <TabbedButtons
            @click="currentProductCategoryId = $event"
            :value="currentProductCategoryId"
            :options="$store.getters.productCategoriesOptions" />
    </div>

    <simplebar class="scroll-container">
        <Card v-if="filteredProducts.length === 0">
            <p class="p-6 text-purple -small">
                {{ $t("inventory.noProducts") }}
            </p>
        </Card>
        <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
            <ProductCard
                v-for="product in filteredProducts"
                :key="product.id"
                :product="product"
                :can-equip="true"
                @use="useProduct" />
        </div>
    </simplebar>
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import StudentHeader from "components/globals/StudentHeader"
import TabbedButtons from "components/globals/TabbedButtons"
import ProductCard from "components/cards/ProductCard"
import Simplebar from "components/globals/Simplebar"

export default {
    name: "Inventory",
    components: { Simplebar, ProductCard, TabbedButtons, StudentHeader, Card },
    data() {
        return {
            currentProductCategoryId: null
        }
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_MY_PRODUCTS)
        this.$store.dispatch(types.STUDENT_DATA.GET_BOUGHT_PRODUCTS)
    },
    computed: {
        filteredProducts() {
            let arr = this.$store.state.studentData.boughtProducts
            if (!this.currentProductCategoryId) {
                return arr
            }
            return arr.filter((p) => {
                return p.productCategoryId === this.currentProductCategoryId
            })
        }
    },
    methods: {
        useProduct(productUuid) {
            this.$store.dispatch(types.STUDENT_DATA.USE_PRODUCT_BY_UUID, productUuid)
        }
    }
}
</script>
