<template>
    <div
        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10"
        v-if="data.length > 0">
        <div class="min-w-12"></div>
        <div class="w-5/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("game.wordGroup") }}
            </p>
        </div>
        <div class="w-3/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("game.nmbOfGames") }}
            </p>
        </div>
        <div class="w-3/12">
            <p class="-smallest font-medium text-purple">
                {{ $t("stats.successRate") }}
            </p>
        </div>
        <div class="w-1/12">
            <p></p>
        </div>
    </div>

    <ClassroomWordGroupTableItem
        v-for="(item, idx) in data"
        :key="item.id"
        :class="{
            'border-t border-purple-10': idx !== 0
        }"
        :item="item"
        :idx="idx" />
    <p
        class="text-purple -small mt-2"
        v-if="data.length === 0">
        {{ $t("game.noGames") }}
    </p>
</template>

<script>
import ClassroomWordGroupTableItem from "components/layouts/items/ClassroomWordGroupTableItem.vue"

export default {
    name: "ClassroomWordGroupsTable",
    components: { ClassroomWordGroupTableItem },
    props: {
        data: Array
    }
}
</script>
