<template>
    <div class="flex flex-row justify-start items-center space-x-1 w-full py-2">
        <div class="w-2/12">
            <p class="-small text-purple">
                {{ moment(game.studentGame.createdAt).format("D MMM YYYY HH:mm") }}
            </p>
        </div>
        <div class="w-2/12">
            <p
                class="-small text-purple"
                v-if="totalGameInSeconds > 60">
                {{ Math.round(totalGameInSeconds / 60) }} {{ $t("global.minutes") }}
            </p>
            <p
                class="-small text-purple"
                v-else-if="totalGameInSeconds">
                {{ totalGameInSeconds }} {{ $t("global.seconds") }}
            </p>
            <p
                class="-small text-purple"
                v-else>
                {{ $t("game.gameNotFinish") }}
            </p>
        </div>
        <div class="w-2/12">
            <template v-if="game.wordGroup">
                <div
                    class="inline-block"
                    v-if="$store.getters.isUserLogin">
                    <CustomButton
                        version="text"
                        align="left"
                        wrap
                        :to="`/user/play/${game.wordGroup.uuid}`">
                        {{ game.wordGroup.text }}
                    </CustomButton>
                </div>
                <div
                    class="inline-block"
                    v-else-if="$store.getters.isStudentLogin">
                    <CustomButton
                        version="text"
                        align="left"
                        wrap
                        :to="`/student/list/${game.wordGroup.uuid}`">
                        {{ game.wordGroup.text }}
                    </CustomButton>
                </div>
            </template>
        </div>
        <div class="w-2/12 flex flex-row justify-start items-center space-x-2">
            <Icons
                :icon="`game-type-${game.gameType.slug}`"
                class="w-4 h-4 text-purple block" />
            <p class="-small text-purple">
                {{ $t(`global.gameTypes.${game.gameType.slug}`) }}
            </p>
        </div>
        <div class="w-1/12">
            <p
                class="-small text-purple"
                v-if="game.studentGame.finishedAt">
                {{ game.studentGame.score }} {{ $t("global.points") }}
            </p>
        </div>
        <div class="w-1/12">
            <p
                class="-small text-purple"
                v-if="game.studentGame.finishedAt">
                {{ game.studentGame.successRate }}%
            </p>
        </div>
        <div class="w-1/12">
            <p
                class="-small text-purple"
                v-if="game.studentGame.finishedAt">
                {{ game.studentGame.perfect ? "Oui" : "Non" }}
            </p>
        </div>
        <div class="w-1/12">
            <p class="-small text-purple">
                {{ game.classroomId ? "Oui" : "Non" }}
            </p>
        </div>
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton.vue"
import moment from "moment"
import Icons from "components/globals/Icons.vue"

export default {
    name: "StudentGameTableItem",
    components: { Icons, CustomButton },
    props: {
        game: Object
    },
    computed: {
        totalGameInSeconds() {
            if (!this.game.studentGame.finishedAt) return null
            return moment(this.game.studentGame.finishedAt).diff(moment(this.game.studentGame.startedAt), "seconds")
        }
    }
}
</script>
