<template>
    <div class="flex flex-row justify-start items-center space-x-3">
        <input
            class="text-center"
            :class="inputClass"
            v-for="(v, index) in values"
            :key="`${id}-${index}`"
            :type="type === 'number' ? 'tel' : type"
            :pattern="type === 'number' ? '[0-9]' : null"
            :data-id="index"
            :autoFocus="autoFocus && index === autoFocusIndex"
            :value="v"
            :ref="inputRefs[index]"
            placeholder="0"
            v-on:input="onValueChange"
            v-on:focus="onFocus"
            v-on:keydown="onKeyDown"
            maxlength="1" />
    </div>
</template>

<script>
import { v4 } from "uuid"

const KEY_CODE = {
    backspace: 8,
    left: 37,
    up: 38,
    right: 39,
    down: 40
}
export default {
    name: "GameCodeInput",
    emits: ["change", "complete"],
    props: {
        type: {
            type: String,
            default: "number"
        },
        length: {
            type: Number,
            default: 6
        },
        autoFocus: {
            type: Boolean,
            default: true
        },
        value: {
            type: Number,
            default: null
        },
        inputClass: {
            type: String,
            default: "max-w-15 h-15"
        }
    },
    data() {
        let values = Array(this.length).fill("")
        let autoFocusIndex = 0
        if (this.value) {
            let values = this.value.toString().split("")
            values = []
            for (let i = 0; i < this.length; i++) {
                values.push(values[i] || "")
            }
            autoFocusIndex = values.length >= this.length ? 0 : values.length
        }

        return {
            values,
            autoFocusIndex
        }
    },
    created() {
        this.id = v4()

        this.inputRefs = []
        for (let i = 0; i < this.length; i++) {
            this.inputRefs.push(`input_${i}`)
        }
    },
    methods: {
        onFocus(e) {
            e.target.select(e)
        },
        onValueChange(e) {
            const index = parseInt(e.target.dataset.id)
            const { type, length } = this
            if (type === "number") {
                e.target.value = e.target.value.replace(/[^\d]/gi, "")
            }

            if (e.target.value === "" || (type === "number" && !e.target.validity.valid)) {
                return
            }
            let next
            const value = e.target.value
            let { values } = this
            values = Object.assign([], values)
            if (value.length > 1) {
                let nextIndex = value.length + index - 1
                if (nextIndex >= length) {
                    nextIndex = length - 1
                }
                next = this.inputRefs[nextIndex]
                const split = value.split("")
                split.forEach((item, i) => {
                    const cursor = index + i
                    if (cursor < length) {
                        values[cursor] = item
                    }
                })
                this.values = values
            } else {
                next = this.inputRefs[index + 1]
                values[index] = value
                this.values = values
            }
            if (next) {
                const element = this.$refs[next][0]
                element.focus()
                element.select()
            }
            this.triggerChange(values)
        },
        onKeyDown(e) {
            const index = parseInt(e.target.dataset.id)
            const prevIndex = index - 1
            const nextIndex = index + 1
            const prev = this.inputRefs[prevIndex]
            const next = this.inputRefs[nextIndex]
            switch (e.keyCode) {
                case KEY_CODE.backspace: {
                    e.preventDefault()
                    const vals = [...this.values]
                    if (this.values[index]) {
                        vals[index] = ""
                        this.values = vals
                        this.triggerChange(vals)
                    } else if (prev) {
                        vals[prevIndex] = ""
                        this.$refs[prev][0].focus()
                        this.values = vals
                        this.triggerChange(vals)
                    }
                    break
                }
                case KEY_CODE.left:
                    e.preventDefault()
                    if (prev) {
                        this.$refs[prev][0].focus()
                    }
                    break
                case KEY_CODE.right:
                    e.preventDefault()
                    if (next) {
                        this.$refs[next][0].focus()
                    }
                    break
                case KEY_CODE.up:
                case KEY_CODE.down:
                    e.preventDefault()
                    break
                default:
                    // this.handleKeys[index] = true;
                    break
            }
        },
        triggerChange(values = this.values) {
            const { length } = this
            const val = values.join("")
            this.$emit("change", val)
            if (val.length >= length) {
                this.$emit("complete", val)
            }
        }
    }
}
</script>
