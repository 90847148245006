<template>
    <UserHeader
        class="mb-6"
        align="start">
        <GoBackButton />
        <h1>
            {{ $t("list.title") }}
        </h1>
    </UserHeader>
    <WordGroupCard
        version="live"
        class="w-full"
        v-if="wordGroup"
        :word-group="wordGroup"
        @create="createGame" />

    <WordsByDfficulty :word-group="wordGroup" />
</template>

<script>
import * as types from "store/types"
import WordGroupCard from "components/cards/WordGroupCard"
import GoBackButton from "components/globals/GoBackButton"
import UserHeader from "components/globals/UserHeader"
import ajax from "config/ajax"
import WordsByDfficulty from "components/layouts/WordsByDifficulty"

export default {
    name: "UserSingleList",
    components: {
        WordsByDfficulty,
        UserHeader,
        GoBackButton,
        WordGroupCard
    },
    data() {
        return {}
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUP_BY_UUID, this.$route.params.uuid)
        this.$store.dispatch(types.USER_DATA.GET_WORDS_BY_WORD_GROUP_UUID, this.$route.params.uuid)
    },
    computed: {
        wordGroup() {
            return this.$store.state.userData.wordGroups.find((w) => w.uuid === this.$route.params.uuid) || null
        }
    },
    methods: {
        async createGame({ gameTypeSlug, wordGroupUuid }) {
            let res = await ajax.post("/user/live-games", {
                classroomUuid: this.$store.state.userData.currentClassroomUuid,
                wordGroupUuid: wordGroupUuid,
                gameTypeSlug: gameTypeSlug
            })

            let { success, game } = res

            if (!success) {
                //todo handle error
                return
            }

            this.$router.replace(`/user/live/${game.uuid}/${gameTypeSlug}`)
        }
    }
}
</script>
