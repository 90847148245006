<template>
    <div class="flex flex-row justify-start items-center space-x-1 w-full py-2">
        <div class="w-4/12">
            <p class="-small text-purple">
                {{ learningTraductionText }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-small text-purple">
                {{ stats.tries }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-small text-purple">
                {{ stats.goodResult }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-small text-purple">
                {{ stats.badResult }}
            </p>
        </div>
        <div class="w-2/12">
            <p class="-small text-purple">
                {{ successRatePercentage }}%
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: "StudentWordTableItem",
    components: {  },
    props: {
        learningLang: String,
        word: Object,
        stats: Object
    },
    computed: {
        successRatePercentage(){
            if (!this.stats) return 0
            let successRatePercentage = 0
            //make sure we dont divide by 0
            if (this.stats.tries > 0) {
                successRatePercentage = parseInt((this.stats.goodResult / this.stats.tries) * 100) //make it an int
            }
            return successRatePercentage
        },
        learningTraductionText() {
            if (!this.word) return ""
            let traduction = null
            if (this.$store.getters.isUserLogin) {
                traduction = this.word.traductions.find((t) => t.lang === this.learningLang) || null
            }
            if (this.$store.getters.isStudentLogin) {
                traduction = this.$store.getters.getStudentLearningTraduction(this.word)
            }

            if (!traduction) return this.word.text
            return traduction.text
        }
    },
}
</script>
