<template>
    <div class="md:grid grid-cols-4 gap-3">
        <div class="c__input">
            <label>
                {{ $t("form.firstName") }}
            </label>
            <input
                type="text"
                name="firstName"
                min="4"
                max="191"
                v-model="student.firstName"
                required />
            <p
                class="error"
                v-if="errors.firstName">
                {{ errors.firstName }}
            </p>
        </div>
        <div class="c__input">
            <label>
                {{ $t("form.lastName") }}
            </label>
            <input
                type="text"
                name="lastName"
                min="4"
                max="191"
                v-model="student.lastName"
                required />
            <p
                class="error"
                v-if="errors.lastName">
                {{ errors.lastName }}
            </p>
        </div>
        <div class="c__input">
            <label>
                {{ $t("form.username") }} <span class="text-11">({{ $t("form.autoGeneratedIfEmpty") }})</span>
            </label>
            <input
                type="text"
                name="studentU"
                min="3"
                max="191"
                v-lowercase
                v-model="student.username" />
            <p
                class="error"
                v-if="errors.username">
                {{ errors.username }}
            </p>
        </div>
        <div class="c__input">
            <label>
                {{ $t("form.gender") }} <span class="text-11">({{ $t("form.genderInfo") }})</span>
            </label>
            <div class="c__select">
                <select
                    v-model="student.gender"
                    name="gender"
                    class="form-select">
                    <option value="">
                        {{ $t("global.noSelect") }}
                    </option>
                    <option
                        v-for="gender in Object.values(GENDERS)"
                        :key="gender"
                        :value="gender">
                        {{ $t(`global.genders.${gender}`) }}
                    </option>
                </select>
            </div>
            <p
                class="error"
                v-if="errors.gender">
                {{ errors.gender }}
            </p>
        </div>
    </div>
</template>

<script>
import { AVAILABLE_LANGS, AVAILABLE_LEARNING_LANGS, DIFFICULTY, GENDERS, GRADES } from "@globals/constants"

export default {
    name: "BulkStudentForm",
    props: {
        student: Object,
        errors: Object
    },
    data() {
        return {
            AVAILABLE_LEARNING_LANGS,
            DIFFICULTY,
            GRADES,
            AVAILABLE_LANGS,
            GENDERS
        }
    },
    methods: {
        validate() {
            let errors = {}

            if (this.student.firstName === "") {
                errors.firstName = this.$t("validationErrors.firstName")
            }
            if (this.student.lastName === "") {
                errors.lastName = this.$t("validationErrors.lastName")
            }

            if (this.$store.getters.isUserLogin && this.$store.state.auth.user.isTeacher) {
                if (!this.student.classroomId) {
                    errors.classroomId = this.$t("validationErrors.classroom")
                }
            }

            return errors
        }
    }
}
</script>
