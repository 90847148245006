<template>
    <div class="w-full h-full max-h-full">
        <Loader v-if="isLoading || localIsLoading" />
        <div
            class="flex flex-col justify-start items-start w-full h-full max-h-full"
            v-else>
            <h2>
                {{ $t("billing.planInfo") }}
            </h2>

            <simplebar class="w-full h-0 flex-1">
                <div
                    class="mt-6 space-y-2"
                    v-if="userLicense">
                    <div v-if="$store.state.auth.user.isTeacher">
                        <p
                            v-if="userLicense.planType === PLAN_TYPES.TEACHER_STANDARD"
                            class="text-purple font-medium">
                            {{ $t("billing.limitedStudents", { count: 28 }) }}
                        </p>
                        <p
                            v-else-if="userLicense.planType === PLAN_TYPES.TEACHER_ON_DEMAND"
                            class="text-purple font-medium">
                            {{ $t("billing.unlimitedStudents") }}
                        </p>
                    </div>
                    <div v-else-if="$store.state.auth.user.isParent">
                        <p
                            v-if="userLicense.planType === PLAN_TYPES.PARENT_STANDARD"
                            class="text-purple font-medium">
                            {{ $t("billing.limitedChildrens", { count: 2 }) }}
                        </p>
                        <p
                            v-else-if="userLicense.planType === PLAN_TYPES.PARENT_PREMIUM"
                            class="text-purple font-medium">
                            {{ $t("billing.unlimitedChildrens") }}
                        </p>
                    </div>


                    <div v-if="userLicense.buyer">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.buyer") }}
                        </p>
                        <p class="-small text-purple">
                            <span v-if="userLicense.buyer.isSchoolManager">{{ $t("auth.schoolManager") }} <br /></span>
                            {{ userLicense.buyer.name }} <br />
                            {{ userLicense.buyer.email }}
                        </p>
                    </div>
                    <div v-else>
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.buyer") }}
                        </p>
                        <p class="-small text-purple">
                            {{ $t("billing.admin") }}
                        </p>
                    </div>
                    <div v-if="currentUserSubscription">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.planName") }}
                        </p>
                        <p class="-small text-purple">
                            {{ $t(`global.plans.${currentUserSubscription.planId}`) }}
                        </p>
                    </div>
                    <div>
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.startDate") }}
                        </p>
                        <p class="-small text-purple">
                            {{ moment(userLicense.startedAt).format("LL") }}
                        </p>
                    </div>
                    <div>
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.endDate") }}
                        </p>
                        <p class="-small text-purple">
                            {{ moment(userLicense.finishedAt).format("LL") }}
                        </p>
                    </div>
                    <div v-if="currentUserSubscription">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.renewDate") }}
                        </p>
                        <p class="-small text-purple">
                            {{ moment(currentUserSubscription.nextBillingDate).format("LL") }}
                        </p>
                    </div>
                    <div v-if="currentUserSubscription">
                        <p class="-small text-purple font-medium">
                            {{ $t("billing.renewPrice") }}
                        </p>
                        <p class="-small text-purple">{{ currentUserSubscription.nextBillAmount }} $</p>
                    </div>

                    <CustomButton
                        @click="showCancelUserSubscriptionModal = true"
                        type="button"
                        class="mt-6">
                        {{ $t("billing.btnCancelSubscriptionComplete") }}
                    </CustomButton>
                </div>
                <div
                    class="mt-6"
                    v-else>
                    <p
                        class="text-purple -small"
                        v-html="$t('billing.userSubscriptionDescription')" />

                    <div class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6 mt-6">
                        <plan-type-card v-for="(plan) in userPlans"
                                        :key="plan.handle"
                                        :plan="plan"
                                        @clicked="onPlanSelected"
                                        :selected="selectedPlan === plan.handle"
                        />
                    </div>
                    <p
                        class="error"
                        v-if="errors.plan">
                        {{ errors.plan }}
                    </p>

                    <p
                        class="error"
                        v-if="errors.global">
                        {{ errors.global }}
                    </p>

                    <CustomButton
                        @click="createUserSubscription"
                        type="button"
                        class="mt-6"
                        :disabled="localIsLoading || !selectedPlan"
                        v-if="paymentData">
                        {{ $t("billing.btnActivateSubscription") }}
                    </CustomButton>

                    <p
                        v-else
                        class="mt-4 error -small">
                        {{ $t("billing.noPaymentMethodTeacher") }}
                    </p>
                </div>
            </simplebar>
        </div>
        <CancelUserSubscriptionModal
            :user="$store.state.auth.user"
            @cancel="cancelUserSubscription"
            @close="showCancelUserSubscriptionModal = false"
            v-if="showCancelUserSubscriptionModal" />
    </div>
</template>

<script>
import Loader from "components/Loader"
import Simplebar from "components/globals/Simplebar"
import ajax from "config/ajax"
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import CancelUserSubscriptionModal from "components/modals/user/CancelUserSubscriptionModal"
import { tryAsyncFunc } from "@globals/helpers/async"
import { PLAN_TYPES } from "@globals/constants"
import PlanTypeCard from "components/cards/PlanTypeCard.vue"
import { parentPlans, teacherPlans } from "@globals/data/plans"

export default {
    name: "UserSubscriptions",
    components: { PlanTypeCard, CancelUserSubscriptionModal, CustomButton, Simplebar, Loader },
    emits: ["changed"],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            teacherPlans,
            PLAN_TYPES,
            errors: {},
            selectedPlan: null,
            localIsLoading: false,
            showCancelUserSubscriptionModal: false
        }
    },
    computed: {
        paymentData() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.paymentData
        },
        currentUserSubscription() {
            return (
                this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.currentUserSubscription
            )
        },
        userLicense() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.license
        },
        userPlans() {
            if (this.$store.state.auth.user.isTeacher) {
                return teacherPlans
            }
            if (this.$store.state.auth.user.isParent) {
                return parentPlans
            }
            return []
        }
    },
    watch: {
        userLicense(nextValue) {
            this.selectedPlan = nextValue ? nextValue.planId : null
        }
    },
    methods: {
        onPlanSelected(planHandle) {
            if (!this.paymentData) return//cant select plan if no payment method

            this.selectedPlan = planHandle
        },
        async cancelUserSubscription() {
            this.errors = {}
            this.localIsLoading = true
            this.showCancelUserSubscriptionModal = false

            const [res, err] = await tryAsyncFunc(ajax.post(`/user/me/subscription/cancel`))

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.localIsLoading = false

            this.$emit("changed")
        },
        async createUserSubscription() {
            this.errors = {}
            if (!this.selectedPlan) {
                this.errors.plan = this.$t("validationErrors.plan")
                return
            }

            this.localIsLoading = true

            const [res, err] = await tryAsyncFunc(ajax.post(`/user/me/subscription/create`, {
                planType: this.selectedPlan
            }))
            this.localIsLoading = false


            if (err) {
                this.errors = err.errors || {plan:this.$t("validationErrors.plan")}
                return
            }

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })


            this.$emit("changed")
        }
    }
}
</script>
