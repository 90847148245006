<template>
    <div class="w-full h-full">
        <Card class="h-full">
            <div class="p-3 flex flex-col justify-start items-start h-full">
                <h1 v-if="playerName">
                    {{ $t("game.congrats", { name: playerName }) }}
                </h1>
                <div class="pt-6">
                    <h2>
                        {{ $t("game.myScore") }}
                    </h2>
                    <div class="flex flex-row justify-start items-center pt-3 space-x-10">
                        <div class="flex flex-row justify-center items-center space-x-2"
                             v-if="game.gameType.slug === GAME_TYPES.LICK_3D_GAME">
                            <h2 class="font-bold text-red">
                                {{ studentGameData.extraData.money }}
                            </h2>
                            <img
                                class="block h-8 w-auto object-contain"
                                :src="moneyItemPng" />
                        </div>

                        <h2 class="text-red font-medium" v-else>
                            <span class="font-bold">{{ studentGameData.totalScore }}</span> {{ $t("global.points") }}
                        </h2>
                        <GameTimer
                            tag="h2"
                            v-if="showTimer"
                            :timer="totalGameTimer"
                            class="block text-red font-bold" />
                        <template v-if="studentGameData.studentGame">
                            <h2 class="text-red font-medium">
                                <span class="font-bold">{{ studentGameData.studentGame.pointsXP }}</span> xp
                            </h2>
                            <h2 class="text-red font-medium">
                                <span class="font-bold">{{ studentGameData.studentGame.pointsA }}</span> K
                            </h2>
                        </template>
                    </div>
                </div>
                <div v-if="capturedBugs.length > 0" class="w-full flex flex-row flex-wrap justify-start items-center space-x-3 md:space-x-6 pt-6">
                    <div v-for="item in capturedBugs"
                         :key="item.bug.id"
                         class="flex flex-col justify-center items-center"
                    >
                        <img
                            :src="item.bug.img"
                            class="object-contain object-center min-w-8 w-6 h-6 md:min-w-12 md:w-12 md:h-12 block" />
                        <h3 class="font-bold text-center pt-1">
                            {{ item.count }} x
                        </h3>
                    </div>
                </div>
                <div class="pt-6 w-full">
                    <h3 v-if="badResults.length === 0">
                        {{ $t("game.perfectGame") }}
                    </h3>
                    <h3 v-else>
                        {{ $t("game.missedWords") }}
                    </h3>
                    <div
                        class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 mt-6"
                        v-if="badResults.length > 0">
                        <WordCard
                            v-for="word in badResults"
                            :key="word.id"
                            :word="word" />
                    </div>
                </div>
                <div class="pt-6 flex-1 h-0 w-full">
                    <CurrentGameScoreboard
                        :show-count="scoreboardShowCount"
                        :game-data="gameData" />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import { msToTime } from "@globals/functions"
import GameTimer from "components/game/GameTimer"
import WordCard from "components/cards/WordCard"
import uniqBy from "lodash-es/uniqBy"
import { GAME_TYPES } from "@globals/constants"
import CurrentGameScoreboard from "components/game/CurentGameScoreboard"
import moneyItemPng from "assets/images/games/money.png"
import { BUGS } from "@globals/data/3dGame"


export default {
    name: "StudentGameResult",
    emits: ["restart", "end"],
    components: { CurrentGameScoreboard, WordCard, GameTimer, Card },
    props: {
        studentGameData: Object,
        gameData: Object,
        game: Object
    },
    data() {
        return {
            GAME_TYPES,
            moneyItemPng
        }
    },
    computed: {
        showTimer() {
            if (this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME) {
                return false
            }
            return true
        },
        totalGameTimer() {
            return msToTime(this.studentGameData.totalGameTimeMs)
        },
        badResults() {
            //remove goodResult and duplicate
            return uniqBy(
                this.studentGameData.results.filter((r) => !r.goodResult),
                "id"
            )
        },
        playerName() {
            if (this.$store.getters.isStudentLogin) {
                return this.$store.state.auth.student.firstName
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.state.auth.anonymous.name
            }
            return null
        },
        scoreboardShowCount() {
            if (this.gameData.studentGames.length <= 4) return null //show all under or equal 4
            return Math.ceil(this.gameData.studentGames.length / 2) //show half the list
        },
        capturedBugs() {
            if (this.game.gameType.slug !== GAME_TYPES.LICK_3D_GAME) {
                return []
            }
            if (!this.studentGameData){
                return []
            }
            if (!this.studentGameData.extraData){
                return []
            }
            //todo check why that may be empty
            if (!this.studentGameData.extraData.allCaptureBugIds){
                return []
            }

            let finalData = {}

            //start count with 0
            BUGS.forEach(b => {
                finalData[b.id] = {
                    count: 0,
                    bug: b
                }
            })

            this.studentGameData.extraData.allCaptureBugIds.forEach(bugId => {
                finalData[bugId].count++
            })

            return Object.values(finalData)
        }
    },
    methods: {
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        }
    }
}
</script>
