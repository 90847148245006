<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="mt-6 text-center px-6">
            {{ $t("modal.closedSocket.title") }}
        </h2>
        <p class="text-center text-purple -small mt-2">
            {{ $t("modal.closedSocket.description") }}
        </p>

        <div class="flex flex-row justify-center items-center mt-6">
            <CustomButton @click.prevent="$emit('close')">
                {{ $t("modal.closedSocket.btnLogin") }}
            </CustomButton>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "ClosedSocketModal",
    components: { CustomButton, Modal },
    emits: ["close"]
}
</script>
