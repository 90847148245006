<template>
    <UserHeader class="block lg:hidden mb-6">
        <h1>
            {{ $t("tools.title") }}
        </h1>
    </UserHeader>
    <div class="py-6 w-full h-full">
        <Card class="w-full h-full">
            <simplebar class="scroll-container">
                <div class="px-6">
                    <h1 class="mb-6">
                        {{ $t("tools.title") }}
                    </h1>
                    <div
                        v-for="tool in tools"
                        :key="tool.handle">
                        <Card>
                            <div class="flex flex-row justify-between items-center p-3 space-x-2">
                                <div class="flex flex-row justify-between items-center p-2 space-x-6">
                                    <Icons
                                        :icon="tool.icon"
                                        class="w-8 h-8 text-purple" />
                                    <div>
                                        <h2>
                                            {{ tool.title }}
                                        </h2>
                                        <p class="text-purple -small pt-1">
                                            {{ tool.description }}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <CustomButton @click.prevent="onUseTool(tool.handle)">
                                        {{ $t("tools.use") }}
                                    </CustomButton>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </simplebar>
        </Card>
    </div>

    <ToolGenerateDictationModal
        v-if="currentToolHandle === 'generate-dictation'"
        @close="currentToolHandle = ''" />
    <NeedLicenseModal
        v-if="showNeedLicenseModal"
        @close="showNeedLicenseModal = false" />
</template>

<script>
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import Simplebar from "components/globals/Simplebar"
import UserHeader from "components/globals/UserHeader"
import Icons from "components/globals/Icons.vue"
import NeedLicenseModal from "components/modals/user/NeedLicenseModal.vue"
import ToolGenerateDictationModal from "components/modals/user/tools/ToolGenerateDictationModal.vue"

export default {
    name: "UserTools",
    components: { ToolGenerateDictationModal, NeedLicenseModal, Icons, UserHeader, Simplebar, CustomButton, Card },
    data() {
        return {
            showNeedLicenseModal: false,
            currentToolHandle: "",
            tools: [
                {
                    title: this.$t("tools.dictation.title"),
                    handle: "generate-dictation",
                    icon: "doc",
                    description: this.$t("tools.dictation.description")
                }
            ]
        }
    },

    computed: {},
    methods: {
        onUseTool(handle) {
            if (!this.$store.state.auth.user.licenseId) {
                this.showNeedLicenseModal = true
                return
            }

            this.currentToolHandle = handle
        }
    }
}
</script>
