<template>
    <h1 class="text-center mb-6">
        {{ $t("auth.forgotPwdIntro") }}
    </h1>
    <div
        v-if="send"
        class="my-4">
        <p
            class="text-purple -small"
            v-if="type === USER_TYPES.USER">
            {{ $t("auth.forgotPwdSent") }}
        </p>
        <p
            class="text-purple -small"
            v-else>
            {{ $t("auth.forgotPwdParentSent") }}
        </p>
    </div>
    <form
        @submit.prevent="forgot"
        class="w-full"
        v-else>
        <div class="c__input">
            <label class="">
                {{ $t("form.usernameOrEmail") }}
            </label>
            <input
                type="text"
                required
                v-model="username"
                name="username" />
            <p
                class="error"
                v-if="errors.username">
                {{ errors.username }}
            </p>
        </div>

        <div class="flex flex-row justify-center items-center mt-6">
            <CustomButton type="submit">
                {{ $t("auth.btnChangePwd") }}
            </CustomButton>
        </div>
    </form>

    <div class="md:flex flex-row justify-center items-center md:space-x-3 mt-6">
        <CustomButton
            @click.prevent="restart"
            version="text"
            v-if="send">
            {{ $t("auth.btnForgotPwdRestart") }}
        </CustomButton>

        <CustomButton
            to="/auth/login"
            class="mt-6 md:mt-0"
            version="text">
            {{ $t("auth.btnLogin") }}
        </CustomButton>
    </div>
</template>
<script>
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import { USER_TYPES } from "@globals/constants"

export default {
    name: "UserForgotPassword",
    components: { CustomButton },
    data() {
        return {
            USER_TYPES,

            username: "",
            send: false,
            type: USER_TYPES.USER,
            errors: {}
        }
    },
    methods: {
        restart() {
            this.type = USER_TYPES.USER
            this.send = false
            this.username = ""
            this.errors = {}
        },
        forgot() {
            this.errors = {}
            this.$store
                .dispatch(types.FORGOT_PWD, this.username)
                .then((res) => {
                    this.send = true
                    this.type = res.type
                })
                .catch((err) => {
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
