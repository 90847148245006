<template>
    <div class="w-full flex flex-row justify-between items-center space-x-3 pt-6 lg:pt-12">
        <div class="flex flex-row justify-between items-center space-x-3 lg:-ml-3">
            <div class="block lg:hidden mr-3 lg:mr-0">
                <CircleButton
                    @click.prevent="openMobileMenu"
                    icon="menu"
                    icon-class="w-5 h-5" />
            </div>

            <slot />
        </div>
        <slot name="end" />
        <div
            class="flex flex-row justify-end items-center space-x-3"
            :class="experienceClass"
            v-if="studentExperience">
            <div class="hidden sm:block">
                <h3 class="whitespace-nowrap leading-0 pt-3 text-white">
                    {{ $t("global.level") }} {{ studentExperience.level }}
                </h3>
                <span class="text-11 text-white pt-2 block"> {{ studentExperience.levelPercentage }}% </span>
            </div>
            <ProgressBar
                :min-value="10"
                class="hidden xl:block xl:w-52 2xl:w-72"
                :value="$store.state.auth.student.pointsXP"
                :max-value="studentExperience.toNextLevel" />
            <div class="hidden xs:flex flex-row items-center space-x-3">
                <PointTag
                    type="A"
                    :value="$store.state.auth.student.pointsA" />
            </div>
            <router-link
                to="/student/settings"
                class="block">
                <StudentAvatar :student="$store.state.auth.student" />
            </router-link>
        </div>
    </div>
</template>

<script>
import ProgressBar from "components/globals/ProgressBar"
import PointTag from "components/globals/PointTag"
import StudentAvatar from "components/globals/StudentAvatar"
import CircleButton from "components/globals/CircleButton"
import EventBus, { EVENTS } from "@/eventbus"

export default {
    name: "StudentHeader",
    components: { CircleButton, StudentAvatar, PointTag, ProgressBar },
    props: {
        experienceClass: String
    },
    computed: {
        studentExperience() {
            return this.$store.getters.getStudentExperience(this.$store.state.auth.student)
        }
    },
    methods: {
        openMobileMenu() {
            EventBus.emit(EVENTS.OPEN_MOBILE_MENU)
        }
    }
}
</script>
