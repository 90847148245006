<template>
    <div
        v-if="isLoading"
        class="py-6">
        <Loader />
    </div>
    <div class="mt-6 w-full">
        <LiveGameHeader
            @goBack="onGoBack"
            :game-data="gameData"
            :game="game"
            :word-group="wordGroup" />
    </div>

    <LiveGameWaiting
        v-if="!gameData.isStarted"
        @exit="onGoBack"
        @start="wantToStartGame"
        :game-data="gameData"
        :word-group="wordGroup"
        :game="game" />

    <simplebar
        v-else
        class="scroll-container -no-scroll">
        <div
            class="flex flex-col justify-start items-start w-full h-full max-h-full"
            v-if="studentGameData">
            <div class="w-full flex-1 relative">
                <StudentGameResult
                    v-if="showResult"
                    class="pb-6"
                    :student-game-data="studentGameData"
                    :game-data="gameData"
                    :game="game"
                    @end="leaveGame"
                    @restart="restart" />
                <template v-else-if="gameData.isStarted">
                    <GameBattle
                        v-if="gameTypeSlug === GAME_TYPES.BATTLE"
                        :current-section="currentSection"
                        :word-group="wordGroup"
                        :game="game"
                        @answer="sendAnswer" />
                    <GameContest
                        v-else-if="gameTypeSlug === GAME_TYPES.CONTEST"
                        :current-section="currentSection"
                        :word-group="wordGroup"
                        :student-game-data="studentGameData"
                        :game="game"
                        :game-data="gameData"
                        @answer="sendAnswer" />

                    <Game3D
                        v-else-if="gameTypeSlug === GAME_TYPES.LICK_3D_GAME"
                        :current-section="currentSection"
                        :word-group="wordGroup"
                        :student-game-data="studentGameData"
                        :game="game"
                        :game-data="gameData"
                        @answer="sendAnswer" />
                </template>
            </div>
            <div
                class="mt-3 md:mt-6 w-full px-2"
                v-if="!showResult && gameTypeSlug === GAME_TYPES.BATTLE">
                <LiveBattleRace :game-data="gameData" />
            </div>
            <div
                class="mt-6 w-full"
                v-if="!showResult">
                <GameFooter
                    :game-type="gameType"
                    :student-game-data="studentGameData"
                    :timer="timer" />
            </div>
        </div>
    </simplebar>
    <ConfirmQuittingGameModal
        v-if="showQuittingModal"
        @exit="leaveGame"
        @stay="showQuittingModal = false"
        @close="showQuittingModal = false" />
</template>

<script>
import * as types from "store/types"
import Loader from "components/Loader"
import ajax from "config/ajax"
import GameBattle from "components/game/student/types/GameBattle"
import GameContest from "components/game/student/types/GameContest"
import LiveGameWaiting from "components/game/student/live/LiveGameWaiting"
import { GAME_TYPES, SOCKET_EVENTS } from "@globals/constants"
import GameFooter from "components/game/GameFooter"
import LiveGameHeader from "components/game/student/live/LiveGameHeader"
import { studentGameMixin } from "mixins/game"
import ConfirmQuittingGameModal from "components/modals/student/ConfirmQuittingGameModal"
import Simplebar from "components/globals/Simplebar"
import LiveBattleRace from "components/game/student/live/LiveBattleRace"
import StudentGameResult from "components/game/student/global/StudentGameResult"
import cloneDeep from "lodash-es/cloneDeep"
import Game3D from "components/game/student/types/Game3D.vue"

export default {
    name: "LiveGame",
    components: {
        Game3D,
        StudentGameResult,
        LiveBattleRace,
        Simplebar,
        ConfirmQuittingGameModal,
        LiveGameHeader,
        GameFooter,
        LiveGameWaiting,
        GameContest,
        GameBattle,
        Loader
    },
    mixins: [studentGameMixin],
    async created() {
        this.$store.commit(types.STUDENT_DATA.STUDENT_PLAYED_GAME)

        await this.init() //from mixin
        await this.initGame() //init game
    },
    methods: {
        async initGame() {
            let res
            try {
                res = await ajax.get(`/student/live-games/${this.$route.params.uuid}`)
            } catch (e) {
                this.$router.replace("/student/live-battle")
                return
            }

            const { gameData, game } = res

            let wordGroup = this.fetchWordGroup(game.wordGroupId)
            if (!wordGroup) {
                return
            }

            this.gameData = gameData
            this.game = game

            //may sure we dont enter a live if we were never there ever
            if (
                this.gameData.isStarted &&
                !this.gameData.logs.allStudentIdsThatJoinedGame.includes(this.$store.state.auth.student.id)
            ) {
                //student has never join that game and its started, so we kicked him out
                this.$router.replace("/student/live-battle")
                return
            }

            this.joinGame() //make sure server knows we enter the game

            //start game if started and not finished yet
            if (this.gameData.isStarted && !this.gameData.isFinish) {
                this.startGame() //init local game
            }

            this.initSocketEvents()

            this.isLoading = false
        },
        initSocketEvents() {
            this.$store.state.auth.studentSocket.on(SOCKET_EVENTS.UPDATE_LIVE_GAME, (gameData) => {
                if (this.isSendingAnswer && this.gameTypeSlug !== GAME_TYPES.CONTEST) {
                    this.gameDatasReceivedWhileAnswering.push(cloneDeep(gameData)) //keep values in our data
                    return //the current student will update himself on different game type execpt contest
                }
                this.gameData = gameData
            })

            this.$store.state.auth.studentSocket.on(SOCKET_EVENTS.USER_UPDATED_GAME, (game) => {
                if (this.game.wordGroupId !== game.wordGroupId) {
                    this.fetchWordGroup(game.wordGroupId)
                }
                this.game = game
            })

            this.$store.state.auth.studentSocket.on(SOCKET_EVENTS.START_LIVE_GAME, (gameData) => {
                this.gameData = gameData
                this.startGame()
            })
        },
        async startGame() {
            if (!this.studentGameData || this.studentGameData.isFinish) {
                return
            }
            let currentSection = this.studentGameData.sections[0]

            //init
            this.currentSection = {
                ...currentSection,
                isReal: true,
                wasGoodAnswer: false,
                wasWrongAnswer: false
            }

            //start timer only if we are not finish
            this.startTimer()
            this.startTotalTimer()
        },
        async wantToStartGame() {
            ajax.post(`/student/live-games/${this.game.uuid}/start`)
            //don't handle response, websocket will do that for us
        },
        async joinGame() {
            //make sure server knows we are in this game
            try {
                let res = await ajax.post(`/student/live-games/${this.game.uuid}/join`)

                this.gameData = res.gameData
                this.game = res.game

                this.$nextTick(() => {
                    //if no student, leave game
                    if (!this.studentGameData) {
                        this.leaveGame()
                    }
                })
            } catch (e) {
                this.leaveGame()
            }
        }
    }
}
</script>
