<template>
    <div class="c__loader"></div>
</template>
<script>
export default {
    name: "loader"
}
</script>
<style>
.c__loader {
    display: block;
    width: 50px;
    height: 50px;
}

.c__loader:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #f5cc16;
    border-color: #f5cc16 transparent #f5cc16 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
