<template>
    <div
        class="border-2 bg-gray-100 rounded-sm p-4 cursor-pointer space-y-3"
        :class="{
                         'border-gray-200': !selected,
                         'border-green': selected,
                     }"
        @click.prevent="$emit('clicked', plan.handle)">
        <icons icon="check-circle" class="block"
               :class="{
                           'w-8 h-8 min-w-8' : size === 'normal',
                           'w-6 h-6 min-w-6': size === 'small',
                         'text-gray-200' : !selected,
                        'text-green' : selected,
                    }"
        />
        <h2 v-if="size === 'normal'">
            {{ plan.title }}
        </h2>
        <h4 v-else-if="size === 'small'">
            {{ plan.title }}
        </h4>
        <div>
            <h4>
                {{ plan.price }}
                <span class="text-11 font-medium">
                    {{ $t("billing.perYear") }}
                </span>
            </h4>
            <span class="text-11 text-purple">
                {{ $t("billing.plusTax") }}
            </span>
        </div>
        <ul class="space-y-2" v-if="size === 'normal'">
            <li class="text-12 text-purple-40" v-for="item in plan.list" :key="item">
                {{ item }}
            </li>
        </ul>
    </div>
</template>
<script>
import Icons from "components/globals/Icons.vue"

export default {
    name: "PlanTypeCard",
    components: {
        Icons
    },
    emits: ["clicked"],
    props: {
        size: {
            type: String,
            default: "normal"
        },
        plan: Object,
        selected: Boolean
    }
}
</script>
