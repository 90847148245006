<template>
    <div class="shadow bg-white rounded p-3 flex flex-row justify-between items-center">
        <div>
            <p class="-small text-gray-400">
                {{ capitalize(title) }}
            </p>
            <CustomButton
                :to="`/student/inventory#product-${product.id}`"
                version="text"
                align="left">
                {{ $t("shop.viewProduct") }}
            </CustomButton>
        </div>
        <div>
            <img
                class="w-12 h-12 object-cover object-center block rounded"
                :alt="product.title"
                loading="lazy"
                :src="product.imageUrl" />
        </div>
    </div>
</template>

<script>
import CustomButton from "components/globals/CustomButton"

export default {
    name: "SmallProductCard",
    components: { CustomButton },
    props: {
        product: {
            required: true,
            type: Object
        }
    },
    computed: {
        traduction() {
            if (!this.product) return null
            return this.$store.getters.getStudentTraduction(this.product)
        },
        title() {
            if (this.traduction) {
                if (this.traduction.text) {
                    return this.traduction.text
                }
            }
            return this.product.text
        }
    }
}
</script>
