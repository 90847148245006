<template>
    <div
        class="popper"
        :class="[{ '-disabled': disabled }, `-${placement}`]"
        @mouseenter="onMouseEnter">
        <div
            class="popper__content"
            style="position: fixed"
            :class="{ '-warning': warning }"
            ref="content">
            <p class="-smallest font-normal">{{ content }}</p>
            <div data-popper-arrow></div>
        </div>
        <slot ref="slot" />
    </div>
</template>

<script>
import { createPopper } from "@popperjs/core"
import { sleep } from "@globals/functions"

export default {
    name: "Popper",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        offsetY: {
            default: 0,
            type: Number
        },
        offsetX: {
            default: 0,
            type: Number
        },
        placement: {
            default: "top",
            type: String
        },
        warning: {
            default: false,
            type: Boolean
        },
        content: String
    },
    watch: {
        disabled(nextValue, prevValue) {
            if (nextValue && !prevValue) {
                this.create()
            }
        }
    },
    computed: {
        isSafari() {
            return typeof window.safari !== "undefined"
        }
    },
    mounted() {
        if (!this.disabled) {
            this.create()
        }
    },
    beforeUnmount() {
        this.destroy()
    },
    methods: {
        onMouseEnter() {
            //make sure its render correctly, fix issue inside horizontal scroll
            if (this.popper) {
                this.popper.forceUpdate()
            }
        },
        async create() {
            this.destroy() //make sure we got only one

            this.popper = createPopper(this.$el, this.$refs.content, {
                placement: this.placement,
                strategy: "fixed", //thats why pre-set position fixed
                modifiers: [
                    {
                        name: "preventOverflow",
                        options: {
                            padding: 0
                        }
                    },
                    ...(this.offsetY !== 0 || this.offsetX !== 0
                        ? [
                              {
                                  name: "offset",
                                  options: {
                                      offset: [this.offsetX, this.offsetY]
                                  }
                              }
                          ]
                        : [])
                ]
            })

            //safari dont like popper, seems like force update fix some bug
            if (this.isSafari) {
                this.popper.forceUpdate()

                await this.$nextTick()
                await sleep(30)

                this.popper.forceUpdate()
            }
        },
        destroy() {
            if (this.popper) this.popper.destroy()
        }
    }
}
</script>

<style lang="scss">
.popper {
    display: block;

    //make sure its not there
    &.-disabled {
        .popper__content {
            display: none;
        }
    }

    //hide it
    .popper__content {
        @apply bg-white rounded shadow text-center;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s opacity ease;
        padding: 0;

        p {
            @apply text-purple;
        }

        &.-warning {
            @apply bg-red text-white;

            p {
                @apply text-white;
            }
        }
    }

    //show it on hover
    &:hover {
        .popper__content {
            @apply py-1 px-2;
            opacity: 1;
            visibility: visible;
        }
    }

    //popper stuff
    &[data-popper-reference-hidden] {
        visibility: hidden;
        opacity: 0;
        padding: 0;
        pointer-events: none;
    }

    &.-right {
        .popper__content {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            position: relative;

            &:after {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                transform: translate3d(-100%, 0, 0);
                width: 0;
                height: 0;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;

                display: block;
                content: "";
                border-right: 12px solid theme("colors.red");
            }
        }
    }
}
</style>
